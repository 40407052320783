import { color, scrollbarThin } from '@/styles/mixins';
import { Box, Divider, styled, SxProps, Theme } from '@mui/material';
import { IChronologyRowDiv } from '../features/taskEditor/item/chronology/Chronology.styled';
import { BoxShadow } from '../ui/BoxShadow';
import { FC, ReactNode } from 'react';

interface IMailNotRowDivBoxShadow {
  children: ReactNode;
  type: 'left' | 'right';
}
export const MailNotBS: FC<IMailNotRowDivBoxShadow> = ({ type, children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        mr: type === 'left' ? 6 : 'auto',
        ml: type === 'right' ? 6 : 'auto',
        mb: 3,
        mt: 6,
        maxHeight: 'calc(100% - 64px)',
        overflow: 'scroll',
      }}
      p={0}
    >
      {children}
    </Box>
  );
};

export const MailNotBoxShadow = styled(MailNotBS)`
  ${scrollbarThin}
`;

export const MailNotRowDiv = styled('div')<IChronologyRowDiv & { isActive?: boolean }>(
  ({ borderColor, borderType = 'dashed', isActive = false }) => ({
    padding: borderColor ? '16px 16px 16px 30px' : '17px 17px 17px 31px',
    border: borderColor ? `1px ${borderType} ${color(borderColor)}` : 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':hover': isActive
      ? {
          cursor: 'pointer',
          background: color('hoverBlue', 0.09),
        }
      : {},
  })
);

export const MailNotDivider = styled(Divider)({ width: '80%', margin: 'auto' });

export const MailNotificationsWrapper = styled('div')({
  padding: 32,
  width: '100%',
  height: 'calc(100vh - 60px)',
  overflow: 'scroll',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  position: 'relative',
});
export const VerticalLineWrapper = styled('div')({
  top: 32,
  bottom: 32,
  left: '50%',
  width: 1,
  position: 'absolute',
  background: color('mailLine'),
});

export const RowNumber = styled('div')({
  cursor: 'pointer',
  width: '10%',
});
export const ButtonWrapper = styled('div')({
  position: 'absolute',
  top: 32,
  left: 32,
  color: color('muiBlue'),
  cursor: 'pointer',
});
