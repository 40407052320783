import { Backdrop, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const CallingMapIncomingTasksWrapper = styled('div')({
  '.MuiDayPicker-monthContainer': {
    paddingTop: 5,
  },

  '.MuiPickersCalendarHeader-label': {
    textTransform: 'capitalize',
  },

  '.MuiDataGrid-row': {
    cursor: 'pointer',
  },
});

export const CallingMapIncomingTasksBackdrop = styled(Backdrop)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
});

export const CallingMapIncomingClaimTableGrid = styled(DataGrid)({
  height: 500,
  marginTop: 20,

  '.MuiToolbar-root': {
    p: {
      marginBottom: 0,
    },
  },
});
