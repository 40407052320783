import { useEffect, useState } from 'react';

/**
 * Хук для определения, является ли устройство сенсорным (с поддержкой touch-скрина).
 *
 * Определение производится на основе:
 * - `ontouchstart` в `window`
 * - `navigator.maxTouchPoints`
 *
 * При изменении размеров экрана (`resize`) хук повторно проверяет, изменился ли статус сенсорного устройства.
 *
 * @returns {boolean} `true`, если устройство поддерживает сенсорный ввод, иначе `false`.
 *
 * @example
 * const isTouch = useIsTouchDevice();
 * console.log(isTouch ? "Сенсорное устройство" : "Обычное устройство");
 */
export function useIsTouchDevice() {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    function onResize() {
      setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }

    window.addEventListener('resize', onResize);
    onResize(); // Проверяем при монтировании

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isTouchDevice;
}
