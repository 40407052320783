import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';
import { getBorderColor } from '../ComplaintItem/utils';
import { useComplaintStore } from '../store';
import {
  ComplaintInfoHeader,
  ComplaintInfoRound,
  ComplaintInfoWrapper,
  ComplaintInfoStage,
  ComplaintInfoFadeInner,
} from './ComplaintInfo.styled';
import CloseIcon from '@mui/icons-material/Close';
import { Fade, IconButton, Stack, Typography } from '@mui/material';
import ComplaintComment from '../ComplaintComment/ComplaintComment';
import InfoComplaintBlock from './InfoComplaintBlock';

import CallCenterModal from '../CallCenterModal/CallCenterModal';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { EComplaintType } from '../types';
import moment from 'moment';
import { InfoComplaintBlockText } from './InfoComplaintBlock.styled';

const ComplaintInfo = () => {
  const { selectedCompliant, setSelectedCompliant } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
      setSelectedCompliant: state.setSelectedCompliant,
    }),
    shallow
  );

  const borderColor = useMemo(
    () => getBorderColor(selectedCompliant?.complaint?.stage?.name),
    [selectedCompliant]
  );

  if (!selectedCompliant) return null;
  if (selectedCompliant?.complaint?.stage?.name === ComplaintStage.CALL_CENTERED)
    return (
      <>
        <Fade in={!!selectedCompliant}>
          <ComplaintInfoFadeInner>
            <ComplaintInfoWrapper isCallCenter>
              <ComplaintComment />
              <CallCenterModal
                selectedCompliant={selectedCompliant}
                borderColor={borderColor}
              />
            </ComplaintInfoWrapper>
          </ComplaintInfoFadeInner>
        </Fade>
      </>
    );

  return (
    <Fade in={!!selectedCompliant}>
      <ComplaintInfoFadeInner>
        <ComplaintInfoWrapper>
          <ComplaintComment />
          <ComplaintInfoHeader>
            <ComplaintInfoStage>
              <ComplaintInfoRound color={borderColor} />
              <InfoComplaintBlockText>
                {selectedCompliant?.complaint?.stage?.title}{' '}
                {selectedCompliant.complaint.stageUpdateAt &&
                  `от ${moment(selectedCompliant.complaint.stageUpdateAt).format('DD.MM.YYYY')}`}
              </InfoComplaintBlockText>
            </ComplaintInfoStage>
            <Stack
              flex={1}
              width={'100%'}
            >
              <InfoComplaintBlockText textAlign={'end'}>
                Жалоба {selectedCompliant.complaint.base.id}
              </InfoComplaintBlockText>
            </Stack>
            <IconButton onClick={() => setSelectedCompliant(null)}>
              <CloseIcon />
            </IconButton>
          </ComplaintInfoHeader>
          <InfoComplaintBlock />
        </ComplaintInfoWrapper>
      </ComplaintInfoFadeInner>
    </Fade>
  );
};

export default ComplaintInfo;
