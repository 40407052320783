// import { GetCatalogForRP } from '@/services/YodaRestService';

import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';

const SEARCH_NAME = 'Контракт';

type DataType = {
  name: string;
  alias: string;
  dType: string;
};
export type DataTypeConvert = {
  id: string;
  value: string;
  label: string;
  dType: string;
  isActive: boolean;
};

export const convertDataFn = (data: DataType[]): DataTypeConvert[] => {
  const contractIndex = data.findIndex((item) => item.name === SEARCH_NAME);
  if (contractIndex !== -1 && contractIndex !== 0) {
    // Удалить объект из текущей позиции и добавить его в начало массива
    const contractObject = data.splice(contractIndex, 1)[0];
    data.unshift(contractObject);
  }
  return data.map((el) => ({
    id: el.alias,
    value: el.alias,
    label: el.name,
    dType: el.dType,
    isActive: false,
  }));
};

export const converToDoobleOrSting = (type: string, param: any) => {
  if (type == 'DOUBLE') return parseFloat(param);
  return param;
};

export const makeArrayOfStingsToOptions = (array: string[]) =>
  array.map((el) => ({ value: el, label: el }));

export const defaultArgInput = {
  TAG: {
    argList: [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST],
    defaultArgNum: 0,
  },
  TEXT: {
    argList: [FilterArgEnum.EQUAL, FilterArgEnum.NOT_EQUAL],
    defaultArgNum: 2,
  },
  DATE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.RANGE,
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 8,
  },
  DOUBLE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.RANGE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
  LONG: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.RANGE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
};

// функции для досупа к динамическим инпутам по ролям смотрит на массив data который настривается индивидуально для роли и динмачиских инпутов
//  приммер можно найти  **src/components/storehouse/SupplyBillBrowse/utils.ts**
export const getFilterSettingByDataAccess = (
  data: DataTypeConvert[],
  arr: { filterSetting?: DataTypeConvert[] }
): DataTypeConvert[] => {
  if (data?.length && Array.isArray(arr?.filterSetting)) {
    const accessIds = new Set(data.map((item) => item?.id));
    const filteredFilterSetting = arr.filterSetting.filter((item) => accessIds.has(item?.id || ''));
    return filteredFilterSetting as DataTypeConvert[];
  } else {
    return [];
  }
};
export const getCheckedSettingByDataAccess = (
  data: DataTypeConvert[],
  arr: { checked?: DataTypeConvert[] }
): DataTypeConvert[] => {
  if (data?.length && Array.isArray(arr?.checked)) {
    const accessIds = new Set(data.map((item) => item?.id));
    const filteredFilterSetting = arr.checked.filter((item) => accessIds.has(item?.id || ''));
    return filteredFilterSetting as DataTypeConvert[];
  } else {
    return [];
  }
};
