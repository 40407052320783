import { color } from '@/styles/mixins';
import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          color: color('fontGray'),
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '17px',
          borderRadius: '6px',
          padding: '8px 12px',
          border: `1px solid ${color('fontGray')}`,
          boxShadow: 'none',
        },
        arrow: {
          color: color('fontGray'),
        },
      },
    },
  },
});

export default theme;
