import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  NewGalleryActiveImageHeader,
  NewGalleryActiveImageHeaderWrapper,
  NewGalleryActiveImageRow,
  NewGalleryResetButton,
  NewGalleryTitle,
} from './NewGallery.styled';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import Draggable from 'react-draggable';
import { formatDate, getFileNameFromUrl, getImageUrl } from './utils';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import DownloadSingleFile from '@/components/button/DownloadSingleFile/DownloadSingleFile';
import MouseOutlinedIcon from '@mui/icons-material/MouseOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';

export interface GalleryActiveImageProps {
  image: IImage | null;
  isDraggable?: boolean;
  imgStyle?: React.CSSProperties;
  contentType?: string | null;
  withControlsTooltip?: boolean;
}

const NewGalleryActiveImage = ({
  image,
  isDraggable = true,
  imgStyle,
  contentType,
  withControlsTooltip,
}: GalleryActiveImageProps) => {
  const zoomStep = 0.5;
  const minScale = 1;
  const maxScale = 10;
  const defaultScale = 1;
  const defaultRotate = 0;

  const [controlsTooltip, setControlsTooltip] = useState({ open: false, showed: false });

  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [version, setVersion] = useState(0);
  const zoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const zoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale <= minScale ? minScale : newScale);
  };
  const reset = () => {
    setScale(defaultScale);
    setRotate(0);
    setVersion(version + 1);
  };

  const onRotate = () => {
    setRotate(rotate + 90);
  };

  const onScroll = (e: WheelEvent) => {
    const delta = e.deltaY * -0.01;
    const newScale = scale + delta;

    if (newScale > scale) {
      zoomIn();
    } else {
      zoomOut();
    }
  };

  // фикс скроллбара во время "вращения" фотографии
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const timeout = setTimeout(() => {
      document.body.style.overflow = 'unset';
    }, 300);

    return () => clearTimeout(timeout);
  }, [rotate]);

  const imageName = getFileNameFromUrl(image?.url);
  const imageDate = formatDate(image?.createdOn);

  const hideTooltip = () => setControlsTooltip({ open: false, showed: true });

  const handleHover = () => {
    if (!withControlsTooltip) return;

    if (!controlsTooltip?.showed) {
      setControlsTooltip({ open: true, showed: true });
      setTimeout(() => {
        hideTooltip();
      }, 3000);
    }
  };

  return (
    <>
      <NewGalleryTitle>{imageName}</NewGalleryTitle>

      <NewGalleryActiveImageHeaderWrapper>
        <NewGalleryTitle>{imageDate}</NewGalleryTitle>
        <NewGalleryActiveImageHeader>
          <NewGalleryResetButton onClick={reset}>Сброс</NewGalleryResetButton>

          <IconButton onClick={zoomIn}>
            <ZoomInRoundedIcon color='info' />
          </IconButton>

          <IconButton onClick={zoomOut}>
            <ZoomOutRoundedIcon color='info' />
          </IconButton>

          <IconButton onClick={onRotate}>
            <CachedRoundedIcon color='info' />
          </IconButton>

          <DownloadSingleFile url={image?.url} />
        </NewGalleryActiveImageHeader>
      </NewGalleryActiveImageHeaderWrapper>

      <Tooltip
        title={
          <div>
            <NewGalleryActiveImageRow>
              <MouseOutlinedIcon color='primary' /> <div>Прокрутите колесико, чтобы увеличить</div>
            </NewGalleryActiveImageRow>
            <NewGalleryActiveImageRow>
              <KeyboardAltOutlinedIcon color='primary' /> Используйте ← → для навигации
            </NewGalleryActiveImageRow>
          </div>
        }
        open={controlsTooltip.open}
        followCursor
        sx={{ maxWidth: 'fit-content' }}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'fit-content',
            },
          },
        }}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        <div
          onMouseOut={() => (document.body.style.overflow = 'unset')}
          onMouseOver={() => (document.body.style.overflow = 'hidden')}
          onMouseEnter={handleHover}
          onMouseLeave={hideTooltip}
        >
          <Draggable
            disabled={!isDraggable}
            key={version}
          >
            <div
              onWheelCapture={(event) => onScroll(event.nativeEvent)}
              style={{ zIndex: 20, position: 'relative', ...(isDraggable && { cursor: 'move' }) }}
            >
              <img
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  minHeight: '150px',
                  maxWidth: '100%',
                  maxHeight: '75svh',
                  transition: '0.3s',
                  ...imgStyle,
                }}
                draggable='false'
                src={getImageUrl(image?.url, contentType)}
                srcSet={getImageUrl(image?.url, contentType)}
                onDoubleClick={zoomIn}
                loading='lazy'
              />
            </div>
          </Draggable>
        </div>
      </Tooltip>
    </>
  );
};

export default NewGalleryActiveImage;
