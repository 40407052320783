import { getMonth, getYear } from 'date-fns';
import { CellWithTooltip } from '@/components/features/tasksBrowse/constants';
import { toFormateDate } from '@/utils/heplers';
import { GridColDef } from '@mui/x-data-grid';

export const getMonthAndYear = (date: Date) => {
  const month = getMonth(date) + 1;
  const year = getYear(date);
  return { month, year };
};

export const columns: GridColDef[] = [
  {
    field: 'accountingNumber',
    headerName: 'Лицевой счет',
    width: 150,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'addressTitle',
    headerName: 'Адрес',
    width: 450,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'executorUserId',
    headerName: 'Исполнитель',
    renderCell: (params) => {
      params.value = '-';
      return <CellWithTooltip params={params} />;
    },

    width: 150,
  },
  {
    field: 'scheduledDate',
    headerName: 'Запланированная дата',
    width: 190,

    valueGetter: (row) => {
      if (!row.value) return '';
      return toFormateDate(row.value);
    },
  },
  {
    field: 'taskId',
    headerName: 'ID',
    flex: 1,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
];
