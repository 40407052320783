import { useEffect } from 'react';

/**
 * Хук, который выполняет переданную функцию, при нажатии заданной клавиши
 *
 * @param {Function} action - Коллбэк, который будет вызван при нажатии клавиши
 * @param {string} key - Клавиша, которую нужно нажать
 *
 * @example
 * useKeyPress('ArrowLeft', handlePrevImage);
 */
export const useKeyPress = (key: string, action: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === key) {
        action();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [key, action]);
};
