import { useNavigate } from 'react-router-dom';
import { YodaTokenContextType } from '@/app/auth/types';
import useAuth from './useAuth';

/**
 * Хук для выхода пользователя из системы.
 *
 * При вызове:
 * - Вызывает метод `logOut` из контекста аутентификации.
 * - Сохраняет текущий путь (`pathname` и `search`) в `localStorage` для последующего редиректа.
 * - Перенаправляет пользователя на страницу входа (`/login`).
 * - Обновляет страницу (`window.location.reload()`), чтобы сбросить состояние приложения.
 *
 * @returns {() => void} Функция выхода из системы.
 *
 * @example
 * const logOut = useLogOut();
 * <button onClick={logOut}>Выйти</button>
 */
export const useLogOut = () => {
  const auth: YodaTokenContextType = useAuth() as YodaTokenContextType;
  const navigate = useNavigate();

  const logOut = () => {
    auth.logOut();

    // Сохраняем текущий путь в localStorage для возможного редиректа после повторного входа
    const currentPath = location.pathname + location.search;
    localStorage.setItem('redirectPath', currentPath);

    navigate('/login');
    window.location.reload(); // Обновляем страницу, чтобы сбросить состояние приложения
  };

  return logOut;
};
