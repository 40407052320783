import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { RegistryADPU } from '@/components/RegistryADPU/RegistryADPU';

const RegistryADPUPage = () => {
  // useAccess({
  // 	access: [AccessEnum.DeliveryPointScreen],
  // });

  useMetaTitle(`Реестры передачи АДПУ`);

  return <RegistryADPU />;
};
export default RegistryADPUPage;
