import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { TextField, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import {
  SupplyPUConfirmationDelete,
  SupplyPUTableHeader,
  SupplyPUTableHeaderItem,
} from './SupplyPUComponent.styled';
import {
  checkErrors,
  EquipmentState,
  FULL_COMPLETENESS_OPTION,
  generatePuExcelFile,
  getPUsFromErrorMaps,
  replaceNonNums,
  TextFieldChangeEvent,
  useStorehouseWorkerAccess,
  useValidReturnBillFields,
} from './utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { SupplyPUModalProps } from './SupplyPUVirtualizedTable';
import { SupplyTabType } from '../StampComponent/StampComponent';
import useSupplyStore from '../SupplyStore';
import shallow from 'zustand/shallow';
import { SupplyPUItem, SupplyServiceCatalogOption } from '@/dto/taskmap/Dto';
import { useAllPuOwner } from '@/hooks/useQuery/useAllSupplyCatalogs';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';

export interface SuppluPUModalHeaderProps extends SupplyPUModalProps, SupplyTabType {
  selectedPUDeclaredCount: string;
  setSelectedPUDeclaredCount: React.Dispatch<React.SetStateAction<string>>;
  savePUItems: (forcedPuListItems?: SupplyPUItem[]) => void;
  closeScanning: () => void;
  removeErroredScanRows: (dupIds: number[], existIds: number[]) => void;
  confirmAllRows: () => void;
  viewMode?: boolean;
  setPUListItems: React.Dispatch<React.SetStateAction<SupplyPUItem[]>>;
}

interface ErrorPuDialog {
  open: boolean;
  dup?: string[];
  exist?: string[];
  noYodaTasks?: string[];
  allIds?: number[];
  removeIds?: number[];
}

const SupplyPUModalHeader = ({
  selectedPUDeclaredCount,
  puListItems,
  osDupFieldError,
  noYodaTaskFieldError,
  osExistsFieldError,
  savePUItems,
  closeScanning,
  removeErroredScanRows,
  setSelectedPUDeclaredCount,
  disabledOrDraftCheck,
  selectedPU,
  returnBill,
  confirmAllRows,
  viewMode,
  tabType,
  billWithConfirm,
  setPUListItems,
  accessProject,
}: SuppluPUModalHeaderProps) => {
  const isValidReturnBill = useValidReturnBillFields(puListItems, returnBill);
  const workerAccess = useStorehouseWorkerAccess();
  const { returnNewItemsMode, setReturnNewItemsMode } = useSupplyStore(
    (state) => ({
      returnNewItemsMode: state.returnNewItemsMode,
      setReturnNewItemsMode: state.setReturnNewItemsMode,
    }),
    shallow
  );
  const { puOwnerOptions } = useAllPuOwner();
  const { puTypesResponse } = useAllPuTypes();

  const [removeErrorPuDialog, setRemoveErrorPuDialog] = useState<ErrorPuDialog>({ open: false });
  const [closeAlertOpen, setCloseAlertOpen] = useState(false);

  const puListItemsErrorDisable = checkErrors(
    osDupFieldError,
    osExistsFieldError,
    noYodaTaskFieldError,
    selectedPU?.tmpId
  );
  const factoryTabType = tabType === 'factory_get';
  const disableSaveButton =
    !accessProject &&
    (disabledOrDraftCheck || (puListItemsErrorDisable && !factoryTabType) || !isValidReturnBill);

  const closeRemoveErrorPuDialog = () => {
    setRemoveErrorPuDialog({ ...removeErrorPuDialog, open: false });
  };

  const openRemoveErrorPuDialog = () => {
    const errorPUs = getPUsFromErrorMaps(
      osDupFieldError,
      osExistsFieldError,
      noYodaTaskFieldError,
      puListItems,
      selectedPU?.tmpId
    );

    setRemoveErrorPuDialog({ ...errorPUs, open: true });
  };

  const removeErrorPUs = () => {
    removeErroredScanRows(removeErrorPuDialog.allIds ?? [], removeErrorPuDialog.removeIds ?? []);
    setRemoveErrorPuDialog({ open: false });
  };

  const handleCsvDownload = () => {
    const filteredPUs = puListItems.filter(
      (it) => (it?.mac && it?.mac?.length > 0) || (it?.number && it?.number?.length > 0)
    );
    generatePuExcelFile(filteredPUs, puOwnerOptions, puTypesResponse, selectedPU?.meterName);
  };

  const handleCloseScanning = () => {
    setCloseAlertOpen(false);
    closeScanning();
  };

  const handleChangeDeclaredCount = (event: TextFieldChangeEvent) => {
    const onlyNums = replaceNonNums(event?.target?.value);
    if (selectedPU) setSelectedPUDeclaredCount(onlyNums ?? '');
  };

  const handleCloseButton = () => {
    viewMode ? handleCloseScanning() : setCloseAlertOpen(true);
  };

  const addedItemsCount = billWithConfirm || viewMode ? puListItems.length : puListItems.length - 1;

  const firstRow = puListItems[0];

  const updateAllRowsAccordingToFirst = () => {
    let stateOption: SupplyServiceCatalogOption | null = null;
    if (
      firstRow?.state?.id === EquipmentState.REPAIRED ||
      firstRow?.state?.id === EquipmentState.NEW ||
      firstRow?.state?.id === EquipmentState.DEFECT
    ) {
      stateOption = firstRow?.state;
    }

    const returnNewItemsPus = puListItems.map((pu) => ({
      ...pu,
      completeness: firstRow?.completeness,
      state: stateOption,
      defectCause: firstRow?.defectCause,
      taskNumber: null,
      completenessComment: firstRow?.completenessComment,
      address: firstRow?.address,
      generalComment: firstRow?.generalComment,
      supplyComment: '-',
      manufactureYear: firstRow?.manufactureYear,
      ownerId: firstRow?.ownerId,
    }));

    return returnNewItemsPus;
  };

  const handleReturnNewItemsClick = () => {
    setReturnNewItemsMode(!returnNewItemsMode);
    setPUListItems(updateAllRowsAccordingToFirst());
  };

  const handleSaveClick = () => {
    if (returnNewItemsMode) {
      savePUItems(updateAllRowsAccordingToFirst());
    } else {
      savePUItems();
    }
  };

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <SupplyPUTableHeader>
          <SupplyPUTableHeaderItem>
            <TextField
              disabled={accessProject}
              label='Заявленное количество'
              value={selectedPUDeclaredCount}
              onChange={(event) => handleChangeDeclaredCount(event)}
              error={addedItemsCount < parseInt(selectedPUDeclaredCount)}
            />
            <TextField
              disabled={accessProject}
              label='Уже добавлено'
              InputLabelProps={{ shrink: true }}
              contentEditable={false}
              error={addedItemsCount > parseInt(selectedPUDeclaredCount)}
              value={addedItemsCount}
            />
          </SupplyPUTableHeaderItem>
          <SupplyPUTableHeaderItem>
            {returnBill && (
              <Button
                variant='contained'
                color={returnNewItemsMode ? 'success' : undefined}
                onClick={handleReturnNewItemsClick}
                disabled={viewMode}
              >
                Массовый возврат ПУ
              </Button>
            )}
            {billWithConfirm && workerAccess && !viewMode && (
              <Button
                variant='contained'
                onClick={confirmAllRows}
              >
                Подтвердить все
              </Button>
            )}
            <Button
              endIcon={<FileDownloadIcon />}
              variant='contained'
              onClick={handleCsvDownload}
            >
              Скачать файл
            </Button>
            {puListItemsErrorDisable && !factoryTabType && (
              <Button
                variant='contained'
                color='error'
                onClick={openRemoveErrorPuDialog}
              >
                Удалить ПУ c ошибками
              </Button>
            )}

            <Button
              variant='contained'
              disabled={disableSaveButton}
              onClick={handleSaveClick}
            >
              Сохранить
            </Button>

            <Button
              variant='outlined'
              onClick={handleCloseButton}
            >
              Закрыть
            </Button>
          </SupplyPUTableHeaderItem>
        </SupplyPUTableHeader>
      </div>
      <ConfirmationDialog
        title={`Cистемой были выявлены следующие ошибки`}
        handleOk={removeErrorPUs}
        handleCancel={closeRemoveErrorPuDialog}
        open={removeErrorPuDialog.open}
        textForOk={'Удалить'}
        fullWidth
      >
        <>
          {(!!removeErrorPuDialog?.dup?.length || !!removeErrorPuDialog?.noYodaTasks?.length) && (
            <>
              <Typography variant='subtitle1'>Текущая накладная:</Typography>
              {removeErrorPuDialog?.dup?.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
              {removeErrorPuDialog?.noYodaTasks?.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
            </>
          )}
          {!!removeErrorPuDialog?.exist?.length && (
            <div style={{ marginTop: 24 }}>
              <Typography variant='subtitle1'>Накладные вкладки &ldquo;Архив&rdquo;:</Typography>
              {removeErrorPuDialog?.exist?.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
            </div>
          )}

          <Typography
            variant='body1'
            sx={{ mt: 4 }}
          >
            Вы уверены что хотите <SupplyPUConfirmationDelete>удалить </SupplyPUConfirmationDelete>
            ошибочные ПУ?
          </Typography>
        </>
      </ConfirmationDialog>

      <ConfirmationDialog
        title={`Внимание!`}
        handleOk={handleCloseScanning}
        handleCancel={() => setCloseAlertOpen(false)}
        open={closeAlertOpen}
        textForOk={'Да'}
        textForCancel='Нет'
      >
        <Typography variant='subtitle1'>
          Вы хотите закрыть страницу без сохранения данных?
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default SupplyPUModalHeader;
