import { useFormContext } from 'react-hook-form';
import { updatePresentEqInfo } from '@/services/TaskService';
import { Button, Stack, Typography } from '@mui/material';
import FormPresentPu from './FormPresentPu';
import FromPresentTransformer from './FromPresentTransformer';
import FromPresentTransformerVoltage from './FromPresentTransformerVoltage';
import { color } from '@/styles/mixins';
import {
  PresentEquipments,
  PresentPu,
  PresentTransformer,
  PresentTransformerVoltage,
} from '@/dto/taskmap/task/TaskResultDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useExistingEquipment } from './store';
import shallow from 'zustand/shallow';
import { useCatalog } from '@/hooks/CatalogHook';

interface FormForExistingEquipmentProps {
  id: string;
  existingEquipmentUrl: string;
  closeModal: () => void;
}

const FormForExistingEquipment = ({
  closeModal,
  id,
  existingEquipmentUrl,
}: FormForExistingEquipmentProps) => {
  const { getExistingEquipmentByKey, setExistingEquipment } = useExistingEquipment(
    (store) => ({
      setExistingEquipment: store.setExistingEquipment,
      getExistingEquipmentByKey: store.getExistingEquipmentByKey,
    }),
    shallow
  );
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const processKtn = (ktn: string) => {
    if (!ktn) return [];
    const catalog = getCatalogMapWithErr('ktnType').get(ktn);
    if (catalog) {
      const arr = catalog.split('/');
      return arr;
    }
    return ktn.split('/');
  };
  const processKtt = (ktt: string) => {
    if (!ktt) return [];
    const catalog = getCatalogMapWithErr('kttEnum').get(ktt);
    if (catalog) {
      const arr = catalog.split('/');
      return arr;
    }
    return ktt.split('/');
  };
  // for PU
  const updatePresentPu = (presentPu: PresentPu | null | undefined): PresentPu | null => {
    if (!presentPu) return null;
    return {
      ...presentPu,
      type: getCatalogMapWithErr('referenceMeterType').get(presentPu.type ?? '') || presentPu.type,
    };
  };
  // for TT
  const updatePresentTransformer = (
    presentTransformer: PresentTransformer | null | undefined
  ): PresentTransformer | null => {
    if (!presentTransformer) return null;

    const [n1, n2] = processKtt(presentTransformer?.ktt as string);
    return {
      ...presentTransformer,
      type:
        getCatalogMapWithErr('amperageTransformerType').get(presentTransformer.type || '') ||
        presentTransformer.type,
      ktt: { n1, n2 },
    };
  };
  // for TN
  const updatePresentTransformerVoltage = (
    presentTransformerVoltage: PresentTransformerVoltage | null | undefined
  ): PresentTransformerVoltage | null => {
    if (!presentTransformerVoltage) return null;
    if (typeof presentTransformerVoltage.ktn === 'string') {
      const [n1, n2] = processKtn(presentTransformerVoltage.ktn);
      return {
        ...presentTransformerVoltage,
        type:
          getCatalogMapWithErr('voltageTransformerType').get(
            presentTransformerVoltage.type || ''
          ) || presentTransformerVoltage.type,
        ktn: { n1, n2 },
      };
    }
    return presentTransformerVoltage;
  };

  const { fetchCatch } = useActionLog();
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const existingEquipment = getExistingEquipmentByKey(id);

  const presentPu = (existingEquipment && existingEquipment?.presentEquipments?.presentPu) || null;
  const presentTransformer =
    (existingEquipment && existingEquipment?.presentEquipments?.presentTransformer) || null;
  const presentTransformerVoltage =
    (existingEquipment && existingEquipment?.presentEquipments?.presentTransformerVoltage) || null;

  const { handleSubmit } = useFormContext();
  const handleForm = (data: PresentEquipments) => {
    const modifiedData: Partial<PresentEquipments> = {};
    modifiedData.presentPu = updatePresentPu(data?.presentPu);
    modifiedData.presentTransformer = updatePresentTransformer(data?.presentTransformer);
    modifiedData.presentTransformerVoltage = updatePresentTransformerVoltage(
      data.presentTransformerVoltage
    );
    setIsInProgress(true);
    updatePresentEqInfo(id, { presentEquipments: modifiedData }, existingEquipmentUrl)
      .then(({ data }) => {
        setExistingEquipment(data);
        closeModal();
      })
      .catch((err) =>
        fetchCatch(err, 'Ошибка при обновление информации о существующеем оборудование')
      )
      .finally(() => setIsInProgress(false));
  };

  return (
    <Stack>
      <Typography
        textAlign={'center'}
        variant='h5'
        color={color('trueBlack')}
      >
        Внесите изменения
      </Typography>
      <Stack
        mt={3}
        alignItems={'center'}
      >
        <form onSubmit={handleSubmit(handleForm)}>
          {presentPu && (
            <FormPresentPu
              keyString={'presentPu'}
              formData={presentPu}
            />
          )}
          {presentTransformer && (
            <FromPresentTransformer
              keyString={`presentTransformer`}
              formData={presentTransformer}
            />
          )}
          {presentTransformerVoltage && (
            <FromPresentTransformerVoltage
              keyString={'presentTransformerVoltage'}
              formData={presentTransformerVoltage}
            />
          )}
          <Stack
            flexDirection={'row'}
            mt={2}
            justifyContent={'space-around'}
          >
            <Button
              disabled={isInProgress}
              type='submit'
            >
              сохранить
            </Button>
            <Button onClick={closeModal}>отмена</Button>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
};

export default FormForExistingEquipment;
