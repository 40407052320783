import { styled } from '@mui/material/styles';

import { Typography } from '@mui/material';
import { color } from '@/styles/mixins';

export const ImagePanelText = styled(Typography)({
  textAlign: 'center',
  padding: '40px',
  color: color('gray'),
  lineHeight: '54px',
  fontWeight: 500,
});
