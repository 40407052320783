import React, { FC } from 'react';
import { Colors } from '@/styles/colors';
import ChronologyItem from '@/components/features/taskEditor/item/chronology/ChronologyItem';
import { RegistryADPURowDiv } from '@/components/RegistryADPU/RegistryADPU.styled';

const FileItem: FC<{ id: string; borderColor?: Colors }> = ({ borderColor, id }) => {
  return (
    <RegistryADPURowDiv borderColor={borderColor}>
      <ChronologyItem
        label={'ID Заявки'}
        value={id}
        width={'100%'}
      />
    </RegistryADPURowDiv>
  );
};

export default FileItem;
