import { FormProvider, useForm } from 'react-hook-form';
import './Filter.css';
import { Grid, Typography } from '@mui/material';

import { SelectFilterOptType, SingleLineFilter } from '@/components/filter/MultySelectFilter';
import React, { useEffect, useMemo, useState } from 'react';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import { TaskStatuses } from '@/components/features/pnr/TaskMap/TaskMapFilter/utils';
import { useGeoQuery } from '../../hooks/GeoQueryHook';
import { useMap } from '@/components/features/pnr/hooks/MapHook';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useKeyPress } from '@/hooks/usePressKey';

const FILTERS_LABELS = {
  taskStatuses: 'Статус заявки',
  contractorList: 'Ответственный подрядчик',
  contractList: 'Контракт',
  accountingNum: '№ ЛС',
  networkResArea: 'Данные к заявке.РЭС/район',
  customerId: '№ договора/СТДО',
};

export const Filter = (props: any) => {
  const { execute, currentGeoResponse, currentTaskFilterParam } = useGeoQuery();
  const [total, setTotal] = useState<number>();
  const { currentGeoBounds } = useMap();
  const methods = useForm({
    defaultValues:
      localStorage.getItem('geoFilterParam') && JSON.parse(localStorage.getItem('geoFilterParam')!),
  });
  const { handleSubmit, reset, watch } = methods;
  const { taskGroupMap, getGroupCatalog, getContractCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    getContractCatalog: state.getContractCatalog,
    taskGroupMap: state.taskGroupMap,
  }));

  useEffect(() => {
    if (currentGeoResponse) {
      setTotal(currentGeoResponse?.count);
    }
  }, [currentTaskFilterParam]);

  const onSubmit = (data: any) => {
    const filtParam: TaskFilterParam = data;
    if (selectedContractList.length) {
      filtParam.contractIds = getDataFromFilter(selectedContractList, 'value');
      filtParam.contractIdsArg = contractListArg;
    }
    if (selectedContractor.length) {
      filtParam.contractorIds = getDataFromFilter(selectedContractor, 'value');
      filtParam.contractorIdsArg = contractorListArg;
    }
    if (selectedStatuses.length) {
      filtParam.statuses = getDataFromFilter(selectedStatuses, 'value');
      filtParam.statusesArg = taskStatusesArg;
    }

    execute(currentGeoBounds, filtParam);
  };

  useKeyPress('Enter', handleSubmit(onSubmit));

  const onResetClick = () => {
    reset();
    setSelectedContractList([]);
    setSelectedContractor([]);
    setSelectedStatuses([]);
  };

  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = React.useState(0);

  const [selectedStatuses, setSelectedStatuses] = React.useState<SelectFilterOptType[]>([]);
  const [taskStatusesArg, setTaskStatusesArg] = React.useState(0);

  const contractorList = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
  const [selectedContractor, setSelectedContractor] = React.useState<SelectFilterOptType[]>([]);
  const [contractorListArg, setContractorListArg] = React.useState(0);

  const detailedInfo = generateDetailedInfo(
    [selectedStatuses, FILTERS_LABELS.taskStatuses],
    [selectedContractor, FILTERS_LABELS.contractorList],
    [selectedContractList, FILTERS_LABELS.contractList],
    [watch('accountingNum'), FILTERS_LABELS.accountingNum],
    [watch('networkResArea'), FILTERS_LABELS.networkResArea],
    [watch('customerId'), FILTERS_LABELS.customerId]
  );

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            position='relative'
          >
            <Grid
              container
              item
              xs={10}
            >
              <Grid
                container
                item
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                wrap='nowrap'
              >
                <MultiSelect
                  label={FILTERS_LABELS.taskStatuses}
                  options={TaskStatuses}
                  value={selectedStatuses}
                  argValue={taskStatusesArg}
                  onChange={(value: SelectFilterOptType[]) => setSelectedStatuses(value)}
                  onArgChange={(arg: number) => setTaskStatusesArg(arg)}
                />

                <MultiSelect
                  label={FILTERS_LABELS.contractorList}
                  options={contractorList}
                  value={selectedContractor}
                  argValue={contractorListArg}
                  onChange={(value: SelectFilterOptType[]) => setSelectedContractor(value)}
                  onArgChange={(arg: number) => setContractorListArg(arg)}
                />

                <MultiSelect
                  label={FILTERS_LABELS.contractList}
                  options={contractListResponse}
                  value={selectedContractList}
                  argValue={contractListArg}
                  onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
                  onArgChange={(arg: number) => setContractListArg(arg)}
                />
              </Grid>
              <Grid
                container
                item
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                wrap='nowrap'
              >
                <SingleLineFilter
                  registerName='accountingNum'
                  label={FILTERS_LABELS.accountingNum}
                />
                <SingleLineFilter
                  registerName='networkResArea'
                  label={FILTERS_LABELS.networkResArea}
                />
                <SingleLineFilter
                  registerName='customerId'
                  label={FILTERS_LABELS.customerId}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={2}
              direction='column'
            >
              <FilterButtons
                info={detailedInfo}
                onSearchClick={() => handleSubmit(onSubmit)}
                onCloseClick={onResetClick}
              />
              <Grid
                container
                item
              >
                {!!total && (
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography align='right'>Найдено: {total} шт.</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
