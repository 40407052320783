import { TablePagination } from '@mui/material';
import { ruRU, GridFooter } from '@mui/x-data-grid';
import React from 'react';
import { columns } from './utils';
import { useIncomingClaim } from '../CallingMapIncomingTasksTab/store';
import shallow from 'zustand/shallow';
import { DisplayedRows } from '../GeoLessPointsTable';
import { CallingMapIncomingClaimTableGrid } from './CallingMapIncomingTasks.styled';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { getSingleTaskfromCallMap } from '@/services/CallingMapService/CallingMapService';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useCallingMapState } from '../../store';

const CallingMapIncomingClaimTable = () => {
  const { setIsInProgress } = useScreenHoldHook();

  const { setSingleGeoPoint, setTaskId } = useCallingMapState(
    (state) => ({
      setSingleGeoPoint: state.setSingleGeoPoint,
      setTaskId: state.setTaskId,
    }),
    shallow
  );

  const {
    pageSize,
    currentPage,
    incomingClaimRows,
    setPageSize,
    setCurrentPage,
    totalRows,
    incomingClaimRowsLoading,
  } = useIncomingClaim(
    (state) => ({
      pageSize: state.pageSize,
      currentPage: state.currentPage,
      incomingClaimRows: state.incomingClaimRows,
      setPageSize: state.setPageSize,
      setCurrentPage: state.setCurrentPage,
      totalRows: state.totalRows,
      incomingClaimRowsLoading: state.incomingClaimRowsLoading,
    }),
    shallow
  );

  const onPageSizeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    if (!event) return;
    setPageSize(Number(event.target.value));
    setCurrentPage(0);
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRowClick = (row: any) => {
    setIsInProgress(true);
    getSingleTaskfromCallMap(row?.id)
      .then(({ data }) => {
        setSingleGeoPoint(data);
        setTaskId(row?.id);
      })
      .catch((error) => fetchCatch(error, 'Ошибка загрузки информации о заявке'))
      .finally(() => setIsInProgress(false));
  };
  return (
    <>
      <CallingMapIncomingClaimTableGrid
        rows={incomingClaimRows}
        columns={columns}
        getRowId={(row) => row.taskId}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        disableColumnMenu
        pagination
        onRowClick={handleRowClick}
        pageSize={pageSize}
        loading={incomingClaimRowsLoading}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        disableSelectionOnClick
        components={{
          Footer: GridFooter,
          Pagination: () => (
            <>
              <TablePagination
                count={totalRows === 'pending' ? 0 : totalRows}
                page={currentPage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={onPageSizeChange}
                labelDisplayedRows={(props) => (
                  <DisplayedRows
                    {...props}
                    isCountLoading={totalRows === 'pending'}
                  />
                )}
                onPageChange={(_event, newPage) => onPageChange(newPage)}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                labelRowsPerPage={'Заявок на странице:'}
              />
            </>
          ),
        }}
      />
    </>
  );
};

export default CallingMapIncomingClaimTable;
