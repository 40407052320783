import { IncommingCallClaimResponseDto } from '@/services/CallingMapService/dto/CallingMapServiceResponseDto';
import { createStore } from '@/utils/createStore';

interface IncomingClaimData {
  incomingClaimCount: number | 'pending' | null;
  incomingClaimRows: IncommingCallClaimResponseDto[];
  incomingClaimRowsLoading: boolean;
  totalRows: number | 'pending';

  pageSize: number;
  currentPage: number;
}

interface IncomingClaimFunctions {
  setIncomingClaimCount: (incomingClaimCount: number | 'pending' | null) => void;
  setPageSize: (pageSize: number) => void;
  setCurrentPage: (currentPage: number) => void;
  setIncomingClaimRows: (incomingClaimRows: IncommingCallClaimResponseDto[]) => void;
  setTotalRows: (totalRows: number | 'pending') => void;
  setIncomingClaimRowsLoading: (incomingClaimRowsLoading: boolean) => void;
}

type IncomingClaimStore = IncomingClaimData & IncomingClaimFunctions;

export const useIncomingClaim = createStore<IncomingClaimStore>(
  (set) => ({
    incomingClaimCount: 0,
    incomingClaimRows: [],
    totalRows: 0,
    incomingClaimRowsLoading: false,

    pageSize: 10,
    currentPage: 0,

    setPageSize: (pageSize) => set({ pageSize }),
    setCurrentPage: (currentPage) => set({ currentPage }),

    setIncomingClaimCount: (incomingClaimCount) => set({ incomingClaimCount }),
    setIncomingClaimRows: (incomingClaimRows) => set({ incomingClaimRows }),
    setTotalRows: (totalRows) => set({ totalRows }),
    setIncomingClaimRowsLoading: (incomingClaimRowsLoading) => set({ incomingClaimRowsLoading }),
  }),
  'useIncomingClaim'
);
