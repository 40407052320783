import { Stack, Tooltip } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useCatalog } from '@/hooks/CatalogHook';
import { useTasksBrowseFilter } from '../store';
import { taskMapToArray } from '../utils';
import shallow from 'zustand/shallow';
import {
  MainFilterCheckbox,
  MainFilterLocker,
  MainFilterTKOTooltipTitle,
  MainFilterWrapper,
} from './MainFilter.styled';
import { taskBrowseFilterData } from '@/components/inputs/DynamicFilter/taskBrowseData';
import DynamicInput from '@/components/inputs/DynamicFilter';
import { defaultArgInput, SAVE_FILTER_KEY } from './utils';

interface IMainFilter {
  onSubmit: () => void;
  isLocked: boolean;
  children: React.ReactNode;
  callBackFn: (queryFields: any, filterSetting: any) => void;
}

export const MainFilter: FC<IMainFilter> = ({ onSubmit, isLocked, children, callBackFn }) => {
  const {
    onlyWithChanges,
    onlyWithCallCenter,
    onlyWithCreationConfirmation,
    onlyWithTko,
    isShowingNotRelevant,
    setBooleanValue,
  } = useTasksBrowseFilter(
    (state) => ({
      onlyWithChanges: state.onlyWithChanges,
      onlyWithCallCenter: state.onlyWithCallCenter,
      onlyWithCreationConfirmation: state.onlyWithCreationConfirmation,
      isShowingNotRelevant: state.isShowingNotRelevant,
      onlyWithTko: state.onlyWithTko,
      setBooleanValue: state.setBooleanValue,
    }),
    shallow
  );

  const {
    taskGroupMap,
    getCatalogMapWithErr,
    taskTypeMap,
    taskPriorityMap,
    taskStatusMap,
    odpuManagementCompany,
    taskConsumer,
    actStatusMap,
    odpuStage,
    ppoState,
    energyCompany,
    regionalElectricNetwork,
    territorialBranch,
    ppoOdpuTechDecision,
    tags,
    getGroupCatalog,
    getTkoStatusesCatalog,
    taskTkoStatuses,
    getContractCatalog,
    meterType,
  } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getTkoStatusesCatalog: state.getTkoStatusesCatalog,
    taskTkoStatuses: state.taskTkoStatuses,
    territorialBranch: state.territorialBranch,
    taskGroupMap: state.taskGroupMap,
    taskTypeMap: state.taskType,
    taskPriorityMap: state.taskPriority,
    taskStatusMap: state.taskStatus,
    odpuManagementCompany: state.odpuManagementCompany,
    odpuStage: state.odpuStage,
    ppoState: state.ppoState,
    taskConsumer: state.taskConsumer,
    actStatusMap: state.actStatus,
    energyCompany: state.energyCompany,
    regionalElectricNetwork: state.regionalElectricNetwork,
    ppoOdpuTechDecision: state.ppoOdpuTechDecision,
    tags: state.tags,
    getGroupCatalog: state.getGroupCatalog,
    getContractCatalog: state.getContractCatalog,
    meterType: state.meterType,
  }));

  const managingOrganizationOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('odpuManagementCompany')),
    [odpuManagementCompany]
  );

  const getTaskBrowseCatalogList = (key: string): { label: string; value: string }[] => {
    switch (key) {
      case 'tkoStatus':
        return useMemo(() => taskMapToArray(getTkoStatusesCatalog()), [taskTkoStatuses]);
      case 'regionalElectricNetworkId':
        return useMemo(
          () => taskMapToArray(getCatalogMapWithErr('regionalElectricNetwork')),
          [regionalElectricNetwork]
        );
      case 'ppoOdpuTechDecisionIdList':
        return useMemo(
          () => taskMapToArray(getCatalogMapWithErr('ppoOdpuTechDecision')),
          [ppoOdpuTechDecision]
        );
      case 'electricNetworksEnterpriseId':
        return useMemo(
          () => taskMapToArray(getCatalogMapWithErr('energyCompany')),
          [energyCompany]
        );
      case 'taskStatus':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('taskStatus')), [taskStatusMap]);
      case 'managingOrganization':
        return managingOrganizationOptions;
      case 'consumer':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('taskConsumer')), [taskConsumer]);
      case 'ppoState':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('ppoState')), [ppoState]);
      case 'actStatus':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('actStatus')), [actStatusMap]);
      case 'odpuStage':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('odpuStage')), [odpuStage]);
      case 'tagList':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('tags', 0)), [tags]);
      case 'taskPriority':
        return useMemo(
          () => taskMapToArray(getCatalogMapWithErr('taskPriority')),
          [taskPriorityMap]
        );
      case 'territorialBranch':
        return useMemo(
          () => taskMapToArray(getCatalogMapWithErr('territorialBranch')),
          [territorialBranch]
        );
      case 'contractIds':
        return getContractCatalog();
      case 'contractorIds':
        return useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
      case 'taskType':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('taskType')), [taskTypeMap]);
      case 'executorIds':
        return useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
      case 'meterTypeIdList':
        return useMemo(() => taskMapToArray(getCatalogMapWithErr('meterType')), [meterType]);
      default:
        return [];
    }
  };
  return (
    <MainFilterWrapper>
      {children}
      <Stack direction='row'>
        <DynamicInput
          defaultArgInput={defaultArgInput}
          data={taskBrowseFilterData}
          callBackFn={callBackFn}
          saveFilterKey={SAVE_FILTER_KEY}
          searchFn={onSubmit}
          getCatalogList={getTaskBrowseCatalogList}
          disabledSearch={true}
          shuldRefresh
        >
          <>
            <Tooltip
              title={
                <MainFilterTKOTooltipTitle
                  dangerouslySetInnerHTML={{
                    __html: 'Будут показаны заявки,\n в которых только ТКО без замены',
                  }}
                />
              }
            >
              <span>
                <MainFilterCheckbox
                  disabled={onlyWithChanges}
                  value={onlyWithTko}
                  onChange={setBooleanValue('onlyWithTko')}
                  label={'ТКО'}
                />
              </span>
            </Tooltip>

            <MainFilterCheckbox
              disabled={onlyWithTko}
              value={onlyWithChanges}
              onChange={setBooleanValue('onlyWithChanges')}
              label={'Замены'}
            />
            <MainFilterCheckbox
              value={onlyWithCallCenter}
              onChange={setBooleanValue('onlyWithCallCenter')}
              label={'Call-центр'}
            />
            <MainFilterCheckbox
              value={onlyWithCreationConfirmation}
              onChange={setBooleanValue('onlyWithCreationConfirmation')}
              label={'Требует акцепт'}
            />
            <MainFilterCheckbox
              value={isShowingNotRelevant}
              onChange={setBooleanValue('isShowingNotRelevant')}
              label={'Неактуальные заявки'}
            />
          </>
        </DynamicInput>
      </Stack>
      {isLocked && <MainFilterLocker />}
    </MainFilterWrapper>
  );
};
