import {
  DataTypeConvert,
  getCheckedSettingByDataAccess,
  getFilterSettingByDataAccess,
} from '@/components/inputs/DynamicFilter/utils';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { createStore } from '@/utils/createStore';

export enum FilterComboErrorTypes {
  NO_INFO = 'Нет данных для сохранения набора',
  DUPLICATE = 'Набор с данным названием уже существует',
}

export interface IFilter {
  value: string | SelectFilterOptType[] | number[];
  argValue: number;
  label?: string;
}

export interface IFilterCombo {
  [key: string]: IFilter | boolean | SelectFilterOptType[];
}

interface Filters {
  accessData: { [filterType: string]: DataTypeConvert[] };
  tmpFilter: {
    [filterType: string]: IFilterCombo;
  };
  allFilters: {
    [filterType: string]: {
      filterName: string;
      filters: IFilterCombo;
    }[];
  };

  addFilters: (filterType: string, newFilterName: string, newFilters?: IFilterCombo) => void;
  addTmpFilters: (filterType: string, newFilters?: IFilterCombo) => void;
  removeFilter: (filterType: string, filterName: string) => void;
  setAccesData: (filterType: string, accessData: DataTypeConvert[]) => void;
}
export const useStoredFilters = createStore<Filters>(
  (set, get) => ({
    allFilters: {},
    tmpFilter: {},
    accessData: {},
    addTmpFilters: (filterType, newFilters) => {
      if (!newFilters) {
        throw new Error(FilterComboErrorTypes.NO_INFO);
      }
      const filterAccessData = get().accessData[filterType] || []; // данные по filterType
      set(() => {
        const checked = getCheckedSettingByDataAccess(filterAccessData, newFilters);
        const filterSetting = getFilterSettingByDataAccess(filterAccessData, newFilters);

        return {
          tmpFilter: {
            ...get().tmpFilter,
            [filterType]: {
              ...newFilters,
              checked,
              filterSetting,
            },
          },
        };
      });
    },

    setAccesData: (filterType, data) => {
      set(() => ({
        accessData: {
          ...get().accessData,
          [filterType]: data, // данные только для конкретного filterType
        },
      }));
    },

    addFilters: (filterType, newFilterName, newFilters) => {
      const newAllFilters = { ...get().allFilters };
      const filterTypeExist = newAllFilters[filterType];
      if (!newFilters) {
        throw new Error(FilterComboErrorTypes.NO_INFO);
      }
      if (filterTypeExist) {
        const duplicateName = filterTypeExist?.find(
          (filter) => filter.filterName === newFilterName
        );
        if (duplicateName) {
          throw new Error(FilterComboErrorTypes.DUPLICATE);
        } else {
          filterTypeExist.push({ filterName: newFilterName, filters: newFilters });
          set(() => ({
            allFilters: newAllFilters,
          }));
        }
      } else {
        newAllFilters[filterType] = [{ filterName: newFilterName, filters: newFilters }];

        set(() => ({
          allFilters: newAllFilters,
        }));
      }
    },

    removeFilter: (filterType: string, removeFilterName: string) => {
      const newAllFilters = { ...get().allFilters };
      const filterTypeExist = newAllFilters[filterType];
      if (filterTypeExist) {
        const test = newAllFilters[filterType]?.filter((filter) => {
          return filter.filterName !== removeFilterName;
        });

        newAllFilters[filterType] = test;

        set(() => ({
          allFilters: newAllFilters,
        }));
      } else {
        return;
      }
    },
  }),
  'taskBrowseFilters',
  'taskBrowseFilters'
);
