import { AxiosResponse } from 'axios';
import { HOST } from '../YodaRestService';
import { axiosGet, axiosPost } from '../helpers';

import {
  DataAddressItem,
  ExecuterScheduleMain,
  ExecuterType,
  IInstallationCall,
  IgeoPointCallingMap,
  RequestHistoryType,
  TechInspectionType,
} from '@/components/maps/callingMap/types/types';
import { GeoData } from '@/components/maps/callingMap/CallingMap';
import { ApiResponse } from '@/types/api';
import {
  IncomingCallDateResponseDto,
  IncommingCallClaimResponseDto,
} from './dto/CallingMapServiceResponseDto';

export async function findAllInstallationCallMap(
  body: any
): Promise<AxiosResponse<IgeoPointCallingMap[], any>> {
  const {
    formatedFilter: { filterItemList },
    polygon,
    limit,
  } = body;
  const url = HOST + `/rest/installation-call/search${limit ? `?limit=${limit}` : ''}`;

  return axiosPost(url, { polygon, filterItemList });
}
export async function findPreClusterGeoPointsCallMap(
  body: GeoData
): Promise<AxiosResponse<IgeoPointCallingMap[], any>> {
  const url = HOST + `/rest/installation-call/pre-cluster`;
  return axiosPost(url, body);
}
export async function findPreClusterPolygonGeoPointsCallMap(
  body: GeoData
): Promise<AxiosResponse<IgeoPointCallingMap[], any>> {
  const url = HOST + `/rest/installation-call/pre-cluster/polygon`;
  return axiosPost(url, body);
}

export async function getSingleTaskfromCallMap(
  taskId: string
): Promise<AxiosResponse<IInstallationCall, any>> {
  const url = HOST + `/rest/installation-call/${taskId}`;
  return axiosGet(url, null);
}
export async function getSingleTKOCallMap(
  taskId: string | null
): Promise<AxiosResponse<TechInspectionType[], any>> {
  const url = HOST + `/rest/task/${taskId}/tech-inspection`;
  return axiosGet(url, null);
}
export async function getSingleRrquestHisoryCallMap(
  taskId: string | null
): Promise<AxiosResponse<RequestHistoryType[], any>> {
  const url = HOST + `/rest/callcentre/task/${taskId}/short`;
  return axiosGet(url, null);
}
export async function getExecutersAvalibleCallMap(): Promise<AxiosResponse<ExecuterType[], any>> {
  const url = HOST + `/rest/installation-call/available-executors`;
  return axiosGet(url, null);
}
export async function getGeoLessPointsOld(): Promise<AxiosResponse<string[], any>> {
  const url = HOST + `/rest/installation-call/geoless`;
  return axiosGet(url, null);
}
export async function getGeoLessPoints(
  params: any
): Promise<AxiosResponse<IgeoPointCallingMap[], any>> {
  const url =
    HOST +
    `/rest/installation-call/search/invalid-coordinates?offset=${
      params.page * params.pageSize
    }&limit=${params.pageSize}`;
  return axiosPost(url, params.body);
}
export async function getGeoLessPointsCount(body: any): Promise<AxiosResponse<number, any>> {
  const url = HOST + `/rest/installation-call/search/invalid-coordinates/count`;
  return axiosPost(url, body);
}
export async function addInstallationCallLogs(body: any): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/installation-call`;
  return axiosPost(url, body);
}
export async function getSingleGeoTask(taskId: string | null): Promise<AxiosResponse<string, any>> {
  const url =
    HOST +
    `/rest/installation-call/${taskId}/general-status
`;
  return axiosGet(url, null);
}
export async function getAllExecutersCallMap(): Promise<AxiosResponse<ExecuterType[], any>> {
  const url = HOST + `/rest/installation-call/executors`;
  return axiosGet(url, null);
}
export async function sendExecutersCallMap(body: string[]): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/installation-call/executors`;
  return axiosPost(url, body);
}
export async function getUrgentPointsCountCallMap(): Promise<AxiosResponse<number, any>> {
  const url = HOST + `/rest/installation-call/urgent/count`;
  return axiosGet(url, null);
}
export async function getUrgentPointsCallMap(): Promise<AxiosResponse<IgeoPointCallingMap[], any>> {
  const url = HOST + `/rest/installation-call/urgent`;
  return axiosGet(url, null);
}
export async function getExecutorsSchedule(
  timeStart: string,
  timeEnd: string,
  executorId?: string
): Promise<AxiosResponse<ExecuterScheduleMain, any>> {
  const url =
    HOST +
    `/rest/installation-call/workload?start=${timeStart}&end=${timeEnd}${
      executorId ? `&executorId=${executorId}` : ''
    }`;
  return axiosGet(url, null);
}

export async function getTasksByIdsCallMap(body: string[]): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/installation-call/task/find-by-ids`;
  return axiosPost(url, body);
}
export async function getTaskByIdCallMap(
  termTaskId: string
): Promise<AxiosResponse<IgeoPointCallingMap[], any>> {
  const url = HOST + `/rest/installation-call/task/${termTaskId}`;
  return axiosGet(url, null);
}
export async function getTaskByAdressCallMap(
  termAdress: string
): Promise<AxiosResponse<DataAddressItem[], any>> {
  const url = HOST + `/rest/address-table?address=${termAdress}`;
  return axiosGet(url, null);
}
export async function getTaskByNumberCallMap(
  termNumber: string
): Promise<AxiosResponse<IgeoPointCallingMap[], any>> {
  const url = HOST + `/rest/installation-call/phone-number/${termNumber}`;
  return axiosGet(url, null);
}
export async function getTasksByAdressIdCallMap(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/installation-call/address/${id}`;
  return axiosGet(url, null);
}

export async function getIncomingCallDates(
  month: number,
  year: number
): ApiResponse<IncomingCallDateResponseDto[]> {
  const url = HOST + `/rest/callcentre/calendar?year=${year}&month=${month}`;
  return axiosGet(url, null);
}
export async function getIncomingCallRequestsPerDay(
  date: string, //Формат yyyy-MM-dd
  offset: number,
  limit: number
): ApiResponse<IncommingCallClaimResponseDto[]> {
  const url =
    HOST + `/rest/callcentre/calendar/day-requests?date=${date}&offset=${offset}&limit=${limit}`;
  return axiosGet(url, null);
}

export async function getIncomingCallCount(): ApiResponse<number | null> {
  const url = HOST + `/rest/callcentre/calendar/unassigned-count`;
  return axiosGet(url, null);
}
