import { CalendarPicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { Badge, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse, isSameDay, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import {
  CallingMapIncomingTasksBackdrop,
  CallingMapIncomingTasksWrapper,
} from './CallingMapIncomingTasks.styled';
import { IncomingCallDateResponseDto } from '@/services/CallingMapService/dto/CallingMapServiceResponseDto';
import {
  getIncomingCallDates,
  getIncomingCallRequestsPerDay,
} from '@/services/CallingMapService/CallingMapService';
import { getMonthAndYear } from './utils';
import { fetchCatch } from '@/hooks/ActionLogHook';
import shallow from 'zustand/shallow';
import { useIncomingClaim } from '../CallingMapIncomingTasksTab/store';
import CallingMapIncomingClaimTable from './CallingMapIncomingClaimTable';

const CallingMapIncomingTasks = () => {
  const { pageSize, currentPage, setIncomingClaimRows, setIncomingClaimRowsLoading, setTotalRows } =
    useIncomingClaim(
      (state) => ({
        pageSize: state.pageSize,
        currentPage: state.currentPage,
        setIncomingClaimRows: state.setIncomingClaimRows,
        setIncomingClaimRowsLoading: state.setIncomingClaimRowsLoading,
        setTotalRows: state.setTotalRows,
      }),
      shallow
    );

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date>(() => new Date());
  const [availableDates, setAvailableDates] = useState<IncomingCallDateResponseDto[]>([]);
  const [isLoadingAvailableDates, setIsLoadingAvailableDates] = useState(false);

  const fetchAvailableDates = (month: number, year: number) => {
    setIsLoadingAvailableDates(true);

    getIncomingCallDates(month, year)
      .then(({ data }) => setAvailableDates(data))
      .catch(fetchCatch)
      .finally(() => setIsLoadingAvailableDates(false));
  };

  const handleMonthChange = (date: Date) => {
    setSelectedDate(null);
    setSelectedMonth(date);
  };

  useEffect(() => {
    const { month, year } = getMonthAndYear(selectedMonth);

    fetchAvailableDates(month, year);
  }, [selectedMonth]);

  useEffect(() => {
    if (!selectedDate) return;
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    setIncomingClaimRowsLoading(true);

    getIncomingCallRequestsPerDay(formattedDate, pageSize * currentPage, pageSize)
      .then(({ data }) => setIncomingClaimRows(data))
      .catch(fetchCatch)
      .finally(() => setIncomingClaimRowsLoading(false));
  }, [selectedDate, pageSize, currentPage]);

  useEffect(() => {
    if (!selectedDate || !availableDates) return;

    const selectedDateStr = format(selectedDate, 'yyyy-MM-dd');
    const matchedDate = availableDates.find((availableDate) => {
      return availableDate.date === selectedDateStr;
    });
    if (!matchedDate) return;
    setTotalRows(matchedDate.count);
  }, [selectedDate, availableDates]);

  const isDateDisabled = (date: Date) => {
    return !availableDates.some((availableDate) => {
      return isSameDay(date, parse(availableDate.date, 'yyyy-MM-dd', new Date()));
    });
  };

  //  Рендеринг ячейки с Badge
  const renderDay = (day: Date, _selectedDays: Date[], pickersDayProps: any) => {
    const matchedDate = availableDates.find((availableDate) =>
      isSameDay(day, parse(availableDate.date, 'yyyy-MM-dd', new Date()))
    );

    return (
      <Badge
        key={day.toString()}
        badgeContent={
          matchedDate ? <span style={{ pointerEvents: 'none' }}>{matchedDate.count}</span> : null
        }
        color='error'
        overlap='circular'
      >
        <PickersDay {...pickersDayProps} />
      </Badge>
    );
  };

  return (
    <CallingMapIncomingTasksWrapper>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ru}
      >
        <div style={{ position: 'relative' }}>
          <CalendarPicker
            date={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            shouldDisableDate={isDateDisabled}
            onMonthChange={handleMonthChange}
            renderDay={renderDay} // Передаем кастомный рендеринг дней
            views={['day']}
          />
          <CallingMapIncomingTasksBackdrop open={isLoadingAvailableDates}>
            <CircularProgress />
          </CallingMapIncomingTasksBackdrop>
        </div>
      </LocalizationProvider>
      <CallingMapIncomingClaimTable />
    </CallingMapIncomingTasksWrapper>
  );
};

export default CallingMapIncomingTasks;
