import { Tooltip, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import HousePnrBrowseInfo from './HousePnrBrowseInfo';

export const getPercentFromNumber = (value?: number, from?: number): number => {
  if (value && from) {
    return Math.round((value / from) * 100);
  }
  return 0;
};
export const getColumns = (): GridColDef[] => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hide: true,
  },
  {
    field: 'address',
    width: 500,
    filterable: false,
    sortable: false,
    headerName: 'Адрес',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Tooltip title={params.value + ''}>
        <Link
          style={{ widows: '100%' }}
          target='_blank'
          to={`/pnr-entrance/edit/${params.row.houseId}`}
          tabIndex={params.hasFocus ? 0 : -1}
        >
          {params.value + ''}
        </Link>
      </Tooltip>
    ),
  },
  {
    field: 'floorCount',
    width: 130,
    filterable: false,
    sortable: false,
    headerName: 'Кол-во этажей',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        variant='caption'
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'flatCount',
    width: 130,
    filterable: false,
    sortable: false,
    headerName: 'Кол-во квартир',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        variant='caption'
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'entranceCount',
    width: 160,
    filterable: false,
    sortable: false,
    headerName: 'Кол-во подъездов',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        variant='caption'
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'ipuCount',
    width: 180,
    filterable: false,
    sortable: false,
    headerName: 'Процент монтажа ИПУ',
    renderCell: (params: GridRenderCellParams<string>) => {
      const montagePercent = getPercentFromNumber(params.row?.ipuCount, params.row?.flatCount);

      return (
        <Typography
          fontSize={14}
          variant='caption'
        >
          {montagePercent}%
        </Typography>
      );
    },
  },
  {
    field: 'odpuManagementCompanyName',
    filterable: false,
    sortable: false,
    flex: 1,
    headerName: 'Управляющая компания',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Tooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          variant='caption'
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {params.value ?? ''}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'odpuManagementCompanyName2',
    filterable: false,
    sortable: false,
    width: 120,
    align: 'center',

    headerName: 'Информация',
    renderCell: (params: GridRenderCellParams<string>) => {
      return <HousePnrBrowseInfo id={params?.row?.houseId} />;
    },
  },
];
