import { useInvestStatisticStore } from '../../store';
import { colorsSegment, formatCount } from '../utils';
import { LegendItem, LegendItemHeader, LegendItemTitle } from './BudgetStatisticDoughnut.styled';
import React from 'react';
import { DoughnutShowInfo } from './DoughnutShowInfo';
import { StatistiSummaryResponse } from '../../api/dto/response';
import { AccordionDetails } from '@mui/material';
import { StatisticInvestProgramItem } from '../../types/types';
import shallow from 'zustand/shallow';

export interface DoughnutLegendItemProps {
  item: StatistiSummaryResponse;
  withNartis?: boolean;
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: d3.PieArcDatum<StatisticInvestProgramItem> | { index: number }
  ) => void;
  index: number;
}

export const DoughnutLegendItem = ({
  item,
  withNartis,
  onClick,
  index,
}: DoughnutLegendItemProps) => {
  const { showInfo, countAsMoney } = useInvestStatisticStore(
    (state) => ({
      showInfo: state.showInfo,
      countAsMoney: state.countAsMoney,
    }),
    shallow
  );

  const title = item.title as keyof typeof colorsSegment;
  const background = colorsSegment[title][0];

  return (
    <>
      <LegendItem
        expanded={showInfo}
        background={background}
        onClick={(e) => onClick(e, { index })}
      >
        <LegendItemHeader background={background}>
          <LegendItemTitle>{item.title}</LegendItemTitle>
          <LegendItemTitle>{countAsMoney ? formatCount(item.count) : item.count}</LegendItemTitle>
        </LegendItemHeader>

        <AccordionDetails style={{ padding: 0 }}>
          <DoughnutShowInfo
            item={item}
            withNartis={withNartis}
          />
        </AccordionDetails>
      </LegendItem>
    </>
  );
};
