import { CircularProgress, Tab, TabProps } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { useIncomingClaim } from './store';
import shallow from 'zustand/shallow';
import { getIncomingCallCount } from '@/services/CallingMapService/CallingMapService';
import { fetchCatch } from '@/hooks/ActionLogHook';

export type CallingMapIncomingTasksTabProps = TabProps;

const CallingMapIncomingTasksTab = (props: CallingMapIncomingTasksTabProps) => {
  const { incomingClaimCount, setIncomingClaimCount } = useIncomingClaim(
    (state) => ({
      incomingClaimCount: state.incomingClaimCount,
      setIncomingClaimCount: state.setIncomingClaimCount,
    }),
    shallow
  );

  const fetchIncomingClaimCount = () => {
    setIncomingClaimCount('pending');

    getIncomingCallCount()
      .then(({ data }) => {
        setIncomingClaimCount(data);
      })
      .catch(fetchCatch);
  };

  useLayoutEffect(() => {
    fetchIncomingClaimCount();
  }, []);

  return (
    <Tab
      label={
        <span style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <span>Календарь входящих заявок</span>
          {incomingClaimCount === 'pending' ? (
            <CircularProgress
              size={10}
              style={{ marginBottom: 3 }}
            />
          ) : (
            <span style={{ color: 'red' }}>
              {!!incomingClaimCount && `(${incomingClaimCount})`}
            </span>
          )}
        </span>
      }
      {...props}
    />
  );
};

export default CallingMapIncomingTasksTab;
