import { Box, Button, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import {
  ApiUpdateAddressDetail,
  ApiRefreshAddressDetail,
  ApiGetAddressDetailByTaskId,
  ApiGetAddressDetailCoordsByTaskId,
} from '@/services/YodaRestService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { DadataResponseDto, TaskAddressDetail } from '@/dto/taskmap/Dto';
import CachedIcon from '@mui/icons-material/Cached';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useAccess';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { AddressEditLabelDialogLoading } from './AddressEditLabelDialog.styled';
import HouseLink from '../HouseLink/HouseLink';

interface AddressEditLabelDialogProps {
  taskResponse: TaskResponse;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1116,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddressEditLabelDialog(props: AddressEditLabelDialogProps) {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { fetchCatch, addActionLog } = useActionLog();
  const { handleSubmit, control, getValues, setValue, register } = useForm<TaskAddressDetail>();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addressDetail, setAddressDetail] = useState<TaskAddressDetail>();
  const [daDataResponse, setDaDataResponse] = useState<DadataResponseDto>();
  const [daDataLoading, setDaDataLoading] = useState(false);
  const [fias, setFias] = useState<string>('');
  const [coords, setCoords] = useState<string>('');

  useEffect(() => {
    if (props.taskResponse) {
      ApiGetAddressDetailByTaskId(props.taskResponse.id)
        .then((value) => {
          setAddressDetail(value.data);
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка при получении адреса');
        });
    }
  }, [props.taskResponse]);

  useEffect(() => {
    setValue('regionName', addressDetail?.regionName);
    setValue('areaName', addressDetail?.areaName);
    setValue('localityNameMob', addressDetail?.localityNameMob);
    setValue('streetNameMob', addressDetail?.streetNameMob);
    setValue('house', addressDetail?.house);
    setValue('korpus', addressDetail?.korpus);
    setValue('stroenie', addressDetail?.stroenie);
    setValue('apartment', addressDetail?.apartment);
    setValue('districtName', addressDetail?.districtName);
  }, [addressDetail]);

  useEffect(() => {
    if (open && !daDataResponse) {
      setDaDataLoading(true);
      ApiGetAddressDetailCoordsByTaskId(props.taskResponse.id)
        .then((value) => setDaDataResponse(value.data))
        .catch((err) => fetchCatch(err, 'Ошибка при получении ФИАС и координат'))
        .finally(() => setDaDataLoading(false));
    }
    if (daDataResponse?.fias) {
      setFias(daDataResponse?.fias);
    }
    if (daDataResponse?.latitudeY && daDataResponse?.longitudeX) {
      setCoords(daDataResponse?.latitudeY + '; ' + daDataResponse?.longitudeX);
    }
  }, [open, daDataResponse]);

  const onSubmit: SubmitHandler<TaskAddressDetail> = (data) => {
    saveAddressDetail(data);
    handleClose();
  };

  const handleUpdateAddress = () => {
    const data = getValues();

    if (addressDetail) {
      data.id = addressDetail.id;
      ApiRefreshAddressDetail(data)
        .then((value) => {
          setAddressDetail(value.data);
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка при обновлении адреса');
        });
    }
  };

  const saveAddressDetail = (data: TaskAddressDetail) => {
    if (addressDetail) {
      data.id = addressDetail.id;
      ApiUpdateAddressDetail(data)
        .then((value) => {
          setAddressDetail(value.data);
          addActionLog(ActionLogType.SUCCESS, 'Адрес обновлен');
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка при обновлении адреса');
        });
    }
  };

  const closeDialog = () => {
    ApiGetAddressDetailByTaskId(props.taskResponse.id)
      .then((value) => {
        setAddressDetail(value.data);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка при получении адреса');
      });
    handleClose();
  };

  return (
    <div>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        gap={1}
      >
        <CopyButton
          onlyIcon={true}
          textToCopy={`${addressDetail?.readOnlyAddress} ${
            addressDetail?.apartment ? ` кв. ${addressDetail?.apartment}` : ''
          }`}
        />
        <Button
          variant='text'
          onClick={handleOpen}
          style={{ textOverflow: 'ellipsis' }}
        >
          {addressDetail?.readOnlyAddress}
          {addressDetail?.apartment && ` кв. ${addressDetail?.apartment}`}
        </Button>
        <HouseLink taskType={props?.taskResponse?.type} />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}
            variant='h5'
            component='h2'
          >
            Внесите изменения по адресу
          </Typography>
          <form
            className='request-form__form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={4}
                >
                  <Controller
                    control={control}
                    name='regionName'
                    render={({ field }) => (
                      <TextField
                        {...register('regionName')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        label='Регион'
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Controller
                    control={control}
                    name='areaName'
                    render={({ field }) => (
                      <TextField
                        {...register('areaName')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        style={{ marginLeft: '20px' }}
                        label='Район'
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Controller
                    control={control}
                    name='districtName'
                    render={({ field }) => (
                      <TextField
                        {...register('districtName')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        style={{ marginLeft: '20px' }}
                        fullWidth
                        label='Район города'
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                >
                  <Controller
                    control={control}
                    name='localityNameMob'
                    render={({ field }) => (
                      <TextField
                        {...register('localityNameMob')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        label='Населенный пункт'
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Controller
                    control={control}
                    name='streetNameMob'
                    render={({ field }) => (
                      <TextField
                        {...register('streetNameMob')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        label='Улица'
                        style={{ marginLeft: '20px' }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                >
                  <Controller
                    control={control}
                    name='house'
                    render={({ field }) => (
                      <TextField
                        {...register('house')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        style={{ marginLeft: '20px' }}
                        label='Дом'
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                >
                  <Controller
                    control={control}
                    name='korpus'
                    render={({ field }) => (
                      <TextField
                        {...register('korpus')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        style={{ marginLeft: '20px' }}
                        label='Корпус'
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                >
                  <Controller
                    control={control}
                    name='stroenie'
                    render={({ field }) => (
                      <TextField
                        {...register('stroenie')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        style={{ marginLeft: '20px' }}
                        label='Строение'
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                >
                  <Controller
                    control={control}
                    name='apartment'
                    render={({ field }) => (
                      <TextField
                        {...register('apartment')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        style={{ marginLeft: '20px' }}
                        label='Квартира'
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={11}
                >
                  <TextField
                    variant='filled'
                    disabled
                    fullWidth
                    value={addressDetail?.readOnlyAddress}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                >
                  <CachedIcon
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    onClick={handleUpdateAddress}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Controller
                    control={control}
                    name='cadaster'
                    render={({ field }) => (
                      <TextField
                        {...register('cadaster')}
                        variant='standard'
                        onChange={(e) => field.onChange(e)}
                        value={field.value ? field.value : ''}
                        label='Кадастровый номер'
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                />
                <Grid
                  item
                  xs={6}
                >
                  <AddressEditLabelDialogLoading loading={daDataLoading}>
                    <TextField
                      variant='standard'
                      value={fias}
                      disabled
                      fullWidth
                      label='ФИАС'
                    />
                  </AddressEditLabelDialogLoading>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <AddressEditLabelDialogLoading loading={daDataLoading}>
                    <TextField
                      variant='standard'
                      value={coords ?? '-'}
                      fullWidth
                      disabled
                      label='Координаты'
                    />
                  </AddressEditLabelDialogLoading>
                </Grid>
              </Grid>
            </Box>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <Button
                style={{ marginRight: '10px' }}
                type='submit'
                variant='contained'
                disabled={disabledAll || onlyShow}
              >
                Сохранить
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={(e) => {
                  closeDialog();
                }}
              >
                Отменить
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
