import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { IdPopup } from '../panel/IdPopup';
import { useParams } from 'react-router-dom';
import { addSuccessActionLog, useActionLog } from '@/hooks/ActionLogHook';
import { ApiGetHouseByTaskId, ApiSetTaskHouseLink } from '@/services/YodaRestService';
import {
  HouseLinkInner,
  HouseLinkInnerColumn,
  HouseLinkInnerRow,
  HouseLinkLoading,
  HouseLinkStrong,
} from './HouseLink.styled';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { HouseEntrancePencilButton } from '../../pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import EditHouseLinkModal from './EditHouseLinkModal';
import { enableHouseLink } from './utils';

// FIXME:до реализации задачи с рефакторингом ДТО лежит здесь
export interface TaskHouseLinkResponseDto {
  id?: string; //uuid
  fias?: string; //uuid
  address?: string;
}

type HouseLinkData = TaskHouseLinkResponseDto;
interface HouseLinkProps {
  taskType?: string;
}

const HouseLink = ({ taskType }: HouseLinkProps) => {
  const { fetchCatch } = useActionLog();
  const { taskId } = useParams();
  const [houseLinkData, setHouseLinkData] = useState<HouseLinkData | null>(null);
  const [houseLinkDataLoading, setHouseLinkDataLoading] = useState(false);
  const [isUpdatingHouseLink, setIsUpdatingHouseLink] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const handleCloseModal = () => {
    setIsOpenEditModal(false);
  };

  const handleSaveNewHouseLink = (houseId: string) => {
    if (!taskId) return;

    setIsUpdatingHouseLink(true);
    ApiSetTaskHouseLink(houseId, taskId)
      .then(({ data }) => {
        setHouseLinkData(data);
        setIsOpenEditModal(false);
        addSuccessActionLog('Ссылка на дом обновлена');
      })
      .catch(fetchCatch)
      .finally(() => setIsUpdatingHouseLink(false));
  };

  const handleClick = () => {
    if (!taskId || houseLinkData) return;
    setHouseLinkDataLoading(true);
    ApiGetHouseByTaskId(taskId)
      .then(({ data }) => setHouseLinkData(data))
      .finally(() => setHouseLinkDataLoading(false));
  };

  if (!enableHouseLink(taskType)) return null;

  return (
    <div>
      <IdPopup
        title={
          <IconButton
            size='small'
            onClick={handleClick}
          >
            <HomeRoundedIcon color='primary' />
          </IconButton>
        }
      >
        <HouseLinkInner>
          <HouseLinkInnerColumn>
            <HouseLinkInnerRow>
              <HouseLinkStrong>Адрес дома:</HouseLinkStrong>
              <HouseLinkLoading loading={houseLinkDataLoading}>
                {houseLinkData?.id && houseLinkData?.address ? (
                  <a
                    href={`/pnr-entrance/edit/${houseLinkData?.id}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {houseLinkData?.address}
                  </a>
                ) : (
                  <span>Не указан</span>
                )}
              </HouseLinkLoading>
            </HouseLinkInnerRow>
            <HouseLinkInnerRow>
              <HouseLinkStrong>ФИАС:</HouseLinkStrong>
              <HouseLinkLoading loading={houseLinkDataLoading}>
                {houseLinkData?.fias || 'Не указан'}
                {houseLinkData?.fias && (
                  <CopyButton
                    onlyIcon
                    textToCopy={houseLinkData?.fias}
                  />
                )}
              </HouseLinkLoading>
            </HouseLinkInnerRow>
            <HouseLinkInnerRow>
              <HouseLinkStrong>Id дома: </HouseLinkStrong>
              <HouseLinkLoading loading={houseLinkDataLoading}>
                {houseLinkData?.id}
                {houseLinkData?.id ? (
                  <CopyButton
                    onlyIcon
                    textToCopy={houseLinkData?.id}
                  />
                ) : (
                  'Не указана'
                )}
              </HouseLinkLoading>
            </HouseLinkInnerRow>
          </HouseLinkInnerColumn>
          <HouseEntrancePencilButton
            size='small'
            onClick={() => setIsOpenEditModal(true)}
          >
            <BorderColorRoundedIcon
              color='primary'
              fontSize='inherit'
            />
          </HouseEntrancePencilButton>
        </HouseLinkInner>
      </IdPopup>

      <EditHouseLinkModal
        open={isOpenEditModal}
        onClose={handleCloseModal}
        onSave={handleSaveNewHouseLink}
        isLoading={isUpdatingHouseLink}
      />
    </div>
  );
};

export default HouseLink;
