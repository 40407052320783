import { useMemo } from 'react';
import { useRole } from '@/hooks/useRole';
import { Accesses } from '@/app/auth/accesses';
import { getColumnsRenderCell } from '@/components/features/tasksBrowse/constants';
import { useCol } from '@/components/features/tasksBrowse/storeCol';
import { RolesEnum, AccessEnum } from '@/app/auth/roles';

const HIDED_COLUMNS_WITHOUT_ACCESS = ['contractorName', 'executorName'];
const ROLE_WITHOUT_ACCESS = AccessEnum.CastomPskClose;

export const useColumnsWithAccess = () => {
  const { role } = useRole();
  const { columns } = useCol((state) => ({
    columns: state.columns,
  }));

  const access = useMemo(() => {
    if (role) {
      return role?.some(
        (item: RolesEnum) =>
          !!Accesses[item] &&
          Accesses[item].find((accessItem) => accessItem === ROLE_WITHOUT_ACCESS)
      );
    } else return null;
  }, [role]);

  const renderColumns = () => {
    const accessedColumns = access
      ? columns.taskBrowseCol
      : columns.taskBrowseCol?.filter(
          (column) => !HIDED_COLUMNS_WITHOUT_ACCESS.includes(column.field)
        );
    const renderCellColumns = getColumnsRenderCell();

    // Свойство renderCell невозможно сохранить в localStorage
    const columnsWithAccessAndRenderCells = accessedColumns?.map((accessColumn) => {
      const columnRenderCell = renderCellColumns.find(
        (column) => column.field === accessColumn.field
      );
      return columnRenderCell ? { ...accessColumn, ...columnRenderCell } : accessColumn;
    });

    return columnsWithAccessAndRenderCells;
  };

  const colmnsWithAccessAndRenderCell = renderColumns();

  return { colmnsWithAccessAndRenderCell };
};
