import React from 'react';
import { useNavbar } from '../store';
import { NavbarLinks } from '../NavbarLinks/NavbarLinks';
import { Drawer } from '@mui/material';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavbarActionButtons from '../NavbarActionButtons';
import { MobileActionButtonsWrapper } from './MobileNavbar.styled';
import NavbarLogoWithBolt from '../NavbarLogoWithBolt';
import Divider from '@mui/material/Divider';

export const MobileNavbar = () => {
  const { toggleDrawer, isOpenMobileNavbar } = useNavbar();
  return (
    <>
      <MobileActionButtonsWrapper>
        <NavbarActionButtons />
      </MobileActionButtonsWrapper>
      <IconButton
        onClick={() => toggleDrawer(true)}
        sx={{ ml: 'auto' }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={isOpenMobileNavbar}
        onClose={() => toggleDrawer(false)}
      >
        <div style={{ paddingTop: '11px' }}>
          <NavbarLogoWithBolt />
          <Divider sx={{ margin: '8px 0' }}></Divider>
          <NavbarLinks />
        </div>
      </Drawer>
    </>
  );
};
