import { HOST } from './YodaRestService';
import { axiosGet, axiosPost } from './helpers';
import { ApiResponse } from '@/types/api';

export async function downloadVRU(reportTemplate: string, vruInputId: string): ApiResponse<any> {
  const url = HOST + `/rest/report/adpu-vru`;
  return axiosPost(url, { vruInputId, reportTemplate }, true);
}
export async function downloadPresent(
  reportTemplate: string,
  presentEqInfoId: string
): ApiResponse<any> {
  const url = HOST + `/rest/report/adpu-present`;
  return axiosPost(url, { presentEqInfoId, reportTemplate }, true);
}
export async function download(reportTemplate: string, objectId: string): ApiResponse<any> {
  const url = HOST + `/rest/report/adpu`;
  return axiosPost(url, { objectId, reportTemplate }, true);
}
export async function downloadTKO(reportTemplate: string, objectId: string): ApiResponse<any> {
  const url = HOST + `/rest/report/act-tko`;
  return axiosPost(url, { objectId, reportTemplate }, true);
}

export async function getAdpuArray(): ApiResponse<string[]> {
  const url = HOST + `/rest/report/adpu/types`;
  return axiosGet(url);
}
export async function getTKOArray(): ApiResponse<string[]> {
  const url = HOST + `/rest/report/templates?code=tkoActs`;
  return axiosGet(url);
}
