import axios from 'axios';
import { HOST, yodaGetAccessToken } from '@/services/YodaRestService';
import { axiosGet, axiosPost } from '@/services/helpers';
import {
  CreateRegistryADPUFilterRequest,
  RegistryADPUFileResponse,
  RegistryADPUFolderResponse,
} from '@/dto/RegistryADPUDto';
import { ApiResponse } from '@/types/api';

export async function GetRegistryADPUFolders(
  data?: CreateRegistryADPUFilterRequest
): ApiResponse<RegistryADPUFolderResponse[]> {
  const url = HOST + `/rest/adpu/registry/find-by-param?offset=0&limit=1000`;
  return axiosPost(url, { ...data });
}

export async function GetRegistryADPUFiles(id: string): ApiResponse<RegistryADPUFileResponse> {
  const url = HOST + `/rest/adpu/registry/${id}`;
  return axiosGet(url);
}

export async function CreateRegistryADPU(data: {
  number: string;
  signingDate: Date;
  taskIdList: string[];
}): ApiResponse<RegistryADPUFolderResponse> {
  const url = HOST + `/rest/adpu/registry`;
  return axiosPost(url, data);
}

export async function UploadZipFile(file: File): ApiResponse<any> {
  const url = HOST + `/rest/file/postal-departure`;
  const token = await yodaGetAccessToken();
  const formData = new FormData();
  formData.append('zip', file);
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
}
