import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accesses } from '@/app/auth/accesses';
import { AccessEnum, RolesEnum } from '@/app/auth/roles';
import { useRole } from './useRole';

interface IParams {
  access: AccessEnum[];
  stopRedirect?: boolean;
}

/**
 * Хук для проверки доступа пользователя на основе его ролей.
 * Если доступ не разрешен, перенаправляет на главную страницу (если `stopRedirect` не установлен в true).
 *
 * @param {IParams} params - Параметры хука:
 * - `access`: Массив значений `AccessEnum`, которые указывают на требуемый доступ.
 * - `stopRedirect`: Флаг для предотвращения перенаправления (по умолчанию - false).
 *
 * @returns {boolean} Возвращает `true`, если у пользователя есть доступ, иначе `false`.
 *
 * @example
 * const hasAccess = useAccess({ access: [AccessEnum.Edit, AccessEnum.View] });
 * console.log(hasAccess); // true или false в зависимости от ролей пользователя
 */
export const useAccess = ({ access, stopRedirect }: IParams) => {
  const navigate = useNavigate();
  const { role } = useRole();

  const hasAccess = useMemo(() => {
    if (access && role) {
      return role?.some(
        (item: RolesEnum) =>
          !!Accesses[item] &&
          Accesses[item].find(
            (accessItem) => !!access.find((innerAcces) => innerAcces === accessItem)
          )
      );
    }
  }, [access, role]);

  if (role && !hasAccess && !stopRedirect) {
    navigate('/');
  }

  return !!hasAccess;
};

interface DisableAccessParams {
  disabledRoles: RolesEnum[];
  refreshCondition?: string;
}

interface AllowAccessParams {
  allowRoles: RolesEnum[];
}

/**
 * Хук для проверки, заблокирован ли доступ для пользователя на основе его ролей.
 * Если роль пользователя совпадает с одной из запрещенных ролей, доступ блокируется.
 *
 * @param {DisableAccessParams} params - Параметры хука:
 * - `disabledRoles`: Массив ролей, доступ которым запрещен.
 * - `refreshCondition`: Условие для пересчета состояния доступа (например, если пропсы приходят динамически).
 *
 * @returns {boolean} Возвращает `true`, если доступ заблокирован, иначе `false`.
 *
 * @example
 * const isAccessDisabled = useDisabledAccess({ disabledRoles: [RolesEnum.User] });
 * console.log(isAccessDisabled); // true или false в зависимости от роли пользователя
 */
export const useDisabledAccess = ({ disabledRoles, refreshCondition }: DisableAccessParams) => {
  const { role } = useRole();
  const [disableAccess, setDisableAccess] = useState(true);
  const memoizedRoles = useMemo(() => disabledRoles, [refreshCondition]);

  useEffect(() => {
    if (role) {
      setDisableAccess(role.some((item: RolesEnum) => memoizedRoles.includes(item)));
    }
  }, [role, memoizedRoles]);

  return disableAccess;
};

/**
 * Хук для проверки, разрешен ли доступ для пользователя на основе его ролей.
 * Если роль пользователя совпадает с одной из разрешенных, доступ предоставляется.
 *
 * @param {AllowAccessParams} params - Параметры хука:
 * - `allowRoles`: Массив ролей, доступ которым разрешен.
 *
 * @returns {boolean} Возвращает `true`, если доступ разрешен, иначе `false`.
 *
 * @example
 * const isAccessAllowed = useAllowedAccessByRole({ allowRoles: [RolesEnum.Admin] });
 * console.log(isAccessAllowed); // true или false в зависимости от роли пользователя
 */
export const useAllowedAccessByRole = ({ allowRoles }: AllowAccessParams) => {
  const { role } = useRole();
  const [allow, setAllowAccess] = useState(false);
  const memoizedRoles = useMemo(() => allowRoles, []);

  useEffect(() => {
    if (!role) return;

    if (role.includes(RolesEnum.Administrators)) {
      setAllowAccess(true);
      return;
    }

    setAllowAccess(role.some((item: RolesEnum) => memoizedRoles.includes(item)));
  }, [role, memoizedRoles]);

  return useMemo(() => allow, [allow]);
};

/**
 * Хук для проверки, заблокирован ли весь доступ.
 * Возвращает логическое значение, указывающее, имеет ли пользователь доступ с ролью, которая блокирует весь доступ.
 *
 * @returns {boolean} Возвращает `true`, если доступ заблокирован, иначе `false`.
 *
 * @example
 * const isDisabledAll = useDisabledAll();
 * console.log(isDisabledAll); // true или false в зависимости от роли пользователя
 */
export const useDisabledAll = (): boolean => {
  const disabled = useAccess({
    access: [AccessEnum.DisabledAll],
    stopRedirect: true,
  });

  return useMemo(() => disabled, [disabled]);
};

/**
 * Хук для проверки, есть ли у пользователя хотя бы один комплект ролей из набора предоставленных комплектов.
 * Если у пользователя есть роль "Administrators", он автоматически имеет доступ.
 *
 * @param {RolesEnum[][]} roleSets - Массив комплектов ролей. Каждый комплект — массив обязательных ролей.
 *
 * @returns {boolean} Возвращает `true`, если у пользователя есть все роли из хотя бы одного комплекта или роль "Administrators".
 *
 * @example
 * const hasRoleSet = useHasAnyRoleSet([[RolesEnum.ComplaintCustomer, RolesEnum.ComplaintOdpu]]);
 * console.log(hasRoleSet); // true или false в зависимости от ролей пользователя
 */
export const useHasAnyRoleSet = (roleSets: RolesEnum[][]): boolean => {
  const { role } = useRole();
  const memoizedRoleSets = useMemo(() => roleSets, []);

  return useMemo(() => {
    if (!role) {
      return false;
    }

    // Если пользователь имеет роль "Administrators", возвращаем true
    if (role.includes(RolesEnum.Administrators)) {
      return true;
    }

    // Проверяем, совпадает ли хотя бы один комплект ролей
    return memoizedRoleSets.some((roleSet) =>
      roleSet.every((requiredRole) => role.includes(requiredRole))
    );
  }, [role, memoizedRoleSets]);
};
