import { BaseUI } from '@/types/ui';
import React, { useState } from 'react';
import {
  HouseEntranceFlatCountMatchedChildrenWrapper,
  HouseEntranceFlatCountMatchedColumn,
  HouseEntranceFlatCountMatchedProgressWrapper,
  HouseEntranceFlatCountMatchedSchemaType,
  HouseEntranceFlatCountMatchedWrapper,
} from './HouseEntranceFlatCountMatched.styled';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useShemaTypeView } from './utils';
import { usePNRStore } from '../store';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { ApiFindHouseTwoGisSheme } from '@/services/YodaRestService';
import { syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { useParams } from 'react-router-dom';
import { useLayoutData } from './utils';

const HouseEntranceFlatCountMatched = ({ children }: BaseUI) => {
  const shemaTypeView = useShemaTypeView();
  const { houseDtoId } = useParams();
  const fetchCatch = useActionLog((state) => state.fetchCatch);
  const { processLayoutData } = useLayoutData();

  const setHouseData = usePNRStore((state) => state.setHouseData);

  const isTwoGisAvailable = usePNRStore((state) => state.houseData?.isTwoGisAvailable);
  const [isLoadingTwoGisData, setIsLoadingTwoGisData] = useState(false);
  const handleTwoGis = async () => {
    if (!houseDtoId) return;
    setIsLoadingTwoGisData(true);
    ApiFindHouseTwoGisSheme(houseDtoId)
      .then(({ data }) => {
        if (data?.houseData) setHouseData(data.houseData);
        if (data?.layout) {
          processLayoutData(data.layout);
        } else {
          syncErrorCatch(
            'Данных с картографических сервисов недостаточно для построения схемы! Пожалуйста, заполните данные вручную'
          );
        }
      })
      .catch(fetchCatch)
      .finally(() => setIsLoadingTwoGisData(false));
  };

  return (
    <HouseEntranceFlatCountMatchedWrapper border={shemaTypeView.border}>
      <HouseEntranceFlatCountMatchedChildrenWrapper>
        {children}
      </HouseEntranceFlatCountMatchedChildrenWrapper>
      <HouseEntranceFlatCountMatchedColumn>
        <HouseEntranceFlatCountMatchedSchemaType>
          <Tooltip
            title={shemaTypeView.tooltip}
            placement='top'
          >
            <span>{shemaTypeView.icon}</span>
          </Tooltip>
        </HouseEntranceFlatCountMatchedSchemaType>

        {isTwoGisAvailable && (
          <div>
            {isLoadingTwoGisData ? (
              <HouseEntranceFlatCountMatchedProgressWrapper>
                <CircularProgress
                  color='info'
                  size={20}
                />
              </HouseEntranceFlatCountMatchedProgressWrapper>
            ) : (
              <>
                <Tooltip
                  title={'Запросить данные с картографических сервисов'}
                  placement='top'
                >
                  <IconButton
                    size='small'
                    color='info'
                    onClick={handleTwoGis}
                  >
                    <NotListedLocationIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        )}
      </HouseEntranceFlatCountMatchedColumn>
    </HouseEntranceFlatCountMatchedWrapper>
  );
};

export default HouseEntranceFlatCountMatched;
