import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createStore } from '@/utils/createStore';

export const REMOVE_KEY = `//${'div'}[text()="${'MUI X: Missing license key'}"]`; // XPath для поиска элемента с определенным текстом
export const xpath = REMOVE_KEY;

/**
 * Глобальное состояние для управления блокировкой (isBlocking).
 * Используется для хранения состояния блокировки внутри приложения.
 */
const useBlockKey = createStore(
  (set: any, get: any) => ({
    isBlocking: false,
    setIsBlocking: (block: boolean) => {
      set({ isBlocking: block });
    },
  }),
  'hackedDataGridPro'
);

/**
 * Функция для поиска элемента в DOM по XPath.
 *
 * @param {string} xp - XPath строка для поиска элемента.
 * @returns {Node | null} Найденный элемент или `null`, если элемент не найден.
 */
function getElementByXpath(xp: string) {
  return document.evaluate(xp, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)
    .singleNodeValue;
}

/**
 * Хук, который отслеживает изменения маршрута (pathname) и
 * удаляет элемент из DOM, если он соответствует указанному XPath.
 *
 * @example
 * useHackedDataGridPro();
 */
export const useHackedDataGridPro = () => {
  const { pathname } = useLocation(); // Получаем текущий путь из React Router
  const { setIsBlocking, isBlocking } = useBlockKey(); // Используем глобальное состояние

  useEffect(() => {
    const element = getElementByXpath(xpath);

    // Если элемент найден, удаляем его из DOM
    if (element) {
      element?.parentNode?.removeChild(element);
    }

    // При размонтировании хука инвертируем флаг блокировки
    return () => setIsBlocking(!isBlocking);
  }, [pathname]);

  return useEffect(() => {
    const element = getElementByXpath(xpath);

    // Повторное удаление элемента при ререндере компонента
    if (element) {
      element?.parentNode?.removeChild(element);
    }
  });
};
