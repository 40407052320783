import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { SupplyBillTypeResponse } from '@/dto/SupplyBillServiceDto';
import moment from 'moment';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { useAdminAccess } from '../SupplyPUComponent/utils';
import { useMemo } from 'react';
import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import { DataTypeConvert } from '@/components/inputs/DynamicFilter/utils';
import { converToNumber } from '@/components/statistics/StatisticWithDateForRP/utils';
type FILTERS_LABELS_TYPE = {
  [key: string]: string;
};

export const SAVE_FILTER_KEY = 'storeHouseDynamicFilterInputs';

export const FILTERS_LABELS_DYNAMIC: FILTERS_LABELS_TYPE = {
  contractorIdList: 'Кому',
  billTypeList: 'Тип накладной',
  billNumber: 'Номер накладной',
  puNumber: 'Номер ПУ',
  billDate: 'Дата создания',
  puTypeIdList: 'Тип ПУ',
  networkElementImei: 'IMEI СО',
  networkElementTypeIdList: 'Тип СО',
  supplyIdList: 'Склад',
  billCreatorList: 'Создана кем',
  billEditorList: 'Обновлена кем',
  stampNumber: 'Номер пломбы',
  projectIdList: 'Проект',
  releasedWithoutProjectId: 'Bыданные накладные без проекта',
};

export const PAGE_SIZE = 45;

export const convertBillTypesIntoFilter = (
  data?: SupplyBillTypeResponse[]
): SelectFilterOptType[] | undefined => {
  return data?.map((elem) => ({ value: `${elem.id}`, label: elem.name, ...elem }));
};

export const transformToLowerCase = (value?: string) => value?.toLowerCase() || '';

export const toSupplyBillFormateDate = (date?: Date | null | number) => {
  return (date && moment(date).format('yyyy-MM-DD')) || '';
};

export const defaultArgInput = {
  TAG: {
    argList: [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST, FilterArgEnum.INSTALLED],
    defaultArgNum: 0,
  },
  TEXT: {
    argList: [FilterArgEnum.EQUAL, FilterArgEnum.NOT_EQUAL, FilterArgEnum.CONTAIN],
    defaultArgNum: 2,
  },

  DATE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.RANGE,
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
    ],
    defaultArgNum: 2,
  },
};

const data: { id: string; dType: string }[] = [
  {
    id: 'contractorIdList',
    dType: 'TAG',
  },
  {
    id: 'billTypeList',
    dType: 'TAG',
  },
  {
    id: 'billNumber',
    dType: 'TEXT',
  },
  {
    id: 'puNumber',
    dType: 'TEXT',
  },
  {
    id: 'billDate',
    dType: 'DATE',
  },
  {
    id: 'puTypeIdList',
    dType: 'TAG',
  },
  {
    id: 'networkElementTypeIdList',
    dType: 'TAG',
  },
  {
    id: 'supplyIdList',
    dType: 'TAG',
  },
  {
    id: 'billCreatorList',
    dType: 'TAG',
  },
  {
    id: 'billEditorList',
    dType: 'TAG',
  },
  {
    id: 'stampNumber',
    dType: 'TEXT',
  },
  {
    id: 'projectIdList',
    dType: 'TAG',
  },
  {
    id: 'networkElementImei',
    dType: 'TEXT',
  },
];
export const useFilteredStoreHouseData = () => {
  const adminAccess = useAdminAccess();

  const filterAdminFields = (
    data: { id: string; dType: string }[],
    isAdmin: boolean | undefined
  ) => {
    return data.filter((elem) => {
      if (elem.id === 'billCreatorList' || elem.id === 'billEditorList') {
        return isAdmin;
      }
      return true;
    });
  };

  return useMemo(() => {
    return filterAdminFields(data, adminAccess)
      .map((elem) => ({
        ...elem,
        value: elem.id,
        label: FILTERS_LABELS_DYNAMIC[elem.id],
        isActive: false,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [adminAccess]);
};

interface ProcessedFilterValues {
  [key: string]: any;
}

export const processFilterParams = (
  filterParamsToSend: IQureFilter[],
  releasedWithoutProjectId?: boolean
): ProcessedFilterValues => {
  return filterParamsToSend.reduce(
    (result, current) => {
      const { type, values, name, arg } = current;
      let processedValues;
      switch (type) {
        case 'DATE':
          processedValues = values.map((date) =>
            moment(date as unknown as Date).format('YYYY-MM-DD')
          );
          if (arg === FilterArgEnum.NOT_EQUAL || arg === FilterArgEnum.EQUAL) {
            processedValues = processedValues[0];
          }
          break;
        case 'TAG':
          processedValues = values.map((elem) => elem.value);
          break;
        case 'TAG_2':
          processedValues = values.map((elem) => elem.value);
          break;
        case 'TEXT':
          if (
            arg === FilterArgEnum.NOT_EQUAL ||
            arg === FilterArgEnum.EQUAL ||
            arg === FilterArgEnum.CONTAIN
          ) {
            processedValues = values[0];
          } else {
            processedValues = (values[0] as unknown as string).split(',');
          }
          break;
        default:
          processedValues = undefined;
      }

      return {
        ...result,
        [name]: processedValues,
        [`${name}Arg`]: arg,
      };
    },
    {
      ...(releasedWithoutProjectId && {
        releasedWithoutProjectId,
      }),
    } as ProcessedFilterValues
  );
};
interface ProcessedQueryField {
  name: string;
  arg: number;
  values: any[];
  type: string;
}
export interface QueryField {
  values: any[];
  arg: number;
  type: string;
}

export const processQueryFields = (
  queryFields: Record<string, QueryField>,
  filterSetting: DataTypeConvert[]
): ProcessedQueryField[] => {
  const objectKeys = Object.keys(queryFields);
  const validKeys = filterSetting.map((el) => el.value);

  objectKeys.forEach((key) => {
    if (!validKeys.includes(key)) {
      delete queryFields[key];
    }
  });
  return Object.keys(queryFields)
    .filter(
      (item) => queryFields[item]?.values?.length && queryFields[item]?.values.some((el: any) => el)
    )
    .map((el) => ({
      name: el,
      arg: queryFields[el]?.arg,
      values: queryFields[el]?.values.map((val: string) =>
        converToNumber(queryFields[el]?.type) ? +val : val
      ),
      type: queryFields[el]?.type,
    }));
};
