import { NamedStatisticInvestShowInfo } from '../types/types';
import { ChartOptions } from 'chart.js';

export const doughnutSectorOptions: ChartOptions<'doughnut'> = {
  layout: {
    padding: {
      left: 150,
      right: 150,
    },
  },
  spacing: 5,
};

export const filterShowInfo = (
  showInfo?: NamedStatisticInvestShowInfo,
  withNartis?: boolean
): NamedStatisticInvestShowInfo | undefined => {
  if (!showInfo) {
    return undefined;
  }
  if (withNartis) {
    // Фильтруем ключи с пустыми массивами
    return Object.fromEntries(
      Object.entries(showInfo).filter(([_, value]) => value && value.length > 0)
    );
  }

  // Убираем ключ "nartis" и фильтруем пустые массивы
  const { nartis, ...rest } = showInfo;
  return Object.fromEntries(Object.entries(rest).filter(([_, value]) => value && value.length > 0));
};

export const FILTERS_LABELS = {
  contract: 'Контракт',
  energyCompany: 'Сбыт',
};

export enum DYNAMIC_SWITCH_LABELS {
  NARTIS = 'НАРТИС',
}

export const timePeriod = [
  {
    title: 'ДЕНЬ',
    name: 'day',
  },
  {
    title: 'НЕДЕЛЯ',
    name: 'week',
  },
  {
    title: 'МЕСЯЦ',
    name: 'month',
  },
];

export const colorsSegment = {
  '1Ф': ['#2C3E50', '#556679', '#1A2A38'],
  '3Ф': ['#1ABC9C', '#5BDBC2', '#16A085'],
  ШЛЮЗ: ['#FF5733', '#FF9C75', '#C0392B'],
  РОУТЕР: ['#F39C12', '#FDC05D', '#D35400'],
  'ОДПУ ЮЛ': ['#BDC3C7', '#D5DBDB', '#7F8C8D'],
  ОДПУ: ['#9B59B6', '#B78BC9', '#8E44AD'],
};

export type ColorKeys = keyof typeof colorsSegment;

export const formatCount = (number: number | undefined) => {
  if (!number) {
    return 0;
  }
  if (number >= 1000000) {
    const billions = (number / 1000000).toFixed(3);
    return billions + ' МЛРД';
  } else if (number >= 1000) {
    const millions = (number / 1000).toFixed(3);
    return millions + ' МЛН';
  } else {
    return number + ' ТЫС';
  }
};
