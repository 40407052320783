import { IconButton, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

export const Wrapper = styled('div')({
  width: '33%',
  minWidth: 320,
  border: '1px solid #ccc',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: '10px',
  flexWrap: 'wrap',
  fontSize: '20px',
  fontWeight: 400,
  marginTop: 10,
  gap: 8,
});

export const SupplyBillBrowseItemDeleteIcon = styled(IconButton)({
  flexShrink: 0,
});

export const SupplyBillBrowseItemDate = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
