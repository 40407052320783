import { styled } from '@mui/material';

interface HouseEntranceFlatCountMatchedWrapperProps {
  border?: string;
}

export const HouseEntranceFlatCountMatchedWrapper = styled(
  'div'
)<HouseEntranceFlatCountMatchedWrapperProps>(({ border }) => ({
  display: 'flex',
  borderRadius: 15,
  ...(border && {
    border: border,
  }),
}));

export const HouseEntranceFlatCountMatchedChildrenWrapper = styled('div')(() => ({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
  padding: 8,
}));

export const HouseEntranceFlatCountMatchedColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-start',
  paddingRight: 8,
}));

export const HouseEntranceFlatCountMatchedSchemaType = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const HouseEntranceFlatCountMatchedProgressWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 34,
  height: 34,
}));
