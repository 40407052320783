import create from 'zustand';

/**
 * Хук для управления состоянием экрана во время выполнения операций, которые требуют задержки или блокировки.
 * Используется для отслеживания процесса выполнения и предотвращения повторных действий во время его выполнения.
 *
 * @returns {object} Объект с состоянием и функцией для обновления этого состояния:
 * - `isInProgress`: Логическое значение, которое указывает, что процесс находится в состоянии выполнения.
 * - `setIsInProgress`: Функция, которая обновляет состояние `isInProgress`.
 *
 * @example
 * const { isInProgress, setIsInProgress } = useScreenHoldHook();
 * // Устанавливаем состояние выполнения процесса:
 * setIsInProgress(true);
 * // Проверяем, идет ли процесс:
 * if (isInProgress) {
 *   // Показываем индикатор загрузки или блокируем элементы
 * }
 */
export const useScreenHoldHook = create((set: any, get: any) => ({
  isInProgress: false, // Начальное состояние, процесс не выполняется

  /**
   * Обновляет состояние isInProgress.
   *
   * @param {boolean} isInProgress - Логическое значение, указывающее, что процесс находится в прогрессе.
   */
  setIsInProgress: (isInProgress: boolean) => {
    set({ isInProgress: isInProgress }); // Устанавливаем новое значение состояния
  },
}));
