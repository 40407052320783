import { IImageGroup } from '@/components/Gallery/store';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import React from 'react';
import { isImage, isImageGroup } from './utils';
import NewGalleryPreview, { NewGalleryPreviewProps } from './NewGalleryPreview';
import {
  NewGallerySubTitle,
  NewPreviewMultiGroupWrapper,
  NewPreviewWrapper,
} from './NewGallery.styled';
import { Divider } from '@mui/material';

export interface NewGalleryPreviewSwitchProps
  extends Omit<
    NewGalleryPreviewProps,
    'image' | 'index' | 'groupIndex' | 'imageIndex' | 'isActive'
  > {
  imagesOrGroups: IImage[] | IImageGroup[];
  activeImageIndex: number;
  activeGroupIndex: number;
}

const NewGalleryPreviewSwitch = ({
  imagesOrGroups,
  activeImageIndex,
  activeGroupIndex,
  onClick,
  onContentTypeLoaded,
}: NewGalleryPreviewSwitchProps) => {
  const singleImageGroup = isImage(imagesOrGroups[0]);
  const multiImageGroup = isImageGroup(imagesOrGroups[0]);

  if (singleImageGroup) {
    return (
      <>
        {imagesOrGroups?.map((image, index) => (
          <NewGalleryPreview
            key={index}
            image={image as IImage}
            onClick={onClick}
            imageIndex={index}
            groupIndex={0}
            onContentTypeLoaded={onContentTypeLoaded}
            isActive={activeImageIndex === index}
            scrollIntoViewOnClick
          />
        ))}
      </>
    );
  }

  if (multiImageGroup) {
    return (
      <>
        <NewPreviewMultiGroupWrapper>
          {imagesOrGroups?.map((group, groupIndex) => (
            <>
              {isImageGroup(group) && (
                <>
                  <NewGallerySubTitle>{group?.title}</NewGallerySubTitle>
                  <NewPreviewWrapper>
                    {group?.imageList?.map((image, index) => (
                      <NewGalleryPreview
                        key={index}
                        image={image}
                        onClick={onClick}
                        imageIndex={index}
                        groupIndex={groupIndex}
                        onContentTypeLoaded={onContentTypeLoaded}
                        isActive={index === activeImageIndex && groupIndex === activeGroupIndex}
                        scrollIntoViewOnClick
                      />
                    ))}
                  </NewPreviewWrapper>
                  <Divider sx={{ mb: 2, mt: 3 }} />
                </>
              )}
            </>
          ))}
        </NewPreviewMultiGroupWrapper>
      </>
    );
  }

  return null;
};

export default NewGalleryPreviewSwitch;
