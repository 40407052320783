import { Divider } from '@mui/material';
import { Tooltip } from '../../types/types';
import { TooltipWrapper } from '../InvestStatistic.styled';
import { DoughnutTooltipHeader, LegendItemTitle } from './BudgetStatisticDoughnut.styled';
import { DoughnutShowInfo } from './DoughnutShowInfo';
import { useInvestStatisticStore } from '../../store';
import { formatCount } from '../utils';

interface DoughnutTooltipProps {
  tooltip: Tooltip;
  withNartis?: boolean;
}

export const DoughnutTooltip = ({ tooltip, withNartis }: DoughnutTooltipProps) => {
  const countAsMoney = useInvestStatisticStore((state) => state.countAsMoney);

  return (
    <TooltipWrapper tooltip={tooltip}>
      <DoughnutTooltipHeader>
        <LegendItemTitle>{tooltip?.tooltipData?.title}</LegendItemTitle>
        <LegendItemTitle>
          {countAsMoney
            ? formatCount(tooltip?.tooltipData?.totalCount)
            : tooltip?.tooltipData?.totalCount}
        </LegendItemTitle>
      </DoughnutTooltipHeader>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <Divider
          variant='middle'
          style={{ backgroundColor: '#000000', height: '1px' }}
        />
      </div>
      {tooltip?.tooltipData && (
        <DoughnutShowInfo
          item={tooltip?.tooltipData}
          withNartis={withNartis}
        />
      )}
    </TooltipWrapper>
  );
};
