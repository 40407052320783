import { useEffect } from 'react';

/**
 * DEPRECATED. Удалить после выката последних изменений.
 *
 * Хук, который выполняет переданную функцию при нажатии клавиши Enter.
 *
 * @param {Function} cb - Коллбэк, который будет вызван при нажатии Enter.
 *
 * @example
 * usePressEnterButton(() => {
 *   console.log('Enter pressed');
 * });
 */

const usePressEnterButton = (cb: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        cb();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Очистка обработчика при размонтировании компонента
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [cb]);
};

export default usePressEnterButton;
