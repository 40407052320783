import { IconButton, styled } from '@mui/material';
import { swingAnimationKeyframes } from '../../pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import { Loading } from '@/components/ui/Loading';
import CommonModal from '@/components/common/CommonModal/CommonModal';

export const HouseLinkInner = styled('div')({
  display: 'flex',
  gap: 14,
  alignItems: 'flex-start',
});

export const HouseLinkInnerColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

export const HouseLinkInnerRow = styled('div')({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
});

export const HouseLinkPencilButton = styled(IconButton)({
  svg: { transformOrigin: 'center bottom' },
  '&:hover': { svg: { animation: `${swingAnimationKeyframes} 0.7s 1 both` } },
});

export const HouseLinkLoading = styled(Loading)({
  height: '24px !important',
  minWidth: 250,
});

export const HouseLinkStrong = styled('strong')({
  flexShrink: 0,
});

export const HouseLinkModalButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 40,
  gap: 20,
});

export const HouseLinkCommonModal = styled(CommonModal)({
  minWidth: '40%',
});
