import { yodaRefreshRoles } from '@/services/YodaRestService';
import axios, { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { RolesEnum } from '../app/auth/roles';
import { useLogOut } from './useLogOut';

// Интерфейс для пропсов компонента RoleProvider
interface IProps {
  children: React.ReactNode; // Это дочерние компоненты, которые будут обернуты компонентом RoleProvider
}

// Создаем контекст для хранения информации о ролях пользователя глобально в приложении
const RoleContext = React.createContext<{
  role: RolesEnum[] | null; // Роль может быть массивом RolesEnum или null, если роль не задана
}>({
  role: null, // По умолчанию значение роли null
});

// Константа, используемая для проверки сообщений об ошибке с неверным токеном
const INVALID_TOKEN_MESSAGE = 'invalid_token';

// Ключ для хранения/получения ролей пользователя из localStorage
export const yodaRoles = 'yodaRoles';

/**
 * Функция для получения ролей из localStorage, если они там есть.
 * @returns {RolesEnum[] | null} Массив ролей из localStorage или null, если роли не найдены.
 */
const getRolesLocal = (): null | RolesEnum[] => {
  const roles = localStorage.getItem(yodaRoles); // Получаем роли, сохраненные в localStorage по ключу 'yodaRoles'
  if (roles) {
    return JSON.parse(roles); // Преобразуем строку обратно в массив
  } else {
    return null; // Если ролей нет, возвращаем null
  }
};

/**
 * Функция для сохранения ролей пользователя в localStorage.
 * @param {string[]} userRoles - Массив ролей, который нужно сохранить в localStorage.
 */
export const saveRolesLocal = (userRoles: string[]) => {
  localStorage.setItem(yodaRoles, JSON.stringify(userRoles)); // Сохраняем роли в localStorage как строку
};

/**
 * Компонент RoleProvider - Обеспечивает доступ к контексту с информацией о ролях по всему приложению.
 * @param {IProps} props - Пропсы для компонента RoleProvider, в частности дочерние компоненты, которые будут использовать контекст.
 * @returns {JSX.Element} Возвращает дочерние компоненты, обернутые в провайдер RoleContext.
 */
export const RoleProvider: React.FC<IProps> = ({ children }) => {
  const logOut = useLogOut(); // Используем кастомный хук для выхода пользователя
  const [role, setRole] = useState<RolesEnum[] | null>(null); // Состояние для хранения ролей пользователя

  useEffect(() => {
    // Пытаемся получить роли из localStorage при монтировании компонента
    const userRoles = getRolesLocal();

    if (userRoles) {
      // Если роли найдены в localStorage, устанавливаем их в состояние
      setRole(userRoles);
    } else {
      // Если ролей в localStorage нет, пытаемся получить их с сервера
      yodaRefreshRoles()
        .then((data) => setRole(data)) // В случае успеха устанавливаем роли из ответа API
        .catch((err: AxiosError<{ error: string }>) => {
          // Если произошла ошибка, проверяем, что это ошибка с неверным токеном
          if (axios.isAxiosError(err) && err.response?.data.error === INVALID_TOKEN_MESSAGE) {
            // Если токен неверный, выходим из системы
            logOut();
          }
        });
    }
  }, []); // Эффект сработает один раз при монтировании компонента

  return (
    <RoleContext.Provider
      value={{
        role, // Передаем значение роли всем дочерним компонентам
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

/**
 * Кастомный хук для доступа к контексту ролей.
 * @returns {object} Объект, содержащий роли текущего пользователя.
 */
export const useRole = () => useContext(RoleContext); // Получаем значение контекста ролей
