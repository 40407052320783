import {
  GridColDef,
  GridRenderCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { color } from '@/styles/mixins';
import moment from 'moment';

function truncateText(params: GridRenderCellParams) {
  const text = params.value;
  const columnWidth = params.colDef.width;
  if (!text || !columnWidth) {
    return '';
  }
  const canvas = document.createElement('canvas');
  const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
  const fontSize = '15px "Roboto","Helvetica","Arial",sans-serif';

  if (!context) {
    return '';
  }
  context.font = fontSize;
  function getCharacterWidth(char: string) {
    if (context) {
      const metrics = context.measureText(char);
      return metrics.width;
    } else {
      return 0;
    }
  }
  const maxChars = Math.floor((columnWidth - 20) / getCharacterWidth('a'));
  let truncatedText = text;
  if (text.length > maxChars) {
    const startIndex = text.length - maxChars;
    truncatedText = '...' + text.slice(startIndex);
  }

  return truncatedText;
}

interface CellWithTooltipProps {
  params: GridRenderCellParams;
  children?: React.ReactElement | string;
  truncateValue?: boolean;
}
export const CellWithTooltip = ({ params, children, truncateValue }: CellWithTooltipProps) => {
  return (
    <Tooltip title={params.value ?? ''}>
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {children ? children : truncateValue ? truncateText(params) : params.value}
      </span>
    </Tooltip>
  );
};

//1795382

export const getColumns = (): GridColDef[] => {
  const arr: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hide: true,
    },
    {
      field: 'taskNumber',
      headerName: '№ Заявки',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Статус',
    },
    {
      field: 'type',
      headerName: 'Тип',
      width: 200,
    },
    {
      field: 'accountNum',
      headerName: '№ ЛС',
      width: 200,
    },
    {
      field: 'contractName',
      headerName: 'Контракт',
      width: 180,
    },
    {
      field: 'customerID',
      headerName: 'СТДО/номер договора',
      width: 180,
    },
    {
      field: 'registrationNum',
      headerName: 'САП/ТУ',
      width: 180,
    },
    {
      field: 'createdOn',
      headerName: 'Дата создания заявки',
      hide: true,
    },
    {
      field: 'taskCustomerId',
      headerName: 'ID в системе Заказчика',
      hide: true,
    },
    {
      field: 'exPuNum',
      headerName: 'Номер существующего ПУ',
      hide: true,
    },
    {
      field: 'assignedWork',
      headerName: 'Назначенные работы',
      hide: true,
    },
    {
      field: 'res',
      headerName: 'РЭС',
      hide: true,
    },
    {
      field: 'tp',
      headerName: 'Наименование ТП',
      hide: true,
    },

    {
      field: 'contactName',
      headerName: 'ФИО',
      width: 180,
    },
    {
      field: 'contractorName',
      headerName: 'Ответственный',
      width: 180,
    },
    {
      field: 'executorName',
      headerName: 'Бригада',
      width: 180,
    },
    {
      field: 'managementCompany',
      headerName: 'Управляющая компания',
      width: 200,
    },
    {
      field: 'address',
      headerName: 'Адрес',
      width: 480,
    },
    {
      field: 'appartment',
      headerName: 'Кв',
      width: 100,
    },
    {
      field: 'region',
      headerName: 'Регион',
      hide: true,
    },
    {
      field: 'settlement',
      headerName: 'Населенный пункт',
      hide: true,
    },
    {
      field: 'street',
      headerName: 'Улица',
      width: 180,
      hide: true,
    },
    {
      field: 'house',
      headerName: 'Дом',
      width: 80,
      hide: true,
    },
    {
      field: 'completeDate',
      headerName: 'Дата закрытия заявки',
      width: 180,
      hide: true,
    },
    {
      field: 'tag',
      headerName: 'Тег',
      width: 150,
    },
  ];

  return arr;
};

export const getColumnsRenderCell = (): GridColDef[] => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hide: true,
    },
    {
      field: 'taskNumber',
      renderCell: (params) => (
        <CellWithTooltip params={params}>
          <span
            style={{
              cursor: 'pointer',
              color: color('muiBlue'),
            }}
            onClick={() => window.open(`/task/edit/${params.id}`)}
          >
            {params.value}
          </span>
        </CellWithTooltip>
      ),
      sortComparator: (v1, v2) => v1 - v2,
    },
    {
      field: 'status',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'taskCustomerId',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'res',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'tp',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'type',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'accountNum',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'assignedWork',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'exPuNum',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'contractName',
      renderCell: (params) => (
        <CellWithTooltip
          params={params}
          truncateValue
        />
      ),
    },

    {
      field: 'customerID',
      renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
    },
    {
      field: 'registrationNum',
      renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
    },

    {
      field: 'contactName',
      renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
    },

    {
      field: 'contractorName',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'executorName',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },

    {
      field: 'managementCompany',
      renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
    },

    {
      field: 'address',
      renderCell: (params) => (
        <CellWithTooltip
          params={params}
          truncateValue
        />
      ),
    },
    {
      field: 'appartment',
      renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
    },

    {
      field: 'region',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'settlement',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'street',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },
    {
      field: 'house',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },

    {
      field: 'tag',
      renderCell: (params) => <CellWithTooltip params={params} />,
    },

    {
      field: 'completeDate',
      renderCell: (params) => {
        const paramsCopy = params;
        paramsCopy.value = params.value && moment(params.value).format('DD-MM-yyyy');
        return <CellWithTooltip params={paramsCopy} />;
      },
    },
    {
      field: 'createdOn',
      renderCell: (params) => {
        const paramsCopy = params;
        paramsCopy.value = params.value && moment(params.value).format('DD-MM-yyyy');
        return <CellWithTooltip params={paramsCopy} />;
      },
    },
  ];
};
