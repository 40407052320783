import {
  HouseEntranceShemeFullEntrance,
  HouseEntranceShemeModal,
} from '@/components/sections/HouseEntranceSheme/HouseEntranceSheme.styled';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { usePNRStore } from '../../store';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  PnrDndEditHouseModalForm,
  PnrDndEditModalCenterWrapper,
  PnrDndEditModalInner,
  PnrDndEditModalShemeWrapper,
  PnrDndEditModalTrash,
} from './PnrDndEditModal.styled';
import PnrDndEditModalFloor from './PnrDndEditModalFloor';
import { useHouseDnDEdit } from './utils';
import PnrDndEditModalEntranceHeader from './PnrDndEditModalEntranceHeader';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { addSuccessActionLog, fetchCatch } from '@/hooks/ActionLogHook';
import { ApiUpdateHouseLayout } from '@/services/YodaRestService';
import { LoadingButton } from '@mui/lab';
import {
  IncorrectHouseShemaType,
  useIsIncorrectHouseShema,
  schemaTypeConfig,
} from '../../HouseEntranceFlatCountMatched/utils';
import { useParams } from 'react-router-dom';

const PnrDndEditModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isIncorrectHouseShema = useIsIncorrectHouseShema();
  const layoutData = usePNRStore((state) => state.layoutData);
  const { houseDtoId } = useParams();
  const setLayoutDataFromBackend = usePNRStore((state) => state.setLayoutDataFromBackend);

  const {
    handleDragEnd,
    updateFloorEntry,
    handleHouseEditSubmit,
    resetDnDModalEditions,
    handleHouseReset,
  } = useHouseDnDEdit();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    resetDnDModalEditions();
  };

  const saveAndCloseModal = () => {
    if (!houseDtoId || !layoutData) return;
    setIsLoading(true);
    ApiUpdateHouseLayout(houseDtoId, layoutData)
      .then(({ data }) => {
        addSuccessActionLog('Успешное сохранение данных');
        setLayoutDataFromBackend(data);
      })
      .catch((error) => fetchCatch(error, 'Ошибка сохранения данных'))
      .finally(() => {
        setIsOpen(false);
        setIsLoading(false);
      });
  };

  const tooltipText = isIncorrectHouseShema
    ? schemaTypeConfig[IncorrectHouseShemaType.NO_HOUSE_ENTRANCE_DATA].tooltip
    : 'Редактирование дома';

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Tooltip
        title={tooltipText}
        placement='top'
      >
        <div>
          <IconButton
            disabled={isIncorrectHouseShema}
            onClick={handleOpen}
          >
            <RoomPreferencesIcon />
          </IconButton>
        </div>
      </Tooltip>
      <HouseEntranceShemeModal
        open={isOpen}
        onClose={handleClose}
      >
        <PnrDndEditModalShemeWrapper>
          <Typography
            variant='h6'
            textAlign='center'
          >
            Редактирование дома
          </Typography>
          <PnrDndEditHouseModalForm
            onSubmit={handleHouseEditSubmit}
            onReset={handleHouseReset}
          />

          <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
            <PnrDndEditModalInner>
              {layoutData?.entrances?.map((entrance) => (
                <HouseEntranceShemeFullEntrance key={entrance.number}>
                  <PnrDndEditModalEntranceHeader
                    entranceNumber={entrance.number}
                    floorCount={entrance?.floors.length}
                  />

                  {!!entrance?.floors?.length &&
                    entrance?.floors?.map((floor) => (
                      <PnrDndEditModalFloor
                        key={floor.number}
                        entranceNumber={entrance.number}
                        floor={floor}
                        updateFloorEntry={updateFloorEntry}
                      />
                    ))}
                </HouseEntranceShemeFullEntrance>
              ))}
            </PnrDndEditModalInner>
            <Droppable
              droppableId={`trash`}
              direction='horizontal'
            >
              {(provided, snapshot) => (
                <PnrDndEditModalTrash
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  <DeleteForeverRoundedIcon color='inherit' />
                </PnrDndEditModalTrash>
              )}
            </Droppable>
          </div>

          <PnrDndEditModalCenterWrapper>
            <LoadingButton
              variant='contained'
              onClick={saveAndCloseModal}
              loading={isLoading}
            >
              Сохранить
            </LoadingButton>
            <Button onClick={handleClose}>Отмена</Button>
          </PnrDndEditModalCenterWrapper>
        </PnrDndEditModalShemeWrapper>
      </HouseEntranceShemeModal>
    </DragDropContext>
  );
};

export default PnrDndEditModal;
