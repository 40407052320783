import { createStore } from '@/utils/createStore';
import { IQureInput } from './types';

interface DynamicInputsStore {
  setQueryFields: (saveFilterKey: string, queryFields: any) => void;
  queryFields: Record<string, IQureInput>;
}

export const useDynamicInputsStore = createStore<DynamicInputsStore>(
  (set: any) => ({
    queryFields: {},
    setQueryFields: (saveFilterKey, queryFields) => {
      set((state: DynamicInputsStore) => ({
        queryFields: {
          ...state.queryFields,
          [saveFilterKey]: queryFields,
        },
      }));
    },
  }),
  'DynamicFilter'
);
