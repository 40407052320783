import React, { useEffect, useState } from 'react';
import {
  CallCenterRequest,
  CallCenterWorkType,
  TaskCC,
  // TaskCCAbonent,
  TaskCCTko,
} from '@/dto/taskmap/Dto';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import '../../styles/callcenterstyle.css';
import {
  Button,
  FormHelperText,
  Grid,
  List,
  Paper,
  Stack,
  // Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ApiGetTaskInfoById,
  ApiGetTaskTkoByTaskInfoId,
  ApiGetWorkTypes,
  ApiSaveCallCenterRequest,
} from '@/services/YodaRestService';
import moment from 'moment';
import { Controller, SubmitHandler, useFieldArray, useForm, useFormState } from 'react-hook-form';
import {
  contactPhoneRegex,
  emptyFieldValidation,
  fioRegex,
} from './callCenterValidation/callCenterValidation';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { LoadingButton } from '@mui/lab';
import { color } from '@/styles/mixins';
// import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';
import { addSub, changeAbonentActiveStatus } from '@/services/SubscribersService';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { formatPhoneNumber } from '@/components/maps/callingMap/utils/CallingMapUtils';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { formatPhoneNumberMask } from '@/utils/heplers';

interface CCTaskItemProps {
  taskItemId: string;
  clearRow: () => any;
}
export default function CCTaskItem(props: CCTaskItemProps) {
  const { taskItemId } = props;
  const { setIsInProgress } = useScreenHoldHook();
  const [taskInfo, setTaskInfo] = useState<TaskCC>({} as TaskCC);
  const navigate = useNavigate();
  const [listTko, setListTko] = React.useState<TaskCCTko[]>([]);
  const [workTypes, setWorkTypes] = useState<CallCenterWorkType[]>([]);
  const [addNumber, setAddNumber] = useState(false);
  const [fio, setFio] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [customErrors, setError] = useState({ phoneNumberError: '', fioError: '' });
  const checkFields = fio && contactPhone;
  const handleUserName = (value: string) => {
    if (!fioRegex.test(value) && value.length) {
      setError({
        ...customErrors,
        fioError: 'Поле может содержать только русские буквы и цифры',
      });
      setFio(value);
    } else {
      setFio(value);
      setError({
        ...customErrors,
        fioError: '',
      });
    }
  };
  const handlePhoneNumber = (value: string) => {
    const error = validateNewAbonentNumber(value);
    const regexError = contactPhoneRegex.test(value);
    if (error || !regexError) {
      setError({
        ...customErrors,
        phoneNumberError: error || ((!regexError && 'неверный номер') as string),
      });
      setContactPhone(value);
    } else {
      setContactPhone(value);
      setError({
        ...customErrors,
        phoneNumberError: error,
      });
    }
  };

  useMetaTitle(`${taskInfo.address ?? ''} колл-центр`);

  const clearNameAndPhone = () => {
    setContactPhone('');
    setFio('');
    setError({ phoneNumberError: '', fioError: '' });
    setAddNumber(false);
  };

  const handleSubmitNewAbonent = () => {
    const newAbonent = {
      taskId: taskInfo.taskId,
      fio,
      contactPhone,
    };

    setIsInProgress(true);
    addSub(newAbonent)
      .then(async () => {
        addActionLog(ActionLogType.SUCCESS, 'Номер успешно добавлен');
        const value = await ApiGetTaskInfoById(taskItemId);
        setValue('abonentsList', value.data.abonentsList);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка изменения абонента');
      })
      .finally(() => {
        setIsInProgress(false);
        setContactPhone('');
        setFio('');
        setError({ phoneNumberError: '', fioError: '' });
      });
  };

  const { handleSubmit, control, setValue, register, resetField, getValues, watch } =
    useForm<CallCenterRequest>({
      defaultValues: {
        abonentsList: taskInfo.abonentsList,
      },
    });
  const { errors } = useFormState({
    control,
  });

  const { fields: abonentsDefault } = useFieldArray({
    control,
    name: 'abonentsList',
    keyName: 'uniqId',
  });

  const validateNewAbonentNumber = (number: string | undefined) => {
    const formattedString = number?.replace(/[^\d]/g, '');
    if (abonentsDefault?.find((abonnet) => abonnet.contactPhone === formattedString)) {
      return 'Номер уже существует';
    }
    return '';
  };

  const { addActionLog, fetchCatch } = useActionLog();

  const onSubmit: SubmitHandler<CallCenterRequest> = (data) => saveCallCenterRequest(data);

  useEffect(() => {
    if (taskInfo && !taskInfo.id) {
      ApiGetTaskInfoById(taskItemId)
        .then(function (value) {
          setTaskInfo(value.data);
          setValue('abonentsList', value.data.abonentsList);
        })
        .catch((err) => fetchCatch(err, 'Ошибка получения информации по заявке'));
      ApiGetTaskTkoByTaskInfoId(taskItemId)
        .then((value) => setListTko(value.data))
        .catch((err) => fetchCatch(err, 'Ошибка получения ТКО'));
    }
  }, [taskItemId]);

  useEffect(() => {
    ApiGetWorkTypes(['101'])
      .then((value) => setWorkTypes(value.data))
      .catch((err) => fetchCatch(err, 'Ошибка получения типов работ'));
  }, []);

  useEffect(() => {
    if (taskInfo?.abonentsList?.length === 0) {
      setAddNumber(true);
    }
  }, [taskInfo]);

  const saveCallCenterRequest = (data: CallCenterRequest) => {
    data.taskInfoId = taskInfo.id;
    data.phoneNumber = data.contactNumber ?? '';
    data.fullName = abonentsDefault.find((el) => el.contactPhone === data.contactNumber)?.fio ?? '';
    const { abonentsList, contactNumber, ...restData } = data;
    setIsInProgress(true);
    ApiSaveCallCenterRequest(restData)
      .then(function () {
        addActionLog(ActionLogType.SUCCESS, 'Заявка сохранена успешно');
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка изменения абонента');
      })
      .finally(() => {
        setIsInProgress(false);
        resetField('newAbonentsList', {
          defaultValue: [],
        });
        setValue('contactNumber', '');
        setValue('comment', '');
        setValue('workType', '');
      });
  };
  const disableSubmit = !!watch('contactNumber');

  // const handleActiveAbonent = (abonent: TaskCCAbonent, index: number) => {
  //   setIsInProgress(true);
  //   changeAbonentActiveStatus(abonent)
  //     .then(() => {
  //       setValue(`abonentsList.${index}`, abonent);
  //       addActionLog(ActionLogType.SUCCESS, 'Статус изменен');
  //     })
  //     .catch((err) => {
  //       fetchCatch(err,'Ошибка изменения абонента' );
  //     })
  //     .finally(() => setIsInProgress(false));
  // };

  const toTaskSearch = () => navigate('/callcenter');

  return (
    <Stack flexDirection={'row'}>
      <Grid
        container
        xs={6}
        className='item-container'
        spacing={1}
        p={3}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={1}
          >
            <Grid item>
              <Typography
                variant='subtitle1'
                color={color('typographyColorMap2')}
              >
                Адрес:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle1'
                color={color('typographyColorMap1')}
              >
                {taskInfo?.address} {taskInfo.apartment && `, кв.${taskInfo.apartment}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
          >
            <Grid item>
              <Typography
                variant='subtitle1'
                color={color('typographyColorMap2')}
              >
                Лицевой счет:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle1'
                color={color('typographyColorMap1')}
              >
                {taskInfo?.accountNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              container
              spacing={1}
            >
              <Grid item>
                <Typography
                  variant='subtitle1'
                  color={color('typographyColorMap1')}
                >
                  Контактная информация
                </Typography>
              </Grid>

              {abonentsDefault?.map((el, index) => {
                return (
                  <Grid
                    item
                    key={el.id}
                    xs={12}
                    container
                    spacing={1}
                    alignItems={'center'}
                  >
                    <Grid
                      item
                      xs={5}
                    >
                      {el.fio || 'Имя отсутствует'}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      {formatPhoneNumber(el.contactPhone, true)}
                    </Grid>
                    {/* <Grid item>
                      <Controller
                        control={control}
                        name={`abonentsList.${index}`}
                        render={({ field }) => {
                          return (
                            <Switch
                              id='active'
                              color='success'
                              defaultChecked={field?.value?.isActive || el?.isActive}
                              value={field.value?.isActive}
                              onChange={(e) =>
                                handleActiveAbonent({ ...el, isActive: e.target.checked }, index)
                              }
                            />
                          );
                        }}
                      />
                    </Grid> */}
                  </Grid>
                );
              })}
              <Stack
                width={'100%'}
                maxHeight={200}
                overflow={'auto'}
                p={1}
              >
                {addNumber && (
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      marginY={1}
                    >
                      <Grid
                        item
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          id='fullname-field'
                          label={'Ф.И.О.'}
                          variant='outlined'
                          value={fio}
                          error={!!customErrors?.fioError}
                          helperText={customErrors?.fioError}
                          onChange={(e) => handleUserName(e.target.value)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                      >
                        <TextField
                          fullWidth
                          id='phone-number-field'
                          label={'Номер телефона'}
                          value={formatPhoneNumberMask(contactPhone || '')}
                          variant='outlined'
                          error={!!customErrors?.phoneNumberError}
                          helperText={customErrors?.phoneNumberError}
                          onChange={(e) => handlePhoneNumber(formatPhoneNumberMask(e.target.value))}
                        />
                      </Grid>
                      <Grid
                        item
                        display={'flex'}
                        alignItems={'center'}
                        gap={2}
                      >
                        <CancelIcon
                          style={{ color: color('trueBlack'), cursor: 'pointer' }}
                          onClick={() => clearNameAndPhone()}
                        />
                        <Button
                          onClick={() => handleSubmitNewAbonent()}
                          variant='text'
                          startIcon={<AddTaskIcon />}
                        >
                          сохранить
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Stack>
              <Grid
                item
                xs={12}
                mb={2}
              >
                {!addNumber && (
                  <Button onClick={() => setAddNumber(true)}>Добавить контактный телефон</Button>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid item>
                <InputLabel
                  required
                  style={{ color: color('typographyColorMap2') }}
                  id='type-of-work-select-label'
                >
                  тип обращения
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Controller
                  control={control}
                  name='workType'
                  rules={emptyFieldValidation}
                  render={({ field }) => (
                    <Select
                      {...register('workType')}
                      labelId='type-of-work-select-label'
                      fullWidth
                      id='type-of-work-type'
                      value={field?.value || ''}
                      error={!!errors?.workType?.message}
                      variant='standard'
                      onChange={(e) => field.onChange(e)}
                    >
                      {workTypes &&
                        workTypes.map((elem) => (
                          <MenuItem
                            key={elem.id}
                            value={elem.workTypeName}
                          >
                            {elem.workTypeName}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                <FormHelperText sx={{ color: color('trueRed'), marginLeft: '14px' }}>
                  {errors?.workType?.message}
                </FormHelperText>
              </Grid>
              <Grid item>
                <InputLabel
                  required
                  style={{ color: color('typographyColorMap2') }}
                  id='type-of-work-select-label'
                >
                  номер для связи
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={3}
              >
                <Controller
                  control={control}
                  name='contactNumber'
                  rules={emptyFieldValidation}
                  render={({ field }) => (
                    <Select
                      {...register('contactNumber')}
                      labelId='type-of-work-select-label'
                      fullWidth
                      id='contactNumber'
                      value={field?.value || ''}
                      error={!!errors?.contactNumber?.message}
                      variant='standard'
                      onChange={(e) => field.onChange(e)}
                    >
                      {abonentsDefault &&
                        abonentsDefault.map((elem) => (
                          <MenuItem
                            key={elem.id}
                            value={elem.contactPhone}
                          >
                            {elem.contactPhone}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                <FormHelperText sx={{ color: color('trueRed'), marginLeft: '14px' }}>
                  {errors?.contactNumber?.message}
                </FormHelperText>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <InputLabel
                  style={{ color: color('typographyColorMap1') }}
                  id='type-of-work-select-label'
                >
                  Комментарий
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Controller
                  control={control}
                  name='comment'
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id='comment-field'
                      label='введите коментраий'
                      multiline
                      rows={3}
                      value={field.value ? field.value : ''}
                      variant='outlined'
                      error={!!errors?.comment?.message}
                      helperText={errors?.comment?.message}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={3}
            mt={3}
          >
            <Grid
              item
              xs={6}
            >
              <LoadingButton
                fullWidth
                variant='contained'
                type='submit'
                disabled={!disableSubmit}
              >
                Сохранить
              </LoadingButton>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Button
                fullWidth
                variant='outlined'
                onClick={toTaskSearch}
              >
                отмена
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <List style={{ maxHeight: '500px', overflow: 'auto' }}>
        {listTko &&
          listTko.map((tko) => {
            return (
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '10px',
                  marginLeft: '15px',
                  marginBottom: '10px',
                  height: 'max-content',
                }}
                key={tko.id}
              >
                <TextField
                  multiline
                  sx={{ width: '350px' }}
                  maxRows={2}
                  value={tko.reason + ''}
                  id='standard-basic'
                  variant='standard'
                />
                <Typography
                  fontSize={16}
                  sx={{ mt: 1, ml: 4 }}
                  variant='caption'
                  gutterBottom
                >
                  {moment(new Date(parseInt(tko.date + ''))).format('DD.MM.yyyy')}
                </Typography>
              </Paper>
            );
          })}
      </List>
    </Stack>
  );
}
