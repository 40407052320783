import DndSelect from '@/components/dndSelect';

import { Typography, Grid } from '@mui/material';
import { Paper } from '@mui/material';
import React, { useCallback, useState } from 'react';
import DynamicPivotTable from './DynamicPivotTable';
import { syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { IDndList, IDndParams, IQureFilter, ISettings, SettingsEnum } from './types';

import DynamicInput from '@/components/inputs/DynamicFilter';

import { DataTypeConvert, convertDataFn } from '@/components/inputs/DynamicFilter/utils';
import { GetTableRP } from '@/services/RPDashboardService';
import { useCatalog } from '@/hooks/CatalogHook';
import { useHackedDataGridPro } from '@/hooks/useHackedDataGridPro';
import { DATA_LIMIT, converToNumber, SAVE_FILTER_KEY } from './utils';
import { StatisticRPDto } from '../RequestStatistic/types';

interface StatisticWithDateForRPProps {
  header: string;
  data: StatisticRPDto[];
}

const reversEnum = {
  '0': 'IN_LIST',
  '1': 'NOT_IN_LIST',
  '2': 'EQUAL',
  '3': 'NOT_EQUAL',
  '4': 'CONTAIN',
  '5': 'MORE',
  '6': 'LESS',
  '7': 'INSTALLED',
  '8': 'RANGE',
};

const StatisticWithDateForRP = ({ header, data }: StatisticWithDateForRPProps) => {
  useHackedDataGridPro();
  const { fetchCatch } = useActionLog();

  const convertData = convertDataFn(data);

  const [first, setFirst] = useState(convertData);
  const [second, setSecond] = useState(convertData);

  const [recivedRows, setRecivedRows] = useState<IDndList[]>([]);

  const [recivedColumns, setRecivedColumns] = useState<IDndList[]>([]);
  const [tableData, setTableData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { getCatalogListForRp } = useCatalog((state) => ({
    getCatalogListForRp: state.getCatalogListForRp,
  }));

  const [filterParamsToSend, setFilterParamsToSend] = useState<IQureFilter[]>([]);
  const [settings, setSettings] = useState<ISettings>({
    rowFields: [],
    columnFields: [],
    valueFields: [],
  });

  const callBackFn = (queryFields: any, filterSetting: DataTypeConvert[]) => {
    const objectKeys = Object.keys(queryFields);
    const kyes = filterSetting.map((el: any) => el.value);
    objectKeys.forEach((key) => {
      if (!kyes.includes(key)) {
        delete queryFields[key];
      }
    });
    const result = Object.keys(queryFields)
      .filter(
        (item) =>
          queryFields[item]?.values?.length && queryFields[item]?.values.some((el: any) => el)
      )
      .map((el) => ({
        name: el,
        arg: queryFields[el]?.arg,
        values: queryFields[el]?.values.map((val: string) =>
          converToNumber(queryFields[el]?.type) ? +val : val
        ),
        type: queryFields[el]?.type,
      }));
    setFilterParamsToSend(result);
  };

  const setFilterParam = useCallback((param: IDndParams) => {
    if (param.listName === SettingsEnum.Row) {
      const filtered = convertData.filter((item) => {
        return !param.dndList.some((selectedItem) => selectedItem.id === item.id);
      });
      setSecond(filtered);
      setRecivedRows(param.dndList);
    }
    if (param.listName === SettingsEnum.Column) {
      const filtered = convertData.filter((item) => {
        return !param.dndList.some((selectedItem) => selectedItem.id === item.id);
      });

      setFirst(filtered);
      setRecivedColumns(param.dndList);
    }
  }, []);

  const makeRequest = () => {
    const filter = {
      queryFields: filterParamsToSend.map((el) => ({
        ...el,
        arg: reversEnum[el.arg as keyof typeof reversEnum],
        values: el.values?.map((el) => el?.label || el),
      })),
      groupFields: [
        ...recivedRows.map((item) => item.value),
        ...recivedColumns.map((item) => item.value),
      ],
      reducerFields: [
        {
          name: 'summary',
          arg: 'COUNT',
        },
      ],
    };
    setSettings({
      rowFields: [...recivedRows], // может быть расширен до N полей
      columnFields: [...recivedColumns], // может быть расширен до N полей
      valueFields: ['summary_count'],
    });
    setIsLoadingData(true);
    GetTableRP(filter)
      .then(({ data }) => {
        if (data.length > DATA_LIMIT) {
          syncErrorCatch(`Объем данных превышает ${data.length}`);
          setTableData([]);
        } else {
          setTableData(data);
        }
      })
      .catch((error) => {
        fetchCatch(error);
        setTableData([]);
      })
      .finally(() => setIsLoadingData(false));
  };
  const disabledSearch = !!recivedRows.length && !!recivedColumns.length;

  return (
    <Paper elevation={6}>
      <Typography
        variant='h4'
        align='center'
      >
        {header}
      </Typography>

      <Grid
        xs={12}
        ml={5}
      >
        <DynamicInput
          data={convertData}
          callBackFn={callBackFn}
          searchFn={makeRequest}
          saveFilterKey={SAVE_FILTER_KEY}
          getCatalogList={getCatalogListForRp}
          disabledSearch={disabledSearch}
          shuldRefresh
        />
      </Grid>
      <Grid
        container
        spacing={2}
        padding={1}
        paddingBottom={4}
      >
        <Grid
          item
          xs={10}
        >
          <DynamicPivotTable
            data={tableData}
            settings={settings}
            isLoadingData={isLoadingData}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <Grid
            container
            direction='column'
            spacing={2}
          >
            <Grid item>
              <DndSelect
                title={SettingsEnum.Row}
                setTableData={setTableData}
                list={first}
                listName={SettingsEnum.Row}
                listener={setFilterParam}
              />
            </Grid>
            <Grid item>
              <DndSelect
                setTableData={setTableData}
                title={SettingsEnum.Column}
                list={second}
                listName={SettingsEnum.Column}
                listener={setFilterParam}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatisticWithDateForRP;
