const EXCLUDED_TYPES_LIST = [
  'WARRANTY_WORK_TECH_CONNECTION',
  'WARRANTY_WORK_SMR_STOLBI',
  'FZ_522',
  'FZ_522_ISUR',
  'SIGNE_ADPU',
  'WARRANTY_WORK_FZ_522',
  'SMR_STOLBI',
  'WARRANTY_WORK_NETWORK_ELEMET_STOLBI',
  'COMPLAINT_FZ_522',
  'CLAIM_ENERGY_SERVICE',
  'NETWORK_ELEMENTS_STOLBY',
  'MASS_INSTALLATION_FZ_522',
  'PPO_TP',
];

export const enableHouseLink = (taskType?: string): boolean => {
  if (!taskType) return false;
  if (EXCLUDED_TYPES_LIST.includes(taskType)) {
    return false;
  } else {
    return true;
  }
};
