import { color } from '@/styles/mixins';
import { styled, Typography } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { FLAT_STYLES } from './utils';
import ZoomUI from '@/components/ui/ZoomUI/ZoomUI';
import { PnrPlanningsCountMainText } from '@/components/blocks/PnrPlanningsCount/PnrPlanningsCount.styled';
import CommonModal from '@/components/common/CommonModal/CommonModal';

const HOUSE_ENTRANCE_BORDER = {
  borderRadius: 5,
  border: `0.5px solid ${color('trueGray')}`,
};

export const HouseEntranceShemeFloorWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

export const HouseEntranceShemeButton = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '12px !important',
  padding: '12px 16px',
  cursor: 'pointer',
  color: color('trueBlack', 0.6),
});

export const HouseEntranceShemeModal = styled(CommonModal)({
  width: '95%',
});

export const HouseEntranceShemeEntranceCube = styled('div')({
  width: 70,
  height: 24,
  fontSize: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: color('trueWhite'),
  background: color('trueWhite'),
  userSelect: 'none',
  '-webkit-text-stroke': '1px #4E4E4E',
  ...HOUSE_ENTRANCE_BORDER,
});

export const HouseEntranceShemeFullEntrance = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  paddingRight: 35,
});

export const HouseEntranceShemeHeaderBoxShadow = styled(BoxShadow)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 12,
  padding: 10,
  paddingLeft: 30,
  paddingRight: 30,
  color: color('trueBlack'),
  fontSize: 14,
  position: 'sticky',
  left: 0,
  zIndex: theme.zIndex.drawer + 1,
}));

export const HouseEntranceShemeHeaderWrapper = styled('div')({
  display: 'flex',
  gap: 10,
});

export const HouseEntranceShemeNotFoundWrapper = styled(HouseEntranceShemeHeaderBoxShadow)({
  flexDirection: 'column',
  gap: 6,
  marginBottom: 12,
  paddingRight: 10,
  width: 'fit-content',
  whiteSpace: 'nowrap',
});

export const HouseEntranceShemeNotFoundItemsWrapper = styled('div')({
  display: 'flex',
  gap: 8,
  justifyContent: 'space-between',
  paddingLeft: 8,
});

export const HouseEntranceShemeWrapper = styled('div')({
  fontFamily: '"Roboto", sans-serif',
  fontWeight: 500,
  fontStyle: 'normal',
  width: '100%',
  overflow: 'auto',
  scrollbarGutter: 'stable',
  padding: 8,
  minWidth: '70vw',
  minHeight: '80svh',
  display: 'flex',
  flexDirection: 'column',
});

export const HouseEntranceShemeHeaderRow = styled('div')({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: 20,
});

interface HouseEntranceShemeFlatProps {
  empty?: boolean;
  sideWidth?: boolean;
  flatStyles?: FLAT_STYLES;
  dndStyles?: boolean;
}

export const HouseEntranceShemeFlat = styled('div')<HouseEntranceShemeFlatProps>(
  ({ empty, sideWidth, flatStyles, dndStyles }) => ({
    transition: '0.4s',
    transitionProperty:
      'outline,backgroundColor,color,background,opacity,border,backgroundGradient',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    fontSize: 10,
    letterSpacing: 1.25,
    height: 24,
    width: sideWidth ? 24 : 35,
    color: color(flatStyles?.color ?? 'textGray'),
    backgroundColor: color(flatStyles?.background ?? 'trueWhite', flatStyles?.opacity || 1),
    borderRadius: 5,
    outline: '3px solid transparent',
    ...(dndStyles && { margin: '0 2px' }),
    ...(!empty && { ...HOUSE_ENTRANCE_BORDER }),
    ...(empty && { ...{ background: 'transparent' } }),
    ...(flatStyles?.border && { ...{ border: flatStyles?.border } }),
    ...(flatStyles?.backgroundGradient && { ...{ background: flatStyles?.backgroundGradient } }),
  })
);

export const EmptyHouseEntranceCube = () => (
  <HouseEntranceShemeFlat
    empty
    sideWidth
  />
);

export const HouseEntranceShemeEmptyFloor = styled('div')({
  background: color('trueGray', 0.4),
  width: '100%',
  height: 24,
  ...HOUSE_ENTRANCE_BORDER,
});

export const HouseEntranceShemeLegendRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: 16,
});

export const HouseEntranceShemeLabelWrapper = styled('div')({
  width: '250px',
  display: 'flex',
  justifyContent: 'flex-end',
  flexShrink: 0,
});

interface HouseEntranceShemeLegendLayerProps {
  selected?: boolean;
}
export const HouseEntranceShemeLegendLayer = styled('div')<HouseEntranceShemeLegendLayerProps>(
  ({ selected }) => ({
    display: 'inline-block',
    transition: '0.4s',
    cursor: 'pointer',
    ...(selected && { ...{ color: color('muiBlue') } }),
  })
);

interface HouseEntranceShemeLegendWrapperProps {
  tooltipMenu?: boolean;
}

export const HouseEntranceShemeLegendWrapper = styled('div')<HouseEntranceShemeLegendWrapperProps>(
  ({ tooltipMenu }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    ...(tooltipMenu && { ...{ flexDirection: 'column', padding: 15 } }),
  })
);

export const HouseEntranceShemeCenter = styled('div')({
  display: 'inline-flex',
  alignItems: 'flex-end',
  flexGrow: 1,
});

export const HouseEntranceShemeAdress = styled(PnrPlanningsCountMainText)({
  textTransform: 'uppercase',
});

export const HouseEntranceShemeHeaderInfoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  flexWrap: 'wrap',
});

export const HouseEntranceShemeHeaderLegendWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
  padding: '20px 0',
  minHeight: 110,
});

interface HouseEntranceShemeHeaderInfoItemProps {
  alignStart?: boolean;
  withoutGap?: boolean;
}

export const HouseEntranceShemeHeaderInfoItem = styled(
  'div'
)<HouseEntranceShemeHeaderInfoItemProps>(({ alignStart, withoutGap }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: withoutGap ? 0 : 10,
  alignItems: alignStart ? 'flex-start' : 'flex-end',
}));

export const HouseEntranceShemeUpDownStairs = styled('div')({
  display: 'flex',
  gap: 4,
});

export const HouseEntranceShemeUpDownStairsInner = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
  gap: 4,
});

export const HouseEntranceShemeFlatsWrapper = styled('div')({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  gap: 4,
});

export const HouseEntranceShemeZoom = styled(ZoomUI)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  gap: 4,
  overflowX: 'hidden',

  '.react-draggable': {
    width: 'fit-content',
  },

  '.ZoomUIButtonContainer': {
    margin: 'auto',
  },
});
