import React, { FC, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useDeliveryPoints } from '../placemark/delivery-point/store';
import { isSubscriberUsed, replaceSubscriber } from '@/services/TaskService';
import { useActionLog, ActionLogType } from '@/hooks/ActionLogHook';
import { SubscriberResponse, SubscriberUsage } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { ChronologyRowDiv } from '@/components/features/taskEditor/item/chronology/Chronology.styled';
import ChronologyItem from '@/components/features/taskEditor/item/chronology/ChronologyItem';
import DraggablePopup from '@/components/popups/DraggablePopup';
import { color } from '@/styles/mixins';

interface IConsumerItem {
  fio: string;
  contractNum: string;
  puNumber: string;
  address: string;
  subscriber: SubscriberResponse;
  setIsLoading: (loadong: boolean) => void;
  type: string;
}

const ConsumerItem: FC<IConsumerItem> = ({
  fio,
  contractNum,
  puNumber,
  address,
  subscriber,
  setIsLoading,
  type,
}) => {
  const { tmpDeliveryPoint, setTmpDeliveryPoint } = useDeliveryPoints();
  const deliveryPointId = tmpDeliveryPoint?.id;

  const [openConfirm, setOpenConferm] = useState(false);
  const [subcriberInfo, setSubcriberInfo] = useState<SubscriberUsage[]>([]);
  const { fetchCatch, addActionLog } = useActionLog();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const handleSubscriberReplace = (newSub: SubscriberResponse) => {
    if (deliveryPointId) {
      isSubscriberUsed(newSub.id).then(({ data }) => {
        if (data?.length) {
          setOpenConferm(true);
          setSubcriberInfo(data);
        } else {
          handleConfirmSubscriberReplace(newSub);
        }
      });
    }
  };
  const handleConfirmSubscriberReplace = (newSub: SubscriberResponse) => {
    if (deliveryPointId) {
      setIsLoading(true);
      replaceSubscriber(newSub, deliveryPointId)
        .then(({ data }) => {
          setTmpDeliveryPoint(deliveryPointId, data);
          addActionLog(ActionLogType.SUCCESS, 'Сохранено');
        })
        .catch((err) => fetchCatch(err, 'Не сохранено'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <DraggablePopup
        isOpen={openConfirm}
        onClose={() => setOpenConferm(false)}
      >
        <Stack
          spacing={2}
          p={2}
          textAlign={'center'}
        >
          <Typography
            variant='h6'
            color={color('red1')}
          >
            Внимание! Данный абонент уже используется в другой ТП
          </Typography>
          {!!subcriberInfo.length &&
            subcriberInfo.map((subInfo, index) => {
              return (
                <Stack key={index}>
                  <Typography
                    variant='h6'
                    color={color('red1')}
                  >
                    {index + 1}. РЭС: {subInfo.resName ?? '-'} № ТП:{' '}
                    {subInfo.transStationName ?? '-'} № опоры: {subInfo.pillarNum ?? '-'}
                  </Typography>
                </Stack>
              );
            })}
          <Typography
            variant='h6'
            color={color('red1')}
          >
            Вы уверены, что хотите выбрать этого потребителя?
          </Typography>
          <Stack
            direction='row'
            spacing={2}
            justifyContent='center'
          >
            <Button
              size='large'
              variant='text'
              onClick={() => handleConfirmSubscriberReplace(subscriber)}
            >
              Да
            </Button>
            <Button
              size='large'
              variant='text'
              onClick={() => setOpenConferm(false)}
            >
              Отмена
            </Button>
          </Stack>
        </Stack>
      </DraggablePopup>
      <ChronologyRowDiv borderColor={'borderGray'}>
        <ChronologyItem
          label={'Имя'}
          value={fio}
          width={'20%'}
        />
        <ChronologyItem
          label={'ЛС'}
          value={contractNum}
          width={'9%'}
        />
        <ChronologyItem
          label={'Контрагент'}
          value={getCatalogMapWithErr('taskConsumer').get(type !== 'undefined' ? type : '')}
          width={'9%'}
        />
        <ChronologyItem
          label={'№ Сущ. ПУ'}
          value={puNumber}
          width={'12%'}
        />
        <ChronologyItem
          label={'Адрес'}
          value={address}
          width={'40%'}
        />
        <Button onClick={() => handleSubscriberReplace(subscriber)}>выбрать</Button>
      </ChronologyRowDiv>
    </>
  );
};

export default ConsumerItem;
