import { Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { PpoTransformerResponseList } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { useActionLog } from '@/hooks/ActionLogHook';

interface MeterItemProps {
  meter: PpoTransformerResponseList[];
  prevString: string;
}

export const PpoTTItem = (props: MeterItemProps) => {
  const { register, setValue } = useFormContext();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { meter } = props;
  const TRANSFORMER = `${props.prevString}.transformerRequestList.0.transformerInfo`;

  if (meter) {
    setValue(`${TRANSFORMER}.id`, meter[0]?.transformerInfo?.id);
    setValue(`${TRANSFORMER}.currentRate`, meter[0]?.transformerInfo?.currentRate);
  }

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant='h6'
          mr={2}
          mt={2}
        >
          ТТ:
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Тип ТТ'
          defaultValue={
            meter[0]?.transformerInfo?.ttType ? `${meter[0].transformerInfo.ttType}` : undefined
          }
          fieldName={`${TRANSFORMER}.ttType`}
          values={getCatalogMapWithErr('amperageTransformerType')}
        />
      </Grid>
      <Grid
        item
        xs={6}
      />
      <Grid
        item
        xs={6}
        style={{ display: 'flex' }}
      >
        {/*<Typography*/}
        {/*  variant='h6'*/}
        {/*  mr={2}*/}
        {/*  mt={2}*/}
        {/*>*/}
        {/*  ТТ:*/}
        {/*</Typography>*/}
        <TextField
          id='tf-number'
          label='Год выпуска'
          variant='standard'
          {...register(`${TRANSFORMER}.manufactureYear`, {
            value: meter[0]?.transformerInfo?.manufactureYear ?? undefined,
          })}
          sx={{ width: '100%' }}
        />
      </Grid>

      {/*<Grid*/}
      {/*  item*/}
      {/*  xs={4}*/}
      {/*>*/}
      {/*  <TextField*/}
      {/*    id='tf-number'*/}
      {/*    label='Номинал'*/}
      {/*    variant='standard'*/}
      {/*    {...register(`${TRANSFORMER}.currentRate`, {*/}
      {/*      value: meter ? meter[0]?.transformerInfo?.currentRate : undefined,*/}
      {/*    })}*/}
      {/*    sx={{ width: '100%' }}*/}
      {/*  />*/}
      {/*</Grid>*/}

      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Коэфф ТТ'
          defaultValue={
            meter[0]?.transformerInfo?.kttEnum ? `${meter[0].transformerInfo.kttEnum}` : undefined
          }
          fieldName={`${TRANSFORMER}.kttEnum`}
          values={getCatalogMapWithErr('kttEnum')}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Диапазон номиналов трансформатора тока'
          defaultValue={
            meter[0]?.transformerInfo?.ppoCurrentRateRange
              ? `${meter[0].transformerInfo.ppoCurrentRateRange}`
              : undefined
          }
          fieldName={`${TRANSFORMER}.ppoCurrentRateRange`}
          values={getCatalogMapWithErr('ppoCurrentRateRange')}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Место включения ТТ'
          defaultValue={
            meter[0]?.transformerInfo?.existsLocationId
              ? `${meter[0].transformerInfo.existsLocationId}`
              : undefined
          }
          fieldName={`${TRANSFORMER}.existsLocationId`}
          values={getCatalogMapWithErr('ppoLocationTt')}
        />
      </Grid>

      {/*<Grid*/}
      {/*  item*/}
      {/*  xs={6}*/}
      {/*>*/}
      {/*  <AutocompleteValue*/}
      {/*    title='Место монтажа нового ТТ'*/}
      {/*    defaultValue={*/}
      {/*      meter[0]?.transformerInfo?.newLocationId*/}
      {/*        ? `${meter[0].transformerInfo.newLocationId}`*/}
      {/*        : undefined*/}
      {/*    }*/}
      {/*    fieldName={`${TRANSFORMER}.newLocationId`}*/}
      {/*    values={getCatalogMapWithErr('ppoLocationTt')}*/}
      {/*  />*/}
      {/*</Grid>*/}
      {meter?.map((e, i) => {
        setValue(`${props.prevString}.transformerRequestList.${i}.id`, e.id);
        setValue(`${props.prevString}.transformerRequestList.${i}.phaseName`, e?.phaseName);
        return (
          <React.Fragment key={e.id}>
            <Grid
              item
              xs={4}
              style={{ display: 'flex' }}
            >
              <Typography
                variant='h6'
                mr={2}
              >
                {e?.phaseName}
              </Typography>
              <TextField
                id='tf-number'
                label='Номер ТТ'
                variant='standard'
                {...register(`${props.prevString}.transformerRequestList.${i}.number`, {
                  value: e?.number,
                })}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <TextField
                id='tf-number'
                label='Пломба ТТ'
                variant='standard'
                {...register(`${props.prevString}.transformerRequestList.${i}.stampNumber`, {
                  value: e?.stampNumber,
                })}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <TextField
                id='tf-number'
                label='Принадлежность пломбы'
                variant='standard'
                {...register(`${props.prevString}.transformerRequestList.${i}.affiliationStamp`, {
                  value: e?.affiliationStamp,
                })}
                sx={{ width: '100%' }}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </>
  );
};
