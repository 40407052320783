import { CommonModalProps } from '@/components/common/CommonModal/CommonModal';
import { LoadingButton } from '@mui/lab';
import { Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { HouseLinkCommonModal, HouseLinkModalButtonsWrapper } from './HouseLink.styled';
import { checkUUID, TASK_UUID_REG_EXP } from '@/components/widgets/Navbar/NavbarBolt/utils';
import { syncErrorCatch } from '@/hooks/ActionLogHook';

export interface EditHouseLinkModalProps extends CommonModalProps {
  onSave: (houseId: string) => void;
  isLoading: boolean;
}

const EditHouseLinkModal = ({ onClose, open, onSave, isLoading }: EditHouseLinkModalProps) => {
  const [houseId, setHouseId] = useState('');

  const handleSave = () => {
    const validUUID = checkUUID(houseId);

    if (!validUUID) {
      syncErrorCatch(`Некорректный формат id дома`, {
        bufferText: houseId,
        testRegExp: TASK_UUID_REG_EXP,
      });
      return;
    }
    onSave(houseId);
  };

  useEffect(() => {
    setHouseId('');
  }, [open]);
  return (
    <HouseLinkCommonModal
      open={open}
      onClose={onClose}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center' }}>
        <Typography variant='h6'>Внесите новый id дома</Typography>

        <TextField
          value={houseId}
          variant='standard'
          onChange={(e) => setHouseId(e.target.value)}
          placeholder='Id'
          sx={{ width: 350 }}
          autoFocus
        />
        <HouseLinkModalButtonsWrapper>
          <LoadingButton
            variant='contained'
            onClick={handleSave}
            disabled={houseId.length < 35}
            loading={isLoading}
          >
            Сохранить
          </LoadingButton>
          <Button onClick={onClose}>Отмена</Button>
        </HouseLinkModalButtonsWrapper>
      </div>
    </HouseLinkCommonModal>
  );
};

export default EditHouseLinkModal;
