import { color } from '@/styles/mixins';
import {
  Checkbox,
  CheckboxProps,
  ListItem,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: color('lightBlue'),
    color: 'white',
  },
  '&': {
    color: color('typographyColorMap1'),
    borderRadius: 10,
  },
}));

type StyledTypographyPropsSpan = {
  customColor?: string;
  theme?: any;
};

export const StyledSpan = styled('span')<StyledTypographyPropsSpan>(({ theme, customColor }) => ({
  '&': {
    color: customColor || color('gray'),
  },
}));

type StyledTypographyProps = {
  colorLengthSelectedEx?: boolean;
} & TypographyProps;

export const StyledTypography = styled((props: StyledTypographyProps) => <Typography {...props} />)(
  ({ colorLengthSelectedEx = true }: StyledTypographyProps) => ({
    color: colorLengthSelectedEx ? color('trueBlack') : color('trueRed'),
    width: '100%',
  })
);

type StyledCheckboxProps = {
  checked?: boolean;
} & CheckboxProps;

export const StyledCheckbox = styled(({ ...props }: StyledCheckboxProps) => (
  <Checkbox {...props} />
))((checkboxProps) => ({
  color: color('blue2'),
  '&.Mui-checked': {
    color: checkboxProps.checked ? color('blue2') : undefined,
  },
}));

interface StyledDivProps {
  isHighlighted?: boolean;
  isBold?: boolean;
}

export const StyledDiv = styled('div')<StyledDivProps>((props) => ({}));
