import { frontendVersion } from '@/components/widgets/FrontendVersion/utils';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { createStore } from '../utils/createStore';
import { v4 as uuid } from 'uuid';

const environment = import.meta.env.MODE || 'development';

interface SessionStore {
  sessionId: string;
}

const sessionId = uuid();
const DENY_URLS_REGEX = /token|jwt/i;

export const useSession = createStore<SessionStore>(
  () => ({
    sessionId: sessionId,
  }),
  'Session store'
);

Sentry.init({
  dsn: 'https://1cf415e0f822d47825632a41d6f92fca@sentry.sec2.ru/2',
  // SHA последнего коммита/локальная сборка, используется для фильтрации ошибок
  release: frontendVersion(),

  // Проброс id сессии, используется для фильтрации ошибок
  beforeSend(event) {
    const NotFoundError =
      event?.exception?.values?.[0]?.value === 'Request failed with status code 404';

    if (NotFoundError) {
      return null;
    }

    event.tags = {
      ...event.tags,
      sessionId: sessionId,
    };
    return event;
  },
  // Отделение dev/staging/prod контуров, используется для фильтрации ошибок
  environment: environment,
  integrations: [
    //   Подключение реплеев
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      // Записываем хедеры и боди реквестов/респонзов
      networkDetailAllowUrls: [
        import.meta.env.VITE_YODA_BACKEND_BASE_URL,
        import.meta.env.VITE_SUPPLY_BILL_SERVICE_BASE_URL,
        import.meta.env.VITE_COMPLAINT_SERVICE_BASE_URL,
        import.meta.env.VITE_RP_DASHBOARD_BASE_URL,
        import.meta.env.VITE_BUDGET_SERVICE_BASE_URL,
      ],
      // Фильтруем запросы авторизации, чтоб не писать их в сентри
      networkDetailDenyUrls: [DENY_URLS_REGEX],
    }),
    // Проброс урла в ошибку
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Проброс сообщений в консоли, временно отключено
    // Sentry.captureConsoleIntegration(),
    // Проброс таймингов сессии
    Sentry.sessionTimingIntegration(),
    // Проброс http запросов
    Sentry.httpClientIntegration(),
  ],
  // Проброс хедеров и куки
  sendDefaultPii: true,
  // Отправлять 100% ошибок
  tracesSampleRate: 1.0,
  // Записывать 10% сессий
  replaysSessionSampleRate: 0.1,
  // Записывать 100% ошибочных сессий
  replaysOnErrorSampleRate: 1.0,
});
