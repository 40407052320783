import * as d3 from 'd3';
import { DynamicStatisticItem, DynamicStatisticItemData } from '../../types/types';
import { DYNAMIC_SWITCH_LABELS, formatCount } from '../utils';
import { RefObject } from 'react';
import { DynamicStatisticResponse } from '../../api/dto/response';

export const margin = {
  top: 30,
  right: -((window.innerWidth * 3) / 100),
  bottom: 70,
  left: (window.innerWidth * 9) / 100,
};

export const getAllData = (data: DynamicStatisticItem[], withNartis: boolean) => {
  return data
    .filter(
      (item) =>
        withNartis || item.title.toLowerCase() !== DYNAMIC_SWITCH_LABELS.NARTIS.toLowerCase()
    )
    .flatMap((dataset) => dataset.data);
};

export const createScaleX = (data: DynamicStatisticResponse[], width: number) => {
  const allDates = data.flatMap((line) => line.data.map((d) => new Date(d.date)));

  const uniqueDates = Array.from(new Set(allDates.map((d) => d3.timeFormat('%d-%m-%Y')(d)))).sort(
    (a, b) => {
      const dateA = d3.timeParse('%d-%m-%Y')(a);
      const dateB = d3.timeParse('%d-%m-%Y')(b);
      if (!dateA || !dateB) {
        return 0;
      }
      return dateA.getTime() - dateB.getTime();
    }
  );

  return d3
    .scaleBand()
    .domain(uniqueDates)
    .range([margin.left, width - margin.right]);
};

export const createScaleY = (data: DynamicStatisticItemData[], height: number) => {
  const maxCount = d3.max(data, (d) => d.count) ?? 0;
  return d3
    .scaleLinear()
    .domain([0, maxCount])
    .range([height - margin.bottom, margin.top]);
};

export const createSvg = (svgRef: RefObject<SVGSVGElement>, width: number, height: number) => {
  return d3
    .select(svgRef.current)
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height]);
};

export const createVerticalGridLines = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  data: DynamicStatisticItemData[],
  height: number,
  x: d3.ScaleBand<string>
) => {
  svg
    .append('g')
    .attr('class', 'grid-line')
    .selectAll('.grid-line')
    .data(data)
    .enter()
    .append('line')
    .attr('class', 'grid-line')
    .attr('x1', (d) => x(d3.timeFormat('%d-%m-%Y')(new Date(d.date))) ?? 0)
    .attr('x2', (d) => x(d3.timeFormat('%d-%m-%Y')(new Date(d.date))) ?? 0)
    .attr('y1', 0)
    .attr('y2', height - margin.bottom)
    .attr('stroke', '#7D7D7D7D')
    .attr('stroke-width', 1);
};

export const createHorizontalGridLines = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  height: number,
  width: number,
  y: d3.ScaleLinear<number, number, never>,
  paddingLeft: number
) => {
  svg
    .append('g')
    .attr('class', 'grid')
    .attr('transform', `translate(${paddingLeft - 70 + window.innerWidth / 100}, 0)`)
    .selectAll('.grid-line')
    .data(y.ticks(height / 100))
    .enter()
    .append('line')
    .attr('class', 'grid-line')
    .attr('x1', 0)
    .attr('x2', width - paddingLeft - margin.right)
    .attr('y1', (d) => y(d))
    .attr('y2', (d) => y(d))
    .attr('stroke', '#DFDFDF');
};

export const createYAxisLabels = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  height: number,
  y: d3.ScaleLinear<number, number, never>,
  paddingLeft: number
) => {
  svg
    .style('position', 'absolute')
    .style('pointer-events', 'none')
    .style('z-index', 1)
    .append('g')
    .attr('class', 'axis-label')
    .attr('transform', `translate(${paddingLeft - 70 + window.innerWidth / 100},0)`)
    .call(d3.axisLeft(y).ticks(6))
    .call((g) => g.select('.domain').remove())
    .call((g) => g.selectAll('.tick line').attr('stroke', '#DFDFDF'))
    .insert('rect', ':first-child')
    .attr('x', -paddingLeft)
    .attr('y', 0)
    .attr('width', paddingLeft)
    .attr('height', height)
    .attr('fill', 'white');
};

export const colorsLine = ['#023E72', '#096FB7', '#189DDE', '#56CCF2'];

export const drawLine = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  data: DynamicStatisticItemData[],
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  strokeDash: string,
  idx: number
) => {
  const line = d3
    .line<DynamicStatisticItemData>()
    .x((d) => x(d3.timeFormat('%d-%m-%Y')(new Date(d.date))) ?? 0)
    .y((d) => y(d.count));

  return svg
    .append('path')
    .datum(data)
    .attr('class', 'line-path')
    .attr('fill', 'none')
    .attr('stroke', colorsLine[idx])
    .attr('stroke-width', 6)
    .attr('d', line)
    .attr('stroke-dasharray', strokeDash);
};

export const drawVerticalIntersectionLine = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
) => {
  return svg
    .append('line')
    .attr('class', 'vertical-line')
    .attr('stroke', 'gray')
    .style('opacity', 0);
};

export const drawHorizontalIntersectionLine = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
) => {
  return svg
    .append('line')
    .attr('class', 'horizontal-line')
    .attr('stroke', 'gray')
    .style('opacity', 0);
};

export const drawIntersectionLineLabel = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  paddingLeft: number
) => {
  return svg
    .append('text')
    .attr('class', 'value-label')
    .attr('fill', 'black')
    .attr('transform', `translate(${paddingLeft - 70 + window.innerWidth / 100},0)`)
    .style('opacity', 0);
};

export const drawCircle = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  d: DynamicStatisticItemData
) => {
  return svg
    .append('circle')
    .attr('cx', x(d3.timeFormat('%d-%m-%Y')(new Date(d.date))) ?? 0)
    .attr('cy', y(d.count))
    .attr('r', 10)
    .attr('fill', 'transparent')
    .style('cursor', 'pointer');
};

export const mouseOverHandler = (
  context: Element | undefined,
  event: MouseEvent,
  verticalLine: d3.Selection<SVGLineElement, unknown, null, undefined>,
  horizontalLine: d3.Selection<SVGLineElement, unknown, null, undefined>,
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  height: number,
  width: number,
  d: DynamicStatisticItemData,
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  setTooltip: (tooltip: any) => void,
  changePositionTooltip: (event: MouseEvent) => void,
  paddingLeft: number,
  countAsMoney: boolean
) => {
  d3.select(context as Element).attr('r', 10);

  verticalLine
    .attr('x1', x(d3.timeFormat('%d-%m-%Y')(new Date(d.date))) ?? 0)
    .attr('y1', 0)
    .attr('x2', x(d3.timeFormat('%d-%m-%Y')(new Date(d.date))) ?? 0)
    .attr('y2', height - margin.bottom)
    .style('opacity', 1);

  horizontalLine
    .attr('x1', 0)
    .attr('y1', y(d.count))
    .attr('x2', width - margin.right)
    .attr('y2', y(d.count))
    .style('opacity', 1);

  const textBounds = svg
    .append('text')
    .attr('class', 'value-label')
    .attr('fill', 'transparent')
    .style('opacity', 0)
    .attr('x', paddingLeft)
    .attr('y', y(d.count))
    .text(countAsMoney ? formatCount(d.count) : d.count)
    .style('opacity', 1);

  const textElement = textBounds.node();

  if (!textElement) {
    return;
  }
  const textRect = textElement.getBoundingClientRect();

  svg
    .append('text')
    .attr('class', 'value-label')
    .attr(
      'x',
      countAsMoney
        ? 0 + textRect.width / 2 + 10
        : paddingLeft - 80 + window.innerWidth / 100 - textRect.width / 2
    )
    .attr('y', y(d.count) - 10)
    .text(countAsMoney ? formatCount(d.count) : d.count)
    .style('opacity', 1)
    .attr('fill', '#2237B5')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle');

  svg.selectAll('.axis-label .tick text').each(function () {
    const tick = d3.select(this);
    const tickRect = (this as SVGElement).getBoundingClientRect();

    if (textRect.y < tickRect.y + tickRect.height && textRect.y + textRect.height > tickRect.y) {
      tick.style('display', 'none');
    }
  });

  const { clientX, clientY } = event;

  setTooltip({
    display: 'block',
    tooltipData: d.showInfo,
    top: clientY,
    left: clientX,
  });

  changePositionTooltip(event);
};

export const mouseOutHandler = (
  context: Element | undefined,
  verticalLine: d3.Selection<SVGLineElement, unknown, null, undefined>,
  horizontalLine: d3.Selection<SVGLineElement, unknown, null, undefined>,
  valueLabel: d3.Selection<SVGTextElement, unknown, null, undefined>,
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  setTooltip: (tooltip: any) => void
) => {
  d3.select(context as Element).attr('r', 10);
  setTooltip({ display: 'none', tooltipData: null, top: 0, left: 0 });

  verticalLine.style('opacity', 0);
  horizontalLine.style('opacity', 0);
  valueLabel.style('opacity', 0);
  svg.selectAll('.background-rect').remove();
  svg.selectAll('.value-label').remove();

  svg.selectAll('.axis-label .tick text').each(function () {
    const tick = d3.select(this);
    const tickRect = (this as SVGElement).getBoundingClientRect();

    tick.style('display', 'block');
  });
};

export const drawLabelLines = (
  dataset: DynamicStatisticItem,
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  idx: number
) => {
  const lastDataPoint = dataset.data[dataset.data.length - 1];

  const endX = x(d3.timeFormat('%d-%m-%Y')(new Date(lastDataPoint.date)));
  const endY = y(lastDataPoint.count);

  const textGroup = svg.append('g');

  const textElement = textGroup
    .append('text')
    .attr('x', endX ?? 0 + 50)
    .attr('y', endY - 10)
    .attr('fill', 'white')
    .attr('font-size', '16px')
    .style('font-weight', '500')
    .text(dataset.title);

  const textElementNode = textElement.node();

  if (!textElementNode) {
    return;
  }

  const bbox = textElementNode.getBBox();
  const paddingX = 16;
  const paddingY = 4;
  const rx = 5;

  textGroup
    .insert('rect', 'text')
    .attr('x', bbox.x - 2 - bbox.width)
    .attr('y', bbox.y - 2)
    .attr('width', bbox.width + 2 * paddingX)
    .attr('height', bbox.height + 2 * paddingY)
    .attr('fill', colorsLine[idx])
    .attr('rx', rx)
    .attr('ry', rx);

  textGroup
    .select('text')
    .attr('x', bbox.x - bbox.width + (bbox.width + paddingX) - bbox.width / 2)
    .attr('y', bbox.y + bbox.height - paddingY)
    .attr('text-anchor', 'middle');
};

export const createXAxis = (
  x: d3.ScaleBand<string>,
  data: DynamicStatisticResponse[],
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  height: number
) => {
  const allDates = data.flatMap((line) => line.data.map((d) => new Date(d.date)));

  const uniqueDates = Array.from(new Set(allDates.map((d) => d3.timeFormat('%d-%m-%Y')(d)))).sort(
    (a, b) => {
      const dateA = d3.timeParse('%d-%m-%Y')(a);
      const dateB = d3.timeParse('%d-%m-%Y')(b);

      if (!dateA || !dateB) {
        return 0;
      }
      return dateA.getTime() - dateB.getTime();
    }
  );

  const xAxis = d3
    .axisBottom(x)
    .tickValues(uniqueDates)
    .tickFormat((d) => d)
    .tickPadding(10);

  svg
    .append('g')
    .attr('transform', `translate(0, ${height - margin.bottom})`)
    .call(xAxis)
    .call((g) => g.select('.domain').remove())
    .call((g) => g.selectAll('.tick line').attr('stroke-opacity', 0.1))
    .attr('font-size', '16px')
    .attr('font-weight', 'bold')
    .selectAll('.tick text')
    .attr('x', (d, i) => 0 - x.bandwidth() / 2);
};

export const backgrounds = ['#16671A', '#2B7D25', '#479B36', '#56CCF2'];
