import React, { useMemo } from 'react';
import { SupplyBillFilterParam } from '@/dto/SupplyBillServiceDto';
import { useAllBillTypes } from '@/hooks/useQuery/useAllBillTypes';
import {
  useAllCreators,
  useAllProjects,
  useAllResponsables,
  useAllStorehouses,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import {
  convertBillTypesIntoFilter,
  defaultArgInput,
  FILTERS_LABELS_DYNAMIC,
  processFilterParams,
  processQueryFields,
  QueryField,
  SAVE_FILTER_KEY,
  useFilteredStoreHouseData,
} from './utils';
import { convertIntoFilter } from '../SupplyPUComponent/utils';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';
import { useSupplyBillBrowseFilter } from './store';
import { useAllNetworkElementTypes } from '@/hooks/useQuery/useAllNetworkElementTypes';
import { LabelCheckbox } from '@/components/inputs/labelCheckbox';
import DynamicInput from '@/components/inputs/DynamicFilter';
import { DataTypeConvert } from '@/components/inputs/DynamicFilter/utils';
import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import { BoxShadow } from '@/components/ui/BoxShadow';

export interface SupplyBillBrowseFormProps {
  updateData: (filt?: SupplyBillFilterParam) => void;
  isFetching: boolean;
  filterParamsToSend: IQureFilter[];
  setFilterParamsToSend: (result: IQureFilter[]) => void;
}

const SupplyBillBrowseForm = ({
  updateData,
  isFetching,
  filterParamsToSend,
  setFilterParamsToSend,
}: SupplyBillBrowseFormProps) => {
  const storeHouseDataFilter = useFilteredStoreHouseData();
  const { setPage, releasedWithoutProjectId, setBooleanValue } = useSupplyBillBrowseFilter();

  const { data: billTypesData } = useAllBillTypes();
  const billTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(billTypesData ?? []),
    [billTypesData]
  );

  const { storehousesOptions } = useAllStorehouses();

  const { puTypesResponse } = useAllPuTypes();
  const puTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(puTypesResponse ?? []),
    [puTypesResponse]
  );

  const { networkElementTypesResponse } = useAllNetworkElementTypes();
  const osTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(networkElementTypesResponse ?? []),
    [networkElementTypesResponse]
  );

  const { responsablesOptions } = useAllResponsables();
  const { projectsOptions } = useAllProjects();

  const { data: creatorsData } = useAllCreators();
  const creatorsOptions = useMemo(() => convertIntoFilter(creatorsData ?? []), [creatorsData]);

  const getTaskBrowseCatalogList = (key: string): { label: string; value: string }[] => {
    switch (key) {
      case 'contractorIdList':
        return responsablesOptions;
      case 'projectIdList':
        return projectsOptions;
      case 'supplyIdList':
        return storehousesOptions;
      case 'billCreatorList':
      case 'billEditorList':
        return creatorsOptions;
      case 'networkElementTypeIdList':
        return osTypesOptions ?? [];
      case 'puTypeIdList':
        return puTypesOptions ?? [];
      case 'billTypeList':
        return billTypesOptions ?? [];
      default:
        return [];
    }
  };

  const callBackFn = (
    queryFields: Record<string, QueryField>,
    filterSetting: DataTypeConvert[]
  ) => {
    const result = processQueryFields(queryFields, filterSetting);
    setFilterParamsToSend(result);
  };
  const onSubmit = () => {
    const filterValues = processFilterParams(filterParamsToSend, releasedWithoutProjectId);
    updateData(filterValues);
    setPage(1);
  };

  return (
    <BoxShadow>
      <DynamicInput
        defaultArgInput={defaultArgInput}
        data={storeHouseDataFilter}
        callBackFn={callBackFn}
        saveFilterKey={SAVE_FILTER_KEY}
        searchFn={onSubmit}
        getCatalogList={getTaskBrowseCatalogList}
        disabledSearch={!isFetching}
      >
        <LabelCheckbox
          value={releasedWithoutProjectId}
          onChange={setBooleanValue('releasedWithoutProjectId')}
          label={FILTERS_LABELS_DYNAMIC.releasedWithoutProjectId}
        />
      </DynamicInput>
    </BoxShadow>
  );
};

export default SupplyBillBrowseForm;
