import { Tooltip } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import { FC, lazy } from 'react';

const CheckCircleOutlineIcon = lazy(() => import('@mui/icons-material/CheckCircleOutline'));
const HighlightOffIcon = lazy(() => import('@mui/icons-material/HighlightOff'));
const ErrorOutlineIcon = lazy(() => import('@mui/icons-material/ErrorOutline'));

interface IYungaAnalyzeTextFieldIcon {
  type?: 'done' | 'cancel' | 'unknown';
}

export const YungaAnalyzeTextFieldIcon: FC<IYungaAnalyzeTextFieldIcon> = ({ type }) => {
  switch (type) {
    case 'done':
      return (
        <Tooltip
          title='Соответствует данным на фотографии'
          arrow
        >
          <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 16 }} />
        </Tooltip>
      );
    case 'cancel':
      return (
        <Tooltip
          title='Не соответствует данным на фотографии'
          arrow
        >
          <HighlightOffIcon style={{ color: red[500], fontSize: 16 }} />
        </Tooltip>
      );
    case 'unknown':
      return (
        <Tooltip
          title='Данныe на фотографии не корректны'
          arrow
        >
          <ErrorOutlineIcon style={{ color: yellow[900], fontSize: 16 }} />
        </Tooltip>
      );
    default:
      return null;
  }
};
