import { useContext } from 'react';
import { AuthContext } from '@/app/auth/AuthProvider';

/**
 * Хук для получения контекста аутентификации.
 *
 * Этот хук позволяет получить доступ к значению контекста `AuthContext`, который хранит информацию о состоянии аутентификации пользователя.
 *
 * @returns {object} Значение контекста аутентификации, предоставляемое `AuthContext`.
 *
 * @example
 * const auth = useAuth();
 * console.log(auth.user); // Доступ к информации о пользователе
 */
const useAuth = () => {
  return useContext(AuthContext); // Возвращает значение из AuthContext
};

export default useAuth;
