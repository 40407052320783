import { Link } from 'react-router-dom';
import React from 'react';
import { ReactComponent as YodaNewLogo } from '@/images/icons/YodaNew_1.svg';
import { ReactComponent as YodaMobileLogo } from '@/images/icons/YodaMobile_1.svg';

import NavbarBolt from './NavbarBolt/NavbarBolt';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { useNavbar } from './store';

const NavbarLogoWithBolt = () => {
  const isMobile = useMobileSize();
  const toggleDrawer = useNavbar((state) => state.toggleDrawer);
  const closeDrawer = () => toggleDrawer(false);

  return (
    <>
      <div
        style={{ display: 'flex', gap: 8 }}
        onClick={closeDrawer}
      >
        <NavbarBolt />
        <Link to='/'>{isMobile ? <YodaMobileLogo /> : <YodaNewLogo />}</Link>
      </div>
    </>
  );
};

export default NavbarLogoWithBolt;
