import { color, scrollbarThin } from '@/styles/mixins';
import { Box, Divider, styled, SxProps, Theme } from '@mui/material';
import { IChronologyRowDiv } from '../features/taskEditor/item/chronology/Chronology.styled';
import { BoxShadow } from '../ui/BoxShadow';
import { FC, ReactNode } from 'react';

interface IRegistryADPURowDivBoxShadow {
  children: ReactNode;
  type: 'left' | 'right';
}
export const RegistryADPUBS: FC<IRegistryADPURowDivBoxShadow> = ({ type, children }) => {
  return (
    <BoxShadow
      sx={{
        width: '100%',
        mr: type === 'left' ? 6 : 'auto',
        ml: type === 'right' ? 6 : 'auto',
        mb: 3,
        mt: 6,
        maxHeight: 'calc(100% - 64px)',
        overflow: 'scroll',
      }}
      p={0}
    >
      {children}
    </BoxShadow>
  );
};
export const RegistryADPUB: FC<{ children: ReactNode; width: string | number }> = ({
  children,
  width,
}) => {
  return (
    <Box
      sx={{
        width,
        mr: 3,
        ml: 3,
        maxHeight: 'calc(100% - 64px)',
        overflow: 'scroll',
      }}
      p={0}
    >
      {children}
    </Box>
  );
};

export const RegistryADPUBox = styled(RegistryADPUB)`
  ${scrollbarThin}
`;
export const RegistryADPUBoxShadow = styled(RegistryADPUBS)`
  ${scrollbarThin}
`;

export const RegistryADPURowDiv = styled('div')<IChronologyRowDiv & { isActive?: boolean }>(
  ({ borderColor, borderType = 'dashed', isActive = false }) => ({
    padding: borderColor ? '16px 16px 16px 20px' : '17px 17px 17px 21px',
    border: borderColor ? `1px ${borderType} ${color(borderColor)}` : 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':hover': isActive
      ? {
          cursor: 'pointer',
          background: color('hoverBlue', 0.09),
        }
      : {},
  })
);

export const RegistryADPUDivider = styled(Divider)({ width: '80%', margin: 'auto' });

export const RegistryADPUPopupOpenButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'absolute',
  top: '-6px',
  left: 32,
  color: color('muiBlue'),
});

export const RegistryADPUTitle = styled('div')({
  fontSize: '20px',
  fontWeight: 500,
  textTransform: 'uppercase',
  height: 48,
  paddingTop: 8,
});

export const RegistryADPUWrapper = styled('div')({
  padding: 32,
  width: '100%',
  height: 'calc(100vh - 190px)',
  overflow: 'scroll',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  position: 'relative',
});
export const VerticalLineWrapper = styled('div')({
  top: 32,
  bottom: 32,
  left: 'calc(50% - 25px)',
  width: 1,
  position: 'absolute',
  background: color('mailLine'),
});
export const VerticalLineWrapper75 = styled(VerticalLineWrapper)({
  left: 'calc(75% - 27px)',
  background: color('mailLine'),
});

export const RowNumber = styled('div')({
  cursor: 'pointer',
  width: '10%',
});
export const ButtonWrapper = styled('div')({
  position: 'absolute',
  top: 32,
  left: 32,
  color: color('muiBlue'),
  cursor: 'pointer',
});
