import { createStore } from '@/utils/createStore';

export type IPageData = {
  page: number;
};

export type ILabelCheckbox = {
  releasedWithoutProjectId: boolean;
};

type ObjectKey<Obj> = keyof Obj;

export type LabelCheckboxKeys = ObjectKey<ILabelCheckbox>;

export type AllInputKeys = LabelCheckboxKeys;

type ITasksBrowseFunks = {
  setPage: (page: number) => void;
  setBooleanValue: (key: LabelCheckboxKeys) => (value: boolean) => void;
};
export type ISupplyBillBrowseStore = ITasksBrowseFunks & IPageData & ILabelCheckbox;

export const pageData = {
  page: 1,
};

export const defaultValueCheckbox: ILabelCheckbox = {
  releasedWithoutProjectId: false,
};

const defaultValue: IPageData & ILabelCheckbox = {
  ...pageData,
  ...defaultValueCheckbox,
};

export const useSupplyBillBrowseFilter = createStore<ISupplyBillBrowseStore>(
  (set, get) => ({
    ...defaultValue,

    setDefaultValue: () =>
      set(() => ({
        ...defaultValue,
      })),

    setPage: (page) => set(() => ({ page })),
    setBooleanValue: (key) => (value) =>
      set(() => ({
        [key]: value,
      })),
  }),
  'Supply bill browse'
);
