import { Grid, Pagination, Stack, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useYunga } from '@/components/features/Yunga/hooks/YungaHook';
import { count } from '@/components/features/Yunga/api/YungaService';
import { YungaLogItem } from './YungaLogItem';
import { YungaStatusPane } from './YungaStatusPane';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { ImageViewItem } from '@/components/features/taskEditor/item/ImageItem/ImageItem';
import { useKeyPress } from '@/hooks/usePressKey';
import { PersistentDrawerRight } from '@/components/ImageDrawer';

export const YungaBrows = () => {
  const limit = 5;
  const { fileId, findAll, page, setPage, searchId, setSearchId, yungaLogResponseList, total } =
    useYunga();

  const handleChagePage = (event: any, page: number) => {
    setPage(page);
    setSearchId('');
  };

  useEffect(() => {
    findAll();
  }, [page, count]);

  const handleFinaAll = () => {
    setPage(1);
    findAll();
  };

  const handleClean = () => {
    setPage(1);
    setSearchId('');
  };

  useKeyPress('Enter', handleFinaAll);

  return (
    <Grid
      container
      spacing={1}
      p={2}
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <Grid
        item
        container
        width={'100%'}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <YungaStatusPane></YungaStatusPane>
        <Stack
          direction={'row'}
          spacing={2}
          alignItems='center'
          alignContent={'center'}
        >
          <TextField
            id='tf-logId'
            label='log/file id'
            variant='standard'
            value={searchId}
            onChange={(t) => setSearchId(t.target?.value)}
          />
          <FilterButtons
            onCloseClick={handleClean}
            onSearchClick={handleFinaAll}
          />
        </Stack>
      </Grid>
      <Grid
        item
        container
        spacing={2}
      >
        <Grid
          item
          xs={8}
          sx={{ minHeight: '75vh', maxHeight: '75vh', overflow: 'auto' }}
          p={2}
          style={{
            scrollbarGutter: 'stable',
          }}
        >
          <Stack spacing={2}>
            {yungaLogResponseList &&
              yungaLogResponseList.map((t) => (
                <YungaLogItem
                  key={t.id}
                  yungaLogResponse={t}
                />
              ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
          container
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {(fileId && (
            <ImageViewItem
              obj={'YungaBrows'}
              imageId={fileId}
              titles={[fileId]}
              withDelete={false}
              createdOn={new Date()}
              ids={[
                {
                  id: fileId,
                  typeList: null,
                  createdOn: new Date(),
                },
              ]}
            />
          )) || <Typography>(пусто)</Typography>}
        </Grid>
      </Grid>
      <PersistentDrawerRight />

      <Grid item>
        <Pagination
          page={page}
          count={Math.ceil(total / limit)}
          size='large'
          onChange={handleChagePage}
        />
      </Grid>
    </Grid>
  );
};
