export enum ImageItemTypes {
  PDF = 'pdf',
  SHEET = 'sheet',
  TEXT_PLAIN = 'text/plain',
  STREAM = 'stream',
  MP4 = 'mp4',
  VIDEO = 'video',
  EXCEL = 'excel',
}

export const isExcelFile = (contentType?: string | null) => {
  if (!contentType) return false;
  return (
    contentType?.includes(ImageItemTypes.SHEET) ||
    contentType?.includes(ImageItemTypes.TEXT_PLAIN) ||
    contentType?.includes(ImageItemTypes.EXCEL)
  );
};
