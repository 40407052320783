import { Box, styled, Stack } from '@mui/material';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import { LoadingButton } from '@mui/lab';
import { font } from '@/styles/mixins';

export const ComplaintListLoaderWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  height: 200,
  alignItems: 'center',
});
export const ComplaintListWrapper = styled(Stack)({
  minHeight: '70vh',
  maxHeight: '70vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'thin',
});
