import React, { useEffect, useMemo } from 'react';
import { Menu } from '@mui/material';

import { useAnchorElement } from '@/hooks/useAnchorElement';
import {
  HouseEntranceShemeFlat,
  HouseEntranceShemeAdress,
  HouseEntranceShemeHeaderRow,
  HouseEntranceShemeLegendRow,
  HouseEntranceShemeLegendLayer,
  HouseEntranceShemeLegendWrapper,
  HouseEntranceShemeHeaderBoxShadow,
  HouseEntranceShemeHeaderInfoWrapper,
  HouseEntranceShemeNotFoundWrapper,
  HouseEntranceShemeHeaderWrapper,
  HouseEntranceShemeHeaderInfoItem,
  HouseEntranceShemeNotFoundItemsWrapper,
  HouseEntranceShemeHeaderLegendWrapper,
  HouseEntranceShemeLabelWrapper,
} from './HouseEntranceSheme.styled';
import { SCHEME_OPEN_QUERY } from './utils';
import { useHouseOdpuReplacementCount } from '@/hooks/useQuery/useHouseOdpuReplacement';
import { useParams, useSearchParams } from 'react-router-dom';
import { getAllGateways, useHermesLog } from '@/components/PnrHermes/utils';
import { useHouseLevelFloorType } from '@/components/features/pnr/HouseEntranceEdit/utils';
import shallow from 'zustand/shallow';
import {
  PnrPlanningsCountLoading,
  PnrPlanningsCountMainText,
  PnrPlanningsCountSecondaryText,
} from '@/components/blocks/PnrPlanningsCount/PnrPlanningsCount.styled';
import { usePNRStore } from '@/components/features/pnr/store';
import {
  ShemeLayerOption,
  SCHEME_LAYER_OPTIONS,
  SCHEME_LAYER_HERMES_OPTION,
} from '@/components/features/pnr/constants';
import HouseEntranceFlatCountMatched from '@/components/features/pnr/HouseEntranceFlatCountMatched/HouseEntranceFlatCountMatched';

const HouseEntranceShemeHeader = () => {
  const {
    houseData,
    floorCount,
    flatCount,
    entranceCount,
    setShemeLayer,
    shemeLayer,
    setHermesGateways,
    hermesBackendCalled,
    networkElementsWithoutEntranceCount,
  } = usePNRStore(
    (state) => ({
      houseData: state.houseData,
      floorCount: state.floorCount,
      flatCount: state.flatCount,
      entranceCount: state.entranceCount,
      setShemeLayer: state.setShemeLayer,
      shemeLayer: state.shemeLayer,
      setHermesGateways: state.setHermesGateways,
      hermesBackendCalled: state.hermesBackendCalled,
      networkElementsWithoutEntranceCount: state.networkElementsWithoutEntranceCount,
    }),
    shallow
  );
  const { getHermesLog } = useHermesLog();

  const {
    anchorEl: anchorLayers,
    handleOpen: handleOpenLayers,
    handleClose: handleCloseLayers,
    open: openLayers,
  } = useAnchorElement();

  const { houseDtoId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { odpuReplacementCount, isLoadingOdpuReplacementCount } =
    useHouseOdpuReplacementCount(houseDtoId);

  const openLayersSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleOpenLayers(event);
  };
  const handleLayerSelect = (el: ShemeLayerOption) => {
    setSearchParams({ [SCHEME_OPEN_QUERY]: el.value });
    handleCloseLayers();
  };
  const { floorTypes } = useHouseLevelFloorType();

  useEffect(() => {
    const urlLayer = searchParams.get(SCHEME_OPEN_QUERY);
    const neededLayer = SCHEME_LAYER_OPTIONS.find((layer) => layer.value === urlLayer);
    if (neededLayer) setShemeLayer(neededLayer);
  }, [searchParams]);

  useEffect(() => {
    if (houseData && shemeLayer?.value === SCHEME_LAYER_HERMES_OPTION && !hermesBackendCalled) {
      const allGateways = getAllGateways(houseData, floorTypes);
      setHermesGateways(allGateways);
      allGateways.forEach((gateway) => {
        getHermesLog(gateway.smrImei, houseData.houseId);
      });
    }
  }, [shemeLayer?.value]);

  return (
    <>
      <HouseEntranceShemeHeaderWrapper>
        <HouseEntranceShemeHeaderBoxShadow>
          <HouseEntranceShemeHeaderRow>
            <HouseEntranceShemeHeaderInfoItem alignStart>
              <PnrPlanningsCountSecondaryText>Адрес</PnrPlanningsCountSecondaryText>
              <HouseEntranceShemeAdress bigFontSize>{houseData?.address}</HouseEntranceShemeAdress>
            </HouseEntranceShemeHeaderInfoItem>

            <HouseEntranceShemeHeaderInfoWrapper>
              {(!!entranceCount || !!floorCount || !!flatCount) && (
                <HouseEntranceFlatCountMatched>
                  {!!entranceCount && (
                    <HouseEntranceShemeHeaderInfoItem>
                      <PnrPlanningsCountSecondaryText>Подъезды</PnrPlanningsCountSecondaryText>
                      <PnrPlanningsCountMainText bigFontSize>
                        {entranceCount}
                      </PnrPlanningsCountMainText>
                    </HouseEntranceShemeHeaderInfoItem>
                  )}
                  {!!floorCount && (
                    <HouseEntranceShemeHeaderInfoItem>
                      <PnrPlanningsCountSecondaryText>Этажи</PnrPlanningsCountSecondaryText>
                      <PnrPlanningsCountMainText bigFontSize>
                        {floorCount}
                      </PnrPlanningsCountMainText>
                    </HouseEntranceShemeHeaderInfoItem>
                  )}
                  {!!flatCount && (
                    <HouseEntranceShemeHeaderInfoItem>
                      <PnrPlanningsCountSecondaryText>Квартиры</PnrPlanningsCountSecondaryText>
                      <PnrPlanningsCountMainText bigFontSize>{flatCount}</PnrPlanningsCountMainText>
                    </HouseEntranceShemeHeaderInfoItem>
                  )}
                </HouseEntranceFlatCountMatched>
              )}

              {!!houseData?.smrTasks?.length && (
                <HouseEntranceShemeHeaderInfoItem>
                  <PnrPlanningsCountSecondaryText>ИПУ</PnrPlanningsCountSecondaryText>
                  <PnrPlanningsCountMainText bigFontSize>
                    {houseData?.ipuCount || 0}
                  </PnrPlanningsCountMainText>
                </HouseEntranceShemeHeaderInfoItem>
              )}
              <HouseEntranceShemeHeaderInfoItem>
                <PnrPlanningsCountSecondaryText>ОДПУ</PnrPlanningsCountSecondaryText>
                <PnrPlanningsCountLoading loading={isLoadingOdpuReplacementCount}>
                  <PnrPlanningsCountMainText bigFontSize>
                    {odpuReplacementCount}
                  </PnrPlanningsCountMainText>
                </PnrPlanningsCountLoading>
              </HouseEntranceShemeHeaderInfoItem>
            </HouseEntranceShemeHeaderInfoWrapper>
          </HouseEntranceShemeHeaderRow>
        </HouseEntranceShemeHeaderBoxShadow>

        <HouseEntranceShemeNotFoundWrapper>
          <div>Подъезд не указан</div>
          <HouseEntranceShemeNotFoundItemsWrapper>
            <HouseEntranceShemeHeaderInfoItem withoutGap>
              <PnrPlanningsCountSecondaryText>Шлюз</PnrPlanningsCountSecondaryText>
              <PnrPlanningsCountMainText>
                {networkElementsWithoutEntranceCount.gateway}
              </PnrPlanningsCountMainText>
            </HouseEntranceShemeHeaderInfoItem>
            <HouseEntranceShemeHeaderInfoItem withoutGap>
              <PnrPlanningsCountSecondaryText>Роутер</PnrPlanningsCountSecondaryText>
              <PnrPlanningsCountMainText>
                {networkElementsWithoutEntranceCount.router}
              </PnrPlanningsCountMainText>
            </HouseEntranceShemeHeaderInfoItem>
          </HouseEntranceShemeNotFoundItemsWrapper>
        </HouseEntranceShemeNotFoundWrapper>
      </HouseEntranceShemeHeaderWrapper>

      <HouseEntranceShemeHeaderLegendWrapper>
        <div style={{ margin: '0 auto' }}>
          <HouseEntranceShemeLegendWrapper>
            {Object.values(shemeLayer?.sheme)?.map((legendItem) => (
              <HouseEntranceShemeLegendRow key={legendItem.text}>
                <HouseEntranceShemeFlat
                  flatStyles={legendItem}
                  sideWidth={legendItem.narrow}
                />
                <HouseEntranceShemeAdress>{legendItem.text}</HouseEntranceShemeAdress>
              </HouseEntranceShemeLegendRow>
            ))}
          </HouseEntranceShemeLegendWrapper>
        </div>
        <Menu
          anchorEl={anchorLayers}
          open={openLayers}
          onClose={() => handleCloseLayers()}
        >
          <HouseEntranceShemeLegendWrapper tooltipMenu>
            {SCHEME_LAYER_OPTIONS?.map((el) => (
              <HouseEntranceShemeLegendRow key={el.value}>
                <HouseEntranceShemeLegendLayer
                  selected={shemeLayer?.value === el.value}
                  onClick={() => handleLayerSelect(el)}
                >
                  {el.label}
                </HouseEntranceShemeLegendLayer>
              </HouseEntranceShemeLegendRow>
            ))}
          </HouseEntranceShemeLegendWrapper>
        </Menu>
        <HouseEntranceShemeLabelWrapper>
          <HouseEntranceShemeLegendLayer
            onClick={openLayersSelect}
            selected
          >
            {shemeLayer?.label ?? 'Слои'}
          </HouseEntranceShemeLegendLayer>
        </HouseEntranceShemeLabelWrapper>
      </HouseEntranceShemeHeaderLegendWrapper>
    </>
  );
};

export default HouseEntranceShemeHeader;
