import { styled } from '@mui/material/styles';
import { Box, ImageListItemBar } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { color } from '@/styles/mixins';

export const SImageListItemBar = styled(ImageListItemBar)({
  position: 'relative',
  bottom: 0,
});

export const SCollapse = styled(Collapse)({
  background: color('gray', 0.95),
  cursor: 'default',
  position: 'relative',
  zIndex: 100,
});

interface WrapperProps {
  disabled?: boolean;
}
export const Wrapper = styled(Box)<WrapperProps>(({ disabled }) => ({
  cursor: disabled ? 'default' : 'pointer',
  position: 'absolute',
  top: 0,
  width: '100%',
  color: color('trueWhite', 0.87),
}));

export const ImageItemTitleSup = styled('sup')({
  position: 'absolute',
  top: '15px',
  right: '10px',
});

export const ImageItemDate = styled('div')({
  position: 'absolute',
  top: '-4px',
  left: '15px',
  fontSize: '10px',
});

export const ImageItemTag = styled('li')({
  padding: '0 10px',
  listStyle: 'inside',
  lineHeight: 'normal',
});

export const ImageItemListItemBar = styled(ImageListItemBar)({
  '.MuiImageListItemBar-titleWrap': {
    padding: '4px 0',
  },
});

export const ImageItemBarRow = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});
