import { useDownload } from '@/hooks/useDownload';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, IconButton } from '@mui/material';
import { DownloadSingleFileLoadWrapper } from './DownloadSingleFile.styled';

export interface DownloadSingleFileProps {
  url?: string;
}

const DownloadSingleFile = ({ url }: DownloadSingleFileProps) => {
  const { download, isLoading } = useDownload();

  return (
    <>
      {isLoading ? (
        <DownloadSingleFileLoadWrapper>
          <CircularProgress size={16} />
        </DownloadSingleFileLoadWrapper>
      ) : (
        <IconButton onClick={() => download(url)}>
          <DownloadIcon color='info' />
        </IconButton>
      )}
    </>
  );
};

export default DownloadSingleFile;
