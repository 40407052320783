import { fetchCatch } from '@/hooks/ActionLogHook';
import { Grid, IconButton, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import FileSaver from 'file-saver';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { InfoComplaintBlockLabel } from './InfoComplaintBlock.styled';
import { useState } from 'react';
import { useGalleryDataFromTask } from '../utils';
import { useGalleryData } from '@/components/Gallery/store';
import { GetFileUrlByFileId } from '@/services/YodaRestService';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import { color } from '@/styles/mixins';
import { useGallery } from '../../NewGallery/utils';

const GalleryBlock = () => {
  const { openGallery } = useGallery();
  const [loading, setLoading] = useState(false);
  const { getImageUrlById } = useGalleryData((state) => ({
    getImageUrlById: state.getImageUrlById,
  }));
  const photoList = useGalleryDataFromTask();
  const dowloadFileZip = (file: any) => {
    return {
      url: GetFileUrlByFileId(file.id),
      fileName: file.typeList[0],
    };
  };

  const handleDownloadClick = async (item: any) => {
    setLoading(true);
    try {
      const file = dowloadFileZip(item);
      const fileUrl = await file.url;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const filename = `${file.fileName ?? 'фото'}.${blob.type.split('/').pop()}`;
      FileSaver.saveAs(blob, filename);
    } catch (error) {
      fetchCatch(error, 'Ошибка загрузки фотографии');
    } finally {
      setLoading(false);
    }
  };

  const onOpenGalleryClick = () => {
    openGallery({ title: 'Фото жалобы', images: photoList });
  };

  if (!photoList.length) return null;
  return (
    <Stack
      style={{ backgroundColor: '#FFF', borderRadius: 10 }}
      p={1}
    >
      <Grid
        xs={12}
        container
      >
        <Grid
          item
          xs={12}
          mb={1}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <InfoComplaintBlockLabel>Галерея</InfoComplaintBlockLabel>
          <IconButton
            sx={{ mr: 2 }}
            disabled={!photoList?.length}
            onClick={onOpenGalleryClick}
          >
            <ImageSearchRoundedIcon htmlColor={color('muiBlue')} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          maxHeight={300}
          overflow={'auto'}
        >
          <ImageList
            cols={3}
            rowHeight={160}
            sx={{ mb: 1 }}
          >
            {photoList.length
              ? photoList.map((item: any) => (
                  <Stack
                    key={item?.img}
                    p={1}
                  >
                    <ImageListItem>
                      <img
                        src={getImageUrlById(item?.id)}
                        srcSet={getImageUrlById(item?.id)}
                        alt={item?.typeList?.at(0) ?? ' неизвестно'}
                        loading='lazy'
                        height={'150'}
                        style={{ minHeight: 130 }}
                      />
                    </ImageListItem>
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography
                        textAlign={'center'}
                        variant='subtitle2'
                      >{`${item?.typeList?.slice(0, 12) ?? 'неизвестно'}`}</Typography>
                      <IconButton
                        disabled={loading}
                        onClick={() => handleDownloadClick(item)}
                      >
                        <FileDownloadIcon color='info' />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))
              : []}
          </ImageList>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GalleryBlock;
