import React, { useMemo, useState } from 'react';
import {
  NewGalleryLeft,
  NewGalleryRight,
  NewGalleryRightWrapper,
  NewGalleryTitle,
  NewGalleryWrapper,
} from './NewGallery.styled';
import DownloadAllButton from '@/components/button/DownloadAllButton/DownloadAllButton';
import { getFilePromises, isImageGroup, useGallery } from './utils';
import NewGalleryPreviewSwitch from './NewGalleryPreviewSwitch';
import { IImageGroup } from '@/components/Gallery/store';
import NewGalleryActiveImage from './NewGalleryActiveImage';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import { useKeyPress } from '@/hooks/usePressKey';

const NewGallery = () => {
  const { galleryData } = useGallery();

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [previewContentTypes, setPreviewContentTypes] = useState<{
    [groupIndex: number]: { [imageIndex: number]: string | null };
  }>({});

  const galleryTitle = galleryData?.title;
  const images = galleryData?.images;

  const handlePreviewImageClick = (imageIndex: number, groupIndex?: number) => {
    setActiveImageIndex(imageIndex);
    groupIndex !== undefined && setActiveGroupIndex(groupIndex);
  };

  const handleContentTypeLoaded = (
    imageIndex: number,
    groupIndex: number,
    contentType: string | null
  ) => {
    setPreviewContentTypes((prev) => ({
      ...prev,
      [groupIndex]: {
        ...(prev[groupIndex] || {}),
        [imageIndex]: contentType,
      },
    }));
  };

  const activeImageContentType = previewContentTypes[activeGroupIndex]?.[activeImageIndex] || null;

  const allFilesPromise = getFilePromises(images);
  const getActiveImage = useMemo(() => {
    if (!images) return null;
    if (isImageGroup(images[0])) {
      return (images as IImageGroup[])[activeGroupIndex]?.imageList[activeImageIndex] as IImage;
    } else {
      return images[activeImageIndex] as IImage;
    }
  }, [images, activeGroupIndex, activeImageIndex]);

  const handleNextImage = () => {
    if (!images || images.length === 0) return;

    const isGroupMode = 'imageList' in images[0];

    if (isGroupMode) {
      const currentGroup = images[activeGroupIndex] as IImageGroup;
      const nextImageIndex = activeImageIndex + 1;
      const nextGroupIndex = activeGroupIndex + 1;

      if (nextImageIndex < currentGroup.imageList.length) {
        setActiveImageIndex(nextImageIndex);
      } else if (nextGroupIndex < images.length) {
        setActiveGroupIndex(nextGroupIndex);
        setActiveImageIndex(0);
      }
    } else {
      if (activeImageIndex < images.length - 1) {
        setActiveImageIndex(activeImageIndex + 1);
      }
    }
  };

  const handlePrevImage = () => {
    if (!images || images.length === 0) return;

    const isGroupMode = isImageGroup(images[0]);

    if (isGroupMode) {
      const prevImageIndex = activeImageIndex - 1;
      const prevGroupIndex = activeGroupIndex - 1;

      if (prevImageIndex >= 0) {
        setActiveImageIndex(prevImageIndex);
      } else if (prevGroupIndex >= 0) {
        const prevGroup = images[prevGroupIndex] as IImageGroup;
        setActiveGroupIndex(prevGroupIndex);
        setActiveImageIndex(prevGroup.imageList.length - 1);
      }
    } else {
      if (activeImageIndex > 0) {
        setActiveImageIndex(activeImageIndex - 1);
      }
    }
  };

  useKeyPress('ArrowRight', handleNextImage);
  useKeyPress('ArrowLeft', handlePrevImage);

  if (!images) return null;

  return (
    <NewGalleryWrapper>
      <NewGalleryLeft>
        <NewGalleryActiveImage
          image={getActiveImage}
          contentType={activeImageContentType}
          withControlsTooltip
        />
      </NewGalleryLeft>

      <NewGalleryRightWrapper>
        <div style={{ display: 'flex', gap: 10, alignItems: 'flex-start' }}>
          {<NewGalleryTitle>{galleryTitle}</NewGalleryTitle>}

          <DownloadAllButton
            disabled={!allFilesPromise?.length}
            zipName={galleryTitle ?? 'Архив_фотографий'}
            downloadLinksPromises={allFilesPromise}
            buttonText={'Скачать все'}
          />
        </div>
        <NewGalleryRight>
          <NewGalleryPreviewSwitch
            imagesOrGroups={images}
            onClick={handlePreviewImageClick}
            activeImageIndex={activeImageIndex}
            activeGroupIndex={activeGroupIndex}
            onContentTypeLoaded={handleContentTypeLoaded}
          />
        </NewGalleryRight>
      </NewGalleryRightWrapper>
    </NewGalleryWrapper>
  );
};

export default NewGallery;
