import { color } from '@/styles/mixins';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { KeyString, getKtnOrKttValue, lookUpExistingEquipment } from './utils';
import DateField from '@/components/DateField';
import { PresentTransformer } from '@/dto/taskmap/task/TaskResultDto';
import { useExsistingEquipmentValidation } from './forms';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '../../tasksBrowse/utils';
import { useMemo } from 'react';

interface FromPresentTransformerProps {
  keyString: KeyString;
  formData: PresentTransformer;
}
const FromPresentTransformer = ({ formData, keyString }: FromPresentTransformerProps) => {
  const { register, control } = useFormContext();
  const { getCatalogMapWithErr, amperageTransformerType, kttEnum } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    amperageTransformerType: state.amperageTransformerType,
    kttEnum: state.kttEnum,
  }));
  const rules = useExsistingEquipmentValidation();
  const optionsAmperageTransformerType = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('amperageTransformerType')),
    [amperageTransformerType]
  );
  const optionsKtt = useMemo(() => taskMapToArray(getCatalogMapWithErr('kttEnum')), [kttEnum]);
  console.log('optionsKtt', optionsKtt);

  return (
    <>
      <Stack
        mb={3}
        mt={3}
      >
        <Typography
          variant='h6'
          color={color('trueBlack')}
        >
          {lookUpExistingEquipment[keyString]}
        </Typography>
      </Stack>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.type`}
            control={control}
            defaultValue={formData.type}
            rules={rules.ttType}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                sx={{ width: '200px' }}
                options={optionsAmperageTransformerType}
                onChange={(event, newValue) => {
                  field.onChange(newValue ? newValue.value : null);
                }}
                value={
                  optionsAmperageTransformerType.find(
                    (option) => option.value === field.value || option.label === field.value
                  ) || {
                    label: field.value,
                    value: field.value,
                  }
                }
                getOptionLabel={(option) => {
                  return (
                    getCatalogMapWithErr('amperageTransformerType').get(option.value) ||
                    option.label ||
                    ''
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Тип'
                    variant='standard'
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.ktt`}
            control={control}
            defaultValue={getKtnOrKttValue(formData.ktt)}
            rules={rules.tnKtn}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                sx={{ width: '200px' }}
                options={optionsKtt}
                onChange={(event, newValue) => {
                  field.onChange(newValue ? newValue.value : null);
                }}
                value={
                  optionsKtt.find(
                    (option) => option.value === field.value || option.label === field.value
                  ) || {
                    label: field.value,
                    value: field.value,
                  }
                }
                getOptionLabel={(option) => {
                  return getCatalogMapWithErr('kttEnum').get(option.value) || option.label || '';
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='КТT'
                    variant='standard'
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            )}
          />
          <TextField
            hidden
            {...register(`${keyString}.accuracy`, {
              value: formData?.accuracy,
            })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.checkDate`}
            control={control}
            defaultValue={formData?.checkDate}
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                label='Дата проверки'
                defaultValue={formData?.checkDate}
                hookFormString={`${keyString}.checkDate`}
                roundTime={false}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.nextCheckDate`}
            control={control}
            defaultValue={formData?.nextCheckDate}
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                label={'Следующая поверка'}
                defaultValue={formData?.nextCheckDate}
                hookFormString={`${keyString}.nextCheckDate`}
                roundTime={false}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <Stack>
        {formData?.phaseList &&
          [...formData.phaseList]
            .sort((a, b) => a.phase.localeCompare(b.phase))
            .map((item, index) => {
              return (
                <Grid
                  item
                  container
                  key={item.phase}
                >
                  <Typography
                    variant='h6'
                    color={color('trueBlack')}
                    marginY={2}
                  >{`фаза ${item?.phase}`}</Typography>
                  <Grid
                    item
                    container
                    spacing={3}
                  >
                    <Grid item>
                      <Controller
                        name={`${keyString}.phaseList.${index}.serial`}
                        control={control}
                        defaultValue={formData?.phaseList[index].serial}
                        rules={rules.ttSerial}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            sx={{ width: '200px' }}
                            id={`${keyString}`}
                            label='№ ТТ'
                            variant='standard'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name={`${keyString}.phaseList.${index}.year`}
                        control={control}
                        defaultValue={formData?.phaseList[index].year}
                        rules={rules.ttYear}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            sx={{ width: '200px' }}
                            id={`${keyString}`}
                            label='Год выпуска'
                            variant='standard'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name={`${keyString}.phaseList.${index}.stamp`}
                        control={control}
                        defaultValue={formData?.phaseList[index].stamp}
                        rules={rules.ttStamp}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            sx={{ width: '200px' }}
                            id={`${keyString}`}
                            label='Пломба'
                            variant='standard'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                      <TextField
                        hidden
                        {...register(`${keyString}.phaseList.${index}.phase`, {
                          value: formData?.phaseList[index].phase,
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
      </Stack>
    </>
  );
};

export default FromPresentTransformer;
