import { useState } from 'react';

/**
 * Хук для управления состоянием якорного элемента (anchor element).
 *
 * Этот хук используется для управления состоянием элемента, к которому прикрепляются всплывающие элементы (например, меню, тултипы и т.д.).
 * Он позволяет открывать и закрывать всплывающие элементы, устанавливая и очищая якорный элемент (например, кнопку или другой элемент DOM).
 *
 * @returns {Object} Объект с функциями и состоянием:
 * - `handleOpen`: Функция для открытия якорного элемента.
 * - `handleClose`: Функция для закрытия якорного элемента.
 * - `open`: Булево значение, указывающее, открыт ли якорный элемент.
 * - `anchorEl`: Текущий якорный элемент, или `null`, если он не задан.
 *
 * @example
 * const { handleOpen, handleClose, open, anchorEl } = useAnchorElement();
 * <button onClick={handleOpen}>Открыть меню</button>
 * <Menu open={open} anchorEl={anchorEl} onClose={handleClose} />
 */
export const useAnchorElement = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // Состояние для хранения якорного элемента
  const open = Boolean(anchorEl); // Определяем, открыт ли якорный элемент

  // Функция для открытия якорного элемента
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) =>
    setAnchorEl(event.currentTarget);

  // Функция для закрытия якорного элемента
  const handleClose = () => setAnchorEl(null);

  return { handleClose, handleOpen, open, anchorEl };
};
