import React, { FC, useState } from 'react';
import {
  NewGalleryBottom,
  NewGalleryHeader,
  NewGalleryStackWrapper,
  NewGalleryTitle,
  NewGalleryTop,
} from './NewGallery.styled';
import NewGalleryActiveImage from './NewGalleryActiveImage';
import NewGalleryPreview from './NewGalleryPreview';
import DownloadAllButton from '@/components/button/DownloadAllButton/DownloadAllButton';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import { IconButton } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { getFilePromises } from './utils';

interface INewGalleryBlock {
  galleryTitle: string;
  images: IImage[];
}

const IMG_COUNT = 2;

const NewGalleryBlock: FC<INewGalleryBlock> = ({ galleryTitle, images }) => {
  const srcList = 'https://cdn.pixabay.com/photo/2022/01/25/04/42/bird-6965228_1280.jpg '
    .repeat(10)
    .split(' ')
    .slice(0, 10);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const [startIndex, setStartIndex] = useState(0);

  const handlePreviewImageClick = (index: number) => {
    setActiveImageIndex(index + startIndex);
  };

  const allFilesPromise = getFilePromises(images);

  return (
    <NewGalleryStackWrapper>
      <NewGalleryTop>
        <NewGalleryHeader>
          <NewGalleryTitle>{galleryTitle}</NewGalleryTitle>
          <DownloadAllButton
            disabled={!allFilesPromise.length}
            zipName={galleryTitle ?? 'Архив_фотографий'}
            downloadLinksPromises={allFilesPromise}
            buttonTitle={'Скачать все фото'}
          />
        </NewGalleryHeader>

        <NewGalleryActiveImage
          image={images[activeImageIndex]}
          imgStyle={{ maxHeight: '30svh' }}
        />
      </NewGalleryTop>
      <NewGalleryBottom>
        <IconButton
          sx={{ height: '100%' }}
          size={'medium'}
          disabled={startIndex <= 0}
          onClick={() => setStartIndex(startIndex - 1)}
        >
          <ArrowBackIosNewRoundedIcon fontSize={'medium'} />
        </IconButton>

        {images?.slice(startIndex, startIndex + IMG_COUNT).map((image, index) => (
          <NewGalleryPreview
            key={image?.id}
            image={image}
            onClick={handlePreviewImageClick}
            imageIndex={index}
            isActive={index + startIndex === activeImageIndex}
          />
        ))}

        <IconButton
          sx={{ height: '100%' }}
          size={'medium'}
          disabled={startIndex + IMG_COUNT >= images.length}
          onClick={() => setStartIndex(startIndex + 1)}
        >
          <ArrowForwardIosRoundedIcon fontSize={'medium'} />
        </IconButton>
      </NewGalleryBottom>
    </NewGalleryStackWrapper>
  );
};

export default NewGalleryBlock;
