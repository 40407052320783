import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { FormProvider, useForm } from 'react-hook-form';
import CatalogPuEditor from '@/components/sections/CatalogsEditor/PuEditor/index';

const CatalogPuItem: FC = () => {
  const { puId } = useParams();
  const methods = useForm();

  return (
    <>
      <FormProvider {...methods}>
        <BoxShadow sx={{ p: 3, m: 2, width: 'calc(100% - 32px)' }}>
          {puId && <CatalogPuEditor puId={puId} />}
        </BoxShadow>
      </FormProvider>
    </>
  );
};

export default CatalogPuItem;
