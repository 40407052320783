import { BarShowInfo, ShowInfoCount, ShowInfoItem, TitleShowInfo } from '../InvestStatistic.styled';
import { StatisticBudgetItemShowInfo, TooltipBarChart } from '../../types/types';
import { DYNAMIC_SWITCH_LABELS, formatCount } from '../utils';
import { transformValue } from './utils';
import { useInvestStatisticStore } from '../../store';

interface BudgetStatisticTooltipProps {
  tooltip: TooltipBarChart;
  tooltipRef: React.RefObject<HTMLDivElement>;
}

export const BudgetStatisticTooltip = ({ tooltip, tooltipRef }: BudgetStatisticTooltipProps) => {
  const tooltipData = Array.isArray(tooltip.tooltipData)
    ? { default: tooltip.tooltipData }
    : tooltip.tooltipData && typeof tooltip.tooltipData === 'object'
    ? tooltip.tooltipData
    : {};

  const lengthTitle = Object.values(tooltipData).reduce((maxLength, group) => {
    if (Array.isArray(group)) {
      const groupMaxLength = Math.max(...group.map((itemValue) => itemValue.title.length), 0);
      return Math.max(maxLength, groupMaxLength);
    }
    return maxLength;
  }, 0);

  const countAsMoney = useInvestStatisticStore((state) => state.countAsMoney);

  return (
    <div
      style={{
        visibility: tooltip.display === 'block' ? 'visible' : 'hidden',
        opacity: tooltip.display === 'block' ? 1 : 0,
        position: 'fixed',
        top: tooltip.top,
        left: tooltip.left,
        transition: 'opacity 0.3s ease',
        pointerEvents: 'none',
      }}
      ref={tooltipRef}
    >
      {tooltipData && !!Object.values(tooltipData)?.length && (
        <div className='show-info-bar'>
          {Object.values(tooltipData).map((item, index) => {
            if (item) {
              const max = Math.max(...item.map((it: StatisticBudgetItemShowInfo) => it.count));
              return (
                <>
                  {item.map((itemValue: StatisticBudgetItemShowInfo, idx: number) => {
                    return (
                      <ShowInfoItem
                        style={{ marginTop: index > 0 && idx === 0 ? '15px' : 0 }}
                        key={idx}
                      >
                        <TitleShowInfo width={lengthTitle}>{itemValue.title}</TitleShowInfo>
                        <div style={{ gridColumn: 2 / 4, display: 'flex', gap: '5px' }}>
                          <BarShowInfo
                            width={(transformValue(itemValue.count, max) * 170) / max}
                            background={
                              itemValue.title.toLowerCase() ===
                              DYNAMIC_SWITCH_LABELS.NARTIS.toLowerCase()
                                ? '#56CCF2'
                                : '#959595'
                            }
                          />
                          <ShowInfoCount>
                            <div>
                              {countAsMoney ? formatCount(itemValue.count) : itemValue.count}
                            </div>
                            {itemValue.percentage && <div>{itemValue.percentage}%</div>}
                          </ShowInfoCount>
                        </div>
                      </ShowInfoItem>
                    );
                  })}
                </>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
