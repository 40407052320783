import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';

import { download, downloadPresent, downloadTKO, downloadVRU } from '@/services/ReportService';
import { useCatalog } from '@/hooks/CatalogHook';
import { useActionLog } from '@/hooks/ActionLogHook';

import { IPopup, Popup } from '../Popup';
import { AutocompleteValue } from '../../inputs/AutocompleteValue/AutocompleteValue';
import styles from '../Popup.module.scss';
import { DownloadPopupBackdrop } from './DownloadPopup.styled';

interface Props extends IPopup {
  id: string;
  isTKO?: boolean;
  isTT?: boolean;
  type?: TtTnType;
  hasReplacement?: boolean;
}

export enum TtTnType {
  PILAR,
  ODPU,
}

export const DownloadPopup: React.FC<Props> = (props) => {
  const { fetchCatch } = useActionLog();
  const [loading, setLoading] = useState<boolean>(false);
  const { adpuArray, getAdpuArray, tkoArray, getTKOArray, ttArray, getTTArray } = useCatalog(
    (state) => ({
      adpuArray: state.adpuArray,
      getAdpuArray: state.getAdpuArray,
      getTKOArray: state.getTKOArray,
      tkoArray: state.tkoArray,
      ttArray: state.ttArray,
      getTTArray: state.getTTArray,
    })
  );
  const { onClose, isTT = false, hasReplacement = false, type } = props;
  const methods = useForm();
  const handleSubmitTKO = (data: any) => {
    setLoading(true);
    const code = tkoArray.find((el) => el?.name === data?.shablon)?.code ?? '';
    const fileName = data?.shablon.replace(/_[^_]*комментар[^_]*/g, '');
    downloadTKO(code, props.id)
      .then((res) => {
        const file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        const fileUrl = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = `${fileName}.docx`;
        a.click();
      })
      .catch((res) => fetchCatch(res))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  useEffect(() => {
    isTT && getTTArray();
    if (props.isOpen && !props.isTKO) {
      getAdpuArray();
    } else {
      getTKOArray();
    }
  }, [props.isOpen]);

  const handleSubmitMain = (data: any) => {
    if (props.id == '') {
      fetchCatch({}, 'Не найдена информация о ПУ');
      return;
    }

    let tmpDownload;
    if (hasReplacement) {
      tmpDownload = download;
    } else {
      switch (type) {
        case TtTnType.PILAR:
          tmpDownload = downloadPresent;
          break;
        case TtTnType.ODPU:
          tmpDownload = downloadVRU;
          break;
        default:
          fetchCatch({}, 'Ошибка выгрузки отчета');
          return;
      }
    }
    setLoading(true);
    tmpDownload(data.shablon, props.id)
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = 'отчет.pdf';
        a.click();
      })
      .catch((res) => fetchCatch(res))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Popup {...props}>
      <h3>Выберите шаблон отчета из списка</h3>
      <div>
        <FormProvider {...methods}>
          <AutocompleteValue
            title='Шаблоны'
            fieldName={`shablon`}
            values={isTT ? ttArray : !props.isTKO ? adpuArray : tkoArray.map((el) => el?.name)}
          />
        </FormProvider>
      </div>
      <div className={styles['modal-buttons']}>
        <DownloadPopupBackdrop open={loading}>
          <CircularProgress color='inherit' />
        </DownloadPopupBackdrop>
        <Button
          onClick={methods.handleSubmit(props.isTKO ? handleSubmitTKO : handleSubmitMain)}
          variant='contained'
        >
          Да
        </Button>
        <Button
          onClick={onClose}
          variant='contained'
        >
          Нет
        </Button>
      </div>
    </Popup>
  );
};
