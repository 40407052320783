import React, { useEffect, useState } from 'react';
import { SupplyStatisticWrapper } from './SupplyStatistic.styled';
import SupplyStatisticForm from './SupplyStatisticForm';
import { SupplyBillFilterParam } from '@/dto/SupplyBillServiceDto';
import { useMutation } from 'react-query';
import { SupplyStatisticRequestDTO } from '@/dto/SupplyBillService/SupplyBillServiceRequest';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { SupplyApiGetSupplyStatistic } from '@/services/SupplyBillService';
import { SupplyStatisticResponseDTO } from '@/dto/SupplyBillService/SupplyBillServiceResponse';
import SupplyStatisticDataGrid from './SupplyStatisticDataGrid';
import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import { processFilterParams } from '../SupplyBillBrowse/utils';
import { useSupplyStatisticFormOptions } from './utils';

const SupplyStatistic = () => {
  const { responsablesOptions } = useSupplyStatisticFormOptions();
  const [filterParamsToSend, setFilterParamsToSend] = useState<IQureFilter[]>([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [result, setResult] = useState<SupplyStatisticResponseDTO[]>([]);
  const [totalCount, setTotalCount] = useState(1);
  const updateData = (filt?: SupplyBillFilterParam, withReset?: boolean) => {
    const filterValues = processFilterParams(filterParamsToSend);
    const tmpFilter = filt ? filt : filterValues;
    let offset = page * pageSize;
    if (withReset) {
      offset = 0;
      setPage(0);
    }
    setTotalCount(tmpFilter?.contractorIdList?.length ?? responsablesOptions.length);
    mutGetSupplyStatistic.mutate({ filter: tmpFilter, offset, limit: pageSize });
  };

  const mutGetSupplyStatistic = useMutation<void, any, SupplyStatisticRequestDTO>((filter) => {
    return SupplyApiGetSupplyStatistic(filter)
      .then(({ data }) => setResult(data))
      .catch((error) => fetchCatch(error, 'Ошибка при загрузке статистики'));
  });

  useEffect(() => {
    if (!result.length) {
      return;
    }
    updateData();
  }, [page, pageSize]);

  return (
    <SupplyStatisticWrapper>
      <SupplyStatisticForm
        updateData={updateData}
        filterParamsToSend={filterParamsToSend}
        setFilterParamsToSend={setFilterParamsToSend}
      />
      <SupplyStatisticDataGrid
        page={page}
        data={result}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalCount={totalCount}
        isLoading={mutGetSupplyStatistic.isLoading}
        filterParamsToSend={filterParamsToSend}
      />
    </SupplyStatisticWrapper>
  );
};

export default SupplyStatistic;
