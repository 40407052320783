import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';

export const SAVE_FILTER_KEY = 'TaskBrowseDynamicFilterInputs';
export const convertIntoFilter = (data: any): any => {
  return data.map((gr: any) => {
    return { value: gr.id, label: gr.title };
  });
};

export const defaultArgInput = {
  TAG: {
    argList: [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST, FilterArgEnum.INSTALLED],
    defaultArgNum: 0,
  },
  TEXT: {
    argList: [
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
      FilterArgEnum.CONTAIN,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
      FilterArgEnum.INSTALLED,
    ],
    defaultArgNum: 4,
  },
  TEXT2: {
    argList: [
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
      FilterArgEnum.CONTAIN,
      FilterArgEnum.IN_LIST,
    ],
    defaultArgNum: 4,
  },
  DATE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.RANGE,
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
    ],
    defaultArgNum: 8,
  },
  DOUBLE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.RANGE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
  LONG: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.RANGE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
};
