import { Box, Grid, Stack } from '@mui/material';
import { TextField } from '@/components/fixUI';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TaskRequest, TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { findTaskById } from '@/services/TaskService';
import { useParams } from 'react-router-dom';
import { BoxShadow } from '@/components/ui/BoxShadow';

import { PersistentDrawerRight } from '@/components/ImageDrawer';
import { Panel as PillarPanel } from '@/components/features/taskEditor/pillar/Panel';
import { Panel as AdpuPanel } from '@/components/features/taskEditor/adpu/Panel';
import {
  TaskEditorHeightWrapper,
  TaskEditorImagePannelWrapper,
  TaskEditorScrollWrapper,
  TaskEditorScrollWrapperMain,
} from './TaskEditor.styled';

import { ImagePanel } from '@/components/features/taskEditor/panel/ImagePannel/ImagePanel';
import { OdpuInfoPanel } from '@/components/features/taskEditor/panel/OdpuInfoPanel';
import { OdpuPanel } from '@/components/features/taskEditor/panel/OdpuPanel';
import { ReplacementPanel } from '@/components/features/taskEditor/panel/ReplacementPanel';
import { TaskCommentPanel } from '@/components/features/taskEditor/panel/TaskCommentPanel/TaskCommentPanel';
import {
  TaskControlPanel,
  screenPosition,
} from '@/components/features/taskEditor/panel/TaskControlPanel/TaskControlPanel';
import { TaskInfoPanel } from '@/components/features/taskEditor/panel/TaskInfoPanel';
import { useStatus } from '@/components/features/taskEditor/panel/store';
import { TaskSubscribersPanel } from '@/components/features/taskEditor/panel/TaskSubscribersPanel';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { Tags } from '@/components/Tags/Tags';
import { useMetaTitle } from '@/hooks/useMetaTitle';

import TaskHeaderPanel from '@/components/features/taskEditor/panel/TaskHeaderPanel';
import LowLevelErrorBoundary from '@/components/common/ErrorBoundary/LowLevelErrorBoundary';
import { useGalleryData } from '@/components/Gallery/store';

export const TaskEditor = () => {
  const onlyShow = useTaskEditorOnlyShow();
  const methods = useForm<TaskRequest>();
  const { taskId } = useParams();
  const { fetchCatch } = useActionLog();

  const [taskResponse, setTaskResponse] = useState<TaskResponse>();

  const [reloadVar, setReloadVar] = useState(false);
  const reloadThis = () => {
    setReloadVar(!reloadVar);
  };
  const { setTaskStatus } = useStatus((state) => ({
    setTaskStatus: state.setTaskStatus,
  }));
  const { setDuplicateIdList } = useGalleryData((state) => ({
    setDuplicateIdList: state.setDuplicateIdList,
  }));

  useEffect(() => {
    if (taskId) {
      findTaskById(taskId)
        .then(({ data }) => {
          setTaskResponse(data);
          setTaskStatus(data.status);
        })
        .catch((err) => fetchCatch(err, 'Заявка не найдена'));
    }
  }, [taskId, reloadVar]);

  useMetaTitle(`Заявка ${taskResponse?.number ?? ''} - редактирование`);

  return (
    <>
      <FormProvider {...methods}>
        <Box m={3}>
          <Grid
            container
            spacing={2}
            sx={{ alignItems: 'flex-start' }}
          >
            <Grid
              item
              xs={12}
            >
              <LowLevelErrorBoundary>
                <BoxShadow sx={{ position: 'relative' }}>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={21 / 2}
                    >
                      <TaskHeaderPanel taskResponse={taskResponse!} />
                    </Grid>
                    <Grid
                      item
                      xs={3 / 2}
                    >
                      <TaskControlPanel
                        reload={reloadThis}
                        positionOnScreen={screenPosition.TOP}
                        taskResponse={taskResponse}
                        handleChangeTask={(taskResponse) => setTaskResponse(taskResponse)}
                      />
                    </Grid>
                  </Grid>
                </BoxShadow>
              </LowLevelErrorBoundary>
            </Grid>
            <TaskEditorHeightWrapper>
              <TaskEditorScrollWrapper
                item
                xs={5 / 2}
              >
                <Stack spacing={2}>
                  {taskResponse?.id && (
                    <TaskCommentPanel
                      callCenterComments={taskResponse?.callCenterComments}
                      taskId={taskResponse?.id}
                    />
                  )}
                  <BoxShadow>
                    {taskResponse?.type === 'SMR_ODPU' ||
                    taskResponse?.type === 'SMR_ODPU_MOEK' ||
                    taskResponse?.type === 'CLAIM_ODPU' ||
                    taskResponse?.type === 'SMR_ODPU_ENTITY' ? (
                      <OdpuInfoPanel taskResponse={taskResponse!} />
                    ) : (
                      <TaskInfoPanel taskResponse={taskResponse!} />
                    )}
                    <div style={{ marginTop: 16 }}>
                      <TaskControlPanel
                        reload={reloadThis}
                        positionOnScreen={screenPosition.LEFT}
                        taskResponse={taskResponse}
                        handleChangeTask={(taskResponse) => setTaskResponse(taskResponse)}
                      />
                    </div>
                  </BoxShadow>
                  {taskResponse?.id && <Tags taskId={taskResponse.id} />}
                  {taskResponse?.id && <TaskSubscribersPanel taskId={taskResponse?.id} />}
                  {taskResponse?.status === 'DISPUT' && (
                    <BoxShadow>
                      <TextField
                        multiline
                        disabled
                        label='Сообщение об ошибке'
                        value={taskResponse?.errorMessage}
                      />
                    </BoxShadow>
                  )}
                </Stack>
              </TaskEditorScrollWrapper>
              <TaskEditorScrollWrapperMain
                item
                xs={19 / 2}
              >
                {taskResponse?.type === 'SMR_ODPU' ||
                taskResponse?.type === 'SMR_ODPU_MOEK' ||
                taskResponse?.type === 'CLAIM_ODPU' ||
                taskResponse?.type === 'SMR_ODPU_ENTITY' ? (
                  <OdpuPanel
                    taskResponse={taskResponse}
                    reloadParent={reloadThis}
                    reloadParentFlag={reloadVar}
                  />
                ) : taskResponse?.type === 'PPO_TP' ? (
                  !onlyShow && (
                    <PillarPanel
                      taskResponse={taskResponse!}
                      reloadParent={reloadThis}
                      reloadParentFlag={reloadVar}
                    />
                  )
                ) : taskResponse?.type === 'SIGNE_ADPU' ? (
                  !onlyShow && (
                    <AdpuPanel
                      taskResponse={taskResponse!}
                      reloadParent={reloadThis}
                      reloadParentFlag={reloadVar}
                    />
                  )
                ) : (
                  <ReplacementPanel
                    taskResponse={taskResponse!}
                    reloadParent={reloadThis}
                    reloadParentFlag={reloadVar}
                  />
                )}
                {/*<DeliveryPointPanel />*/}
              </TaskEditorScrollWrapperMain>
              <TaskEditorImagePannelWrapper
                item
                xs={3}
              >
                <Stack
                  spacing={2}
                  height={'100%'}
                >
                  <BoxShadow
                    sx={{
                      overflow: 'auto',
                    }}
                  >
                    <ImagePanel />
                  </BoxShadow>
                  <PersistentDrawerRight />
                </Stack>
              </TaskEditorImagePannelWrapper>
            </TaskEditorHeightWrapper>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
};
