import React, { useEffect, useState } from 'react';
import {
  FileInputContainer,
  FileInputLabelText,
  InputFileErrorText,
  InputFileItem,
  InputFilesWrapper,
  InputFileProgressWrapper,
  InputFileErrorInfoIconWrapper,
} from './FileInput.styled';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { Button, CircularProgress, IconButton, Menu, Tooltip, Typography } from '@mui/material';
import {
  calculateFileSize,
  DEFAULT_ACCEPTED_EXTENSIONS,
  DEFAULT_MAX_FILES_AMOUNT,
  DEFAULT_MAX_FILE_SIZE,
  FileInputFiles,
  getFileExtension,
  getFileName,
  FileInputFile,
} from './utils';
import { UseFormRegisterReturn, UseFormSetValue, useFormContext } from 'react-hook-form';
import { BaseUI } from '@/types/ui';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useAnchorElement } from '@/hooks/useAnchorElement';
import { BackendFile } from '@/dto/taskmap/Dto';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ApiResponse } from '@/types/api';
import { useActionLog } from '@/hooks/ActionLogHook';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileSaver from 'file-saver';
import FileDeleteModal from './FileDeleteModal';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

export interface FileInputProps extends BaseUI {
  maxFileSize?: number;
  maxFilesAmount?: number;
  register: UseFormRegisterReturn;
  setFormFiles: UseFormSetValue<any>;
  disableEdit?: boolean;
  filesFromBackend?: BackendFile[] | null;
  filesFromBackendGetFunction?: (fileId: string | number) => ApiResponse<Blob>;
  acceptedExtensions?: string;
  resetFlag?: boolean;
  errorText?: string;
  deleteFileFromBack?: (fileId: string) => Promise<void>;
  watchFiles?: (files: any) => void;
}

const FileInput = ({
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  acceptedExtensions = DEFAULT_ACCEPTED_EXTENSIONS,
  maxFilesAmount = DEFAULT_MAX_FILES_AMOUNT,
  register,
  setFormFiles,
  className,
  disableEdit,
  filesFromBackend,
  filesFromBackendGetFunction,
  deleteFileFromBack,
  resetFlag,
  watchFiles,
}: FileInputProps) => {
  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();
  const { fetchCatch } = useActionLog();
  const { formState } = useFormContext();

  const [deleteModal, setDeleteModal] = useState<null | {
    file: FileInputFile;
    index: number;
  }>(null);

  const [files, setFiles] = useState<FileInputFiles>([]);
  const [backendDeletionPending, setBackendDeletionPending] = useState(false);
  const [error, setError] = useState<JSX.Element | null>(null);
  const [pendingFiles, setPendingFiles] = useState<string[]>([]);

  const handleArrayFilter = (file: File) => {
    const fileSize = calculateFileSize(file.size);
    const fileExtension = getFileExtension(file.name.toLowerCase());

    if (fileExtension && acceptedExtensions.includes(fileExtension)) {
      if (fileSize <= maxFileSize) {
        return true;
      } else {
        setError(<span>Размер файлов ограничен {calculateFileSize(maxFileSize)}MB</span>);
        return false;
      }
    } else {
      setError(
        <Tooltip title={`Допустимые расширения:${acceptedExtensions}`}>
          <span>
            Некорректное расширение файла {fileExtension}
            <InputFileErrorInfoIconWrapper>
              <InfoOutlinedIcon
                fontSize='inherit'
                color='error'
              />
            </InputFileErrorInfoIconWrapper>
          </span>
        </Tooltip>
      );
      return false;
    }
  };

  const changeHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    if (!target.files) return;

    setError(null);

    const filteredInputFiles = Array.from(target.files).filter(handleArrayFilter);
    const allFiles = [...files, ...filteredInputFiles];
    const uniqueFiles = [...new Map(allFiles.map((item) => [item.name, item])).values()];

    if (uniqueFiles.length <= maxFilesAmount) {
      setFiles(uniqueFiles);
    } else {
      const acceptedAmountFiles = uniqueFiles.slice(0, maxFilesAmount);
      setFiles(acceptedAmountFiles);
      setError(<span>Максимальное количество файлов: {maxFilesAmount}</span>);
    }
  };

  const deleteHandler = (index: number): void => {
    setFiles(files.filter((file) => file != files[index]));
    setError(null);
  };

  //фикс выбора того же файла после удаления
  const resetInputValue = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = '';
  };

  useEffect(() => {
    setFormFiles(register.name, files);
  }, [files, setFormFiles]);

  useEffect(() => {
    if (filesFromBackend) setFiles(filesFromBackend);
  }, [filesFromBackend]);

  useEffect(() => {
    watchFiles && watchFiles(files);
    if (!files.length) {
      handleClose();
    }
  }, [files]);

  useEffect(() => {
    if (resetFlag === undefined) return;
    setFiles([]);
    setError(null);
  }, [resetFlag]);

  useEffect(() => {
    setError(<span>{formState?.errors?.files?.message}</span>);
  }, [formState?.errors?.files]);

  const downloadFile = (fileId: string, fileName: string) => {
    setPendingFiles((prev) => [...prev, fileId]);
    if (filesFromBackendGetFunction) {
      filesFromBackendGetFunction(fileId)
        .then(({ data }) => FileSaver.saveAs(data, fileName))
        .catch((err) => fetchCatch(err, 'Ошибка получения файла'))
        .finally(() => {
          setPendingFiles(pendingFiles.filter((pendingFileId) => pendingFileId !== fileId));
        });
    }
  };

  const deleteFile = () => {
    if (!deleteModal) return;

    const { file, index } = deleteModal;
    if (deleteFileFromBack && 'id' in file) {
      setBackendDeletionPending(true);
      deleteFileFromBack(file?.id).finally(() => {
        setBackendDeletionPending(false);
        setDeleteModal(null);
      });
      return;
    }
    deleteHandler(index);
    setDeleteModal(null);
  };

  return (
    <>
      <FileInputContainer className={className}>
        <label>
          <input
            type='file'
            id='fileInput'
            hidden
            multiple
            onInput={changeHandler}
            onClick={resetInputValue}
            disabled={disableEdit}
            accept={acceptedExtensions}
          />

          <Button
            startIcon={
              maxFilesAmount == 1 ? (
                <AttachFileOutlinedIcon color='info' />
              ) : (
                <PostAddOutlinedIcon />
              )
            }
            component='span'
            disabled={disableEdit}
          >
            <Typography
              variant='subtitle2'
              sx={{ mt: '0.2rem' }}
            >
              ПРИКРЕПИТЬ ФАЙЛ
            </Typography>
          </Button>
        </label>
        {error && <InputFileErrorText>{error}</InputFileErrorText>}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <InputFilesWrapper>
            {files &&
              files.map((file, index) => (
                <InputFileItem key={file.name}>
                  <Typography
                    variant='body1'
                    sx={{ fontWeight: 500 }}
                  >
                    {getFileName(file.name)}
                  </Typography>
                  <InputFileItem>
                    {'id' in file && (
                      <>
                        {pendingFiles.includes(file.id) ? (
                          <InputFileProgressWrapper>
                            <CircularProgress size={20} />
                          </InputFileProgressWrapper>
                        ) : (
                          <IconButton onClick={() => downloadFile(file.id, file.name)}>
                            <FileDownloadIcon color='info' />
                          </IconButton>
                        )}
                      </>
                    )}

                    {!disableEdit && (
                      <IconButton
                        disabled={backendDeletionPending}
                        onClick={() => {
                          setDeleteModal({ file, index });
                        }}
                      >
                        <DeleteOutlineOutlinedIcon color='error' />
                      </IconButton>
                    )}
                  </InputFileItem>
                </InputFileItem>
              ))}
          </InputFilesWrapper>
        </Menu>

        <FileInputLabelText
          variant='button'
          disabled={!files.length}
          onClick={handleOpen}
        >
          Добавленые файлы: {files.length}
        </FileInputLabelText>
      </FileInputContainer>

      <FileDeleteModal
        open={!!deleteModal?.file}
        onClose={() => setDeleteModal(null)}
        onSuccess={deleteFile}
        fileName={deleteModal?.file?.name}
        backendDeletionPending={backendDeletionPending}
      />
    </>
  );
};

export default FileInput;
