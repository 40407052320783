import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { NewGalleryPreviewImage, NewGalleryPreviewItem } from './NewGallery.styled';
import { getImageUrl, getUrlHeaders } from './utils';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';

export interface NewGalleryPreviewProps {
  image: IImage;
  onClick: (imageIndex: number, groupIndex?: number) => void;
  imageIndex: number;
  groupIndex?: number;
  onContentTypeLoaded?: (
    imageIndex: number,
    groupIndex: number,
    contentType: string | null
  ) => void;
  isActive: boolean;
  scrollIntoViewOnClick?: boolean;
}

const NewGalleryPreview = ({
  image,
  imageIndex,
  groupIndex = 0,
  onClick,
  onContentTypeLoaded,
  isActive,
  scrollIntoViewOnClick,
}: NewGalleryPreviewProps) => {
  const [contentType, setContentType] = useState('');
  const refWrapper = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (image?.url) {
      // Выполняем HEAD-запрос, чтобы получить заголовки без загрузки всего файла
      getUrlHeaders(image.url)
        .then((response) => {
          const type = response.headers['content-type'];
          setContentType(type);
          if (onContentTypeLoaded) {
            onContentTypeLoaded(imageIndex, groupIndex, type);
          }
        })
        .catch((err) => {
          console.error('HEAD request error:', err);
          if (onContentTypeLoaded) {
            onContentTypeLoaded(imageIndex, groupIndex, null);
          }
        });
    }
  }, [image?.url]);

  useEffect(() => {
    if (isActive && scrollIntoViewOnClick) {
      refWrapper.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isActive, scrollIntoViewOnClick]);

  return (
    <NewGalleryPreviewItem
      ref={refWrapper}
      onClick={() => onClick(imageIndex, groupIndex)}
      active={isActive}
    >
      <NewGalleryPreviewImage
        src={getImageUrl(image?.url, contentType)}
        srcSet={getImageUrl(image?.url, contentType)}
        loading='lazy'
        height='100%'
        width='100%'
      />
    </NewGalleryPreviewItem>
  );
};

export default React.memo(NewGalleryPreview);
