import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';
import { FormPU } from './FormPU';
import { FormTT } from './FormTT';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { FormPUNew } from './FormPUNew';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import {
  createTaskByDeliveryPointId,
  deleteDeliveryPointById,
  saveDeliveryPointById,
} from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { Link } from 'react-router-dom';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { converStringBoolenToBoolen, getSMRText, isChangeInput } from './utils';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditOffIcon from '@mui/icons-material/EditOff';
import { color } from '@/styles/mixins';

import { usePillar } from '@/components/maps/ppoMap/resCard/pillarStore';
import { IdPopup } from '../panel/IdPopup';
import { FormPUInput } from './FormInput';
import { SMRBox } from './index.styled';
import { useCoords } from '@/components/maps/ppoMap/coordsStore';
import { GeoTransStation } from '@/components/maps/ppoMap/dto';
import { useDeliveryPoints } from '@/components/maps/ppoMap/placemark/delivery-point/store';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import { findAllDeliveryPoint } from '@/components/maps/ppoMap/service';
import { useGallery } from '../../NewGallery/utils';

interface DeliveryPointItemProps {
  id: string;
  data: DeliveryPointResponse;
  disabledAll?: boolean;
}

export const DeliveryPointItem: FC<DeliveryPointItemProps> = ({
  id,
  data,
  disabledAll = false,
}) => {
  const { openGallery } = useGallery();
  const [isOpenDel, setIsOpenDel] = useState<boolean>(false);
  const [isLodingDel, setIsLodingDel] = useState<boolean>(false);

  const { fetchCatch, addActionLog } = useActionLog();
  const { register, handleSubmit, getValues, setValue } = useFormContext();
  const { geoTransStation, setGeoTransStation, tmpTaskId } = usePpoMap();

  const feeders = geoTransStation?.feeders;

  const { setTmpDeliveryPoint } = useDeliveryPoints();

  const onSave = (e: any) => {
    id &&
      saveDeliveryPointById(
        {
          ...e[id],
          isChangeNeeded: converStringBoolenToBoolen(e[id].isChangeNeeded),
          isIkkExists: converStringBoolenToBoolen(e[id].isIkkExists),
          isNeededSeparateInput: converStringBoolenToBoolen(e[id].isNeededSeparateInput),
          isReplacementInput: converStringBoolenToBoolen(e[id].isReplacementInput),
        },
        id
      )
        .then((res) => {
          setTmpDeliveryPoint(id, res.data);
          addActionLog(ActionLogType.SUCCESS, 'Сохранено');
          tmpTaskId &&
            findAllDeliveryPoint(tmpTaskId)
              .then(({ data }) => setGeoTransStation(data))
              .catch((err) => fetchCatch(err, 'Ошибка при загрузке точек поставки'));
        })
        .catch((err) => fetchCatch(err, 'Не сохранено'));
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const createTask = () => {
    createTaskByDeliveryPointId(id)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Заявка создана');
      })
      .catch((err) => fetchCatch(err, 'Заявка не создана'));
  };
  const deleteDeliveryPoint = () => {
    setIsLodingDel(true);
    deleteDeliveryPointById(id)
      .then(() => {
        const newDeliveryPoints = geoTransStation?.deliveryPoints?.filter((el) => el.id !== id);
        const newData = {
          ...geoTransStation,
          deliveryPoints: newDeliveryPoints,
        } as GeoTransStation;
        addActionLog(ActionLogType.SUCCESS, 'Точка удалена');
        setGeoTransStation(newData);
      })
      .catch((err) => fetchCatch(err, 'Точка не найдена'))
      .finally(() => {
        setIsLodingDel(false);
        setIsOpenDel(false);
      });
  };

  const str = id;

  const onCopy = () => {
    const latitude = getValues(`${str}.latitudeY`);
    const longitude = getValues(`${str}.longitudeX`);
    navigator.clipboard
      .writeText(`${latitude}, ${longitude}`)
      .then(() => addActionLog(ActionLogType.SUCCESS, `Координаты скопированы`))
      .catch((err) => fetchCatch(err, 'Something went wrong'));
  };

  const feederOptions = useMemo(
    () => new Map(feeders?.map((elem) => [elem.id, elem.num])),
    [feeders]
  );

  const onOpenGalleryClick = () => {
    openGallery({ images: data.imageList, title: 'Точка поставки' });
  };

  const { isEditMode, changeMode, latitude, longitude } = useCoords();
  const { isEditPillarMode, changeModePillar, pillar } = usePillar();

  useEffect(() => {
    if (!isEditMode && latitude && longitude) {
      setValue(`${str}.latitudeY`, latitude?.toFixed(6));
      setValue(`${str}.longitudeX`, longitude?.toFixed(6));
    }
  }, [isEditMode]);

  useEffect(() => {
    setValue(`${str}.ppoComment`, data?.ppoComment);
    setValue(`${str}.isChangeNeeded`, `${data.isChangeNeeded}`);
  }, [data]);

  return (
    <Box
      onDoubleClick={handleClick}
      sx={{ position: 'relative' }}
    >
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography
            variant={'h6'}
            sx={{ flex: '1 1 0' }}
          >
            Точка Поставки
          </Typography>
          <IconButton
            sx={{ mr: 2 }}
            disabled={!data.imageList || data.imageList.length === 0}
            onClick={onOpenGalleryClick}
          >
            <ImageSearchRoundedIcon htmlColor={color('muiBlue')} />
          </IconButton>
          <IdPopup>
            <div>
              <CopyButton textToCopy={data.id} />
              id Точки Поставки: {data.id}
            </div>
          </IdPopup>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='latitudeY'
            label='Широта'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${str}.latitudeY`, {
              value: data?.latitudeY,
            })}
          />
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='longitudeX'
            label='Долгота'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${str}.longitudeX`, {
              value: data?.longitudeX,
            })}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <IconButton onClick={() => changeMode()}>
            {isEditMode ? (
              <LocationOffRoundedIcon htmlColor={color('muiBlue')} />
            ) : (
              <EditLocationRoundedIcon htmlColor={color('muiBlue')} />
            )}
          </IconButton>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <AutocompleteValue
            title='Фидер 0,4 кВ'
            sx={{ width: '100%' }}
            defaultValue={data?.feeder?.id}
            fieldName={`${str}.feeder.id`}
            values={feederOptions}
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextField
            id='trns-number'
            label='Опора'
            variant='standard'
            disabled
            sx={{ width: '100%' }}
            value={data?.pillar?.number}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <IconButton onClick={() => changeModePillar()}>
            {isEditPillarMode ? (
              <BorderColorIcon htmlColor={color('muiBlue')} />
            ) : (
              <EditOffIcon htmlColor={color('muiBlue')} />
            )}
          </IconButton>
        </Grid>
        <FormPU
          keyStr={str}
          meter={data}
          // disabledAll
        />
        <FormPUNew
          keyStr={str}
          meter={data}
          // disabledAll
        />
        <FormPUInput
          keyStr={str}
          meter={data}
          // disabledAll
        />

        <Grid
          item
          xs={12}
        />

        <FormTT
          keyStr={`${str}.ppoStolbiTransformator`}
          meter={data?.ppoStolbiTransformator}
          // disabledAll
        />
        <Grid
          item
          xs={12}
        >
          <TextField
            id='comment'
            label='Комментарий'
            maxRows={3}
            minRows={3}
            multiline
            variant='outlined'
            sx={{ width: '100%' }}
            {...register(`${str}.ppoComment`, {
              value: data?.ppoComment,
            })}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <AutocompleteValue
            title='На данной точке монтаж планируется'
            sx={{ width: '100%' }}
            fieldName={`${str}.isChangeNeeded`}
            defaultValue={`${data.isChangeNeeded}`}
            values={isChangeInput}
            disabled={!!data.smrTaskId}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <SMRBox>
            <div>
              СМР:{' '}
              {getSMRText(
                data.smrTaskId,
                data.meterChangeIdList,
                data.tkoIdList,
                data.isChangeNeeded
              )}
            </div>
            {data?.smrTaskId || (data?.tkoIdList && data.tkoIdList.length > 0) ? (
              <Button>
                <Link
                  to={`/task/edit/${data.smrTaskId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  target={'_blank'}
                >
                  {'Открыть заявку СМР'}
                </Link>
              </Button>
            ) : (
              <Button onClick={createTask}>{'Создать заявку СМР'}</Button>
            )}
          </SMRBox>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Button onClick={() => setIsOpenDel(true)}>Удалить точку поставки</Button>
          <SaveButton onSave={handleSubmit(onSave)} />
        </Grid>
      </Grid>
      <AccessPopup
        isOpen={isOpenDel}
        isLoading={isLodingDel}
        onClose={() => setIsOpenDel(false)}
        onSuccess={deleteDeliveryPoint}
        headerText={'ВНИМАНИЕ! УДАЛЕНИЕ ТОЧКИ ПОСТАВКИ'}
        successText={'удалить точку поставки'}
        btnVariant={'text'}
        titleText={
          'ВЫ УВЕРЕНЫ ЧТО ХОТИТЕ УДАЛИТЬ ЭТУ ТОЧКУ ПОСТАВКИ? ВСЕ СВЯЗАНЫЕ ДАННЫЕ, ВКЛЮЧАЯ УЖЕ ПРОВЕДЕННЫЕ МОНТАЖИ, БУДУТ УДАЛЕНЫ БЕЗВОЗВРАТНО. ПОЖАЛУЙСТА УБЕДИТЕСЬ В НЕОБХОДИМОСТИ ЭТОГО ДЕЙСТВИЯ.'
        }
      />
    </Box>
  );
};
