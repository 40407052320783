import {
  PresentEquipments,
  PresentPu,
  PresentTransformer,
  PresentTransformerVoltage,
} from '@/dto/taskmap/task/TaskResultDto';

export const lookUpExistingEquipment = {
  presentPu: 'Существующий ПУ',
  presentTransformer: 'Существующий ТТ',
  presentTransformerVoltage: 'Существующий ТН',
};

export function filterObjectsWithValues(
  presentEquipments: PresentEquipments
): { key: string; value: PresentPu | PresentTransformer | PresentTransformerVoltage }[] {
  return Object.entries(presentEquipments).reduce((result, [key, value]) => {
    if (
      value &&
      typeof value === 'object' &&
      Object.values(value).some((v) => v !== null && v !== undefined)
    ) {
      result.push({ key, value });
    }
    return result;
  }, [] as { key: string; value: PresentPu | PresentTransformer | PresentTransformerVoltage }[]);
}

export type KeyString = keyof typeof lookUpExistingEquipment;
export const getKtnOrKttValue = (
  ktnOrKtt: string | { n1?: string; n2?: string } | undefined
): string => {
  if (typeof ktnOrKtt === 'string') {
    return ktnOrKtt;
  } else if (ktnOrKtt?.n1 && ktnOrKtt?.n2) {
    return `${ktnOrKtt.n1}/${ktnOrKtt.n2}`;
  }
  return '';
};

export const getKttOrKtnValueFromObj = (
  value: PresentTransformer | PresentTransformerVoltage | PresentPu
): string | null => {
  if ('ktt' in value && typeof value.ktt === 'object' && value.ktt?.n1 && value.ktt?.n2) {
    return `${value.ktt.n1}/${value.ktt.n2}`;
  }
  if ('ktn' in value && typeof value.ktn === 'object' && value.ktn?.n1 && value.ktn?.n2) {
    return `${value.ktn.n1}/${value.ktn.n2}`;
  }

  return null;
};
