import { color } from '@/styles/mixins';
import { styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { font } from '@/styles/mixins';

export const NavWrapper = styled('nav')({
  marginTop: 6,
  color: color('muiBlue'),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 60,
});

export const NavLink = styled(Link)`
  ${font('text6')}
  width:100%;
  color: ${color('muiBlue')};
  padding: 10px 16px;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${color('errorGrayNavbar')}!important;
  }
`;

export const NavLinkMain = styled(Link)`
  ${font('text6')}
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${color('errorGrayNavbar')}!important;
  }
`;

export const NavLinkOut = styled('a')`
  ${font('text6')}
  padding: 10px 16px;
  width: 100%;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${color('errorGrayNavbar')};
  }
`;

export const NavLinkMainOut = styled('a')`
  ${font('text6')}
  color: ${color('muiBlue')};
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${color('errorGrayNavbar')};
  }
`;

export const NavLinkMainWrapper = styled('div')`
  ${font('text6')}
  color: ${color('muiBlue')};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${color('errorGrayNavbar')};
  }
`;

export const NavExitWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: 40,
});

export const Arrow = styled('div')({
  display: 'inline-block',
  marginLeft: '.255em',
  verticalAlign: '.255em',
  borderTop: '.3em solid',
  borderRight: '.3em solid transparent',
  borderBottom: 0,
  borderLeft: '.3em solid transparent',
});
export const TextActionLogMain = styled(Typography)`
  ${font('text4')}
`;
