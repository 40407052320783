import { MeterChangeReworkRow } from '@/components/sections/MeterChangeRework/MeterChangeRework.styled';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color, font } from '@/styles/mixins';
import { FormControlLabel, IconButton, styled, Typography } from '@mui/material';
import { Tooltip } from '../types/types';

export const ChartWrapper = styled(BoxShadow)({
  padding: '27px',
  paddingBottom: '15px',
  marginTop: '5px',
  marginBottom: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 'calc(100vh - 60px)',
  display: 'flex',
  flexDirection: 'column',
});

export const DoughnutWrapper = styled('div')({
  position: 'relative',
  width: '900px',
  height: '850px',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
});

export const BarWrapper = styled('div')({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  overflowX: 'auto',
});

export const ButtonClose = styled(IconButton)({
  marginLeft: 'auto',
});

export const WrapperFilters = styled(MeterChangeReworkRow)`
  display: flex;
  width: 100%;
  padding-right: 37;
`;

export const BudgetStatisticBarWrapper = styled(BoxShadow)({
  padding: '25px',
  marginTop: '10px',
  marginBottom: '5px',
});

interface ShowInfoControllLabelProps {
  active: boolean;
}

export const ShowInfoControllLabel = styled(FormControlLabel)<ShowInfoControllLabelProps>`
  margin-left: 10px;

  .MuiFormControlLabel-label {
    user-select: none;
    text-transform: uppercase;
    ${font('label')};
    color: ${(props) => (props.active ? '#1976D2' : color('grayLabel'))};
    font-weight: 500;
  }
`;
interface BarShowInfoProps {
  width: number;
  background: string;
}

interface TitleShowInfoProps {
  width: number;
}

interface LabelSwitchProps {
  checked: boolean;
}

export const ShowInfoItem = styled('div')({
  display: 'grid',
  gap: '5px',
  height: '17px',
  gridTemplateColumns: '1fr 2fr',
});

export const TitleShowInfo = styled('div')<TitleShowInfoProps>`
  justify-content: end;
  align-items: center;
  display: flex;

  font-weight: 500;
  text-transform: uppercase;
  ${font('mainText')};
  grid-column: 1/2;
  height: 17px;
`;

export const BarShowInfo = styled('div')<BarShowInfoProps>(({ width, background }) => ({
  width: `${width}px`,
  background: background,
  height: '17px',
}));

export const WrapperSwitch = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const LabelSwitch = styled(Typography)<LabelSwitchProps>`
  text-transform: uppercase;
  ${font('label')}
  color: ${(props) => (props.checked ? '#1976D2' : color('grayLabel'))};
  font-weight: 500;
`;

export const ShowInfoCount = styled('div')`
  display: flex;
  gap: 20px;
  align-items: center;
  ${font('mainText')};
  height: 17px;
`;

export const InvestStatisticTitle = styled('p')`
  ${font('title2')}
  margin: 0;
`;

interface TooltipProps {
  tooltip: Tooltip;
}

export const TooltipWrapper = styled('div')<TooltipProps>(({ tooltip }) => ({
  visibility: tooltip.display === 'block' ? 'visible' : 'hidden',
  opacity: tooltip.display === 'block' ? 1 : 0,
  position: 'fixed',
  top: tooltip.top,
  left: tooltip.left,
  transition: 'opacity 0.3s ease',
  pointerEvents: 'none',
  borderRadius: '10px',
  border: '1px solid #2C3E50',
}));

export const ChartSvg = styled('svg')`
  text {
    ${font('label')};
    font-weight: 600;
  }
  .tick {
    text {
      ${font('labelAxis')}
    }
  }
  text.value-label {
    ${font('labelAxis')}
  }
`;
