import React, { FC } from 'react';
import { Colors } from '@/styles/colors';
import moment from 'moment';
import { RegistryADPUFolderResponse } from '@/dto/RegistryADPUDto';
import { RegistryADPURowDiv } from '@/components/RegistryADPU/RegistryADPU.styled';
import ChronologyItem from '@/components/features/taskEditor/item/chronology/ChronologyItem';

const FolderItem: FC<
  RegistryADPUFolderResponse & {
    borderColor?: Colors;
    onClick?: () => void;
  }
> = ({ signingDate, number, borderColor, count, onClick }) => {
  return (
    <>
      <RegistryADPURowDiv
        isActive
        borderColor={borderColor}
        onClick={onClick}
      >
        <ChronologyItem
          label={'№ реестра'}
          value={number}
        />
        <ChronologyItem
          label={'Загружено кем'}
          value={'Автор загрузки'}
        />
        <ChronologyItem
          label={'Передано в РЭС'}
          value={signingDate ? moment(signingDate).format('DD.MM.YYYY') : '-'}
        />
        <ChronologyItem
          label={'Файлы АДПУ'}
          value={`${count}`}
        />
      </RegistryADPURowDiv>
    </>
  );
};

export default FolderItem;
