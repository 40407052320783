import { HOST } from './YodaRestService';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from './helpers';
import {
  EquipmentEditorResponse,
  KooBrowseResponse,
  MeterBrowseResponse,
  PuBrowseResponse,
  // PuEditorRequest,
  PuEditorResponse,
  SimBrowseResponse,
  StampBrowseResponse,
  TaskNumberForEquipmentResponse,
} from '@/dto/CatalogsDto';
import { AxiosResponse } from 'axios';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';
import { ApiResponse } from '@/types/api';

export const getBrowse = (catalogType: CatalogTypes) => {
  switch (catalogType) {
    // case CatalogTypes.METER:
    //   return { filter: getMeterBrowse, count: getMeterBrowseCount };
    case CatalogTypes.STAMP:
      return { filter: getStampBrowse, count: getStampBrowseCount };
    case CatalogTypes.KOO:
      return { filter: getKooBrowse, count: getKooBrowseCount };
    case CatalogTypes.SIM:
      return { filter: getSimBrowse, count: getSimBrowseCount };
    case CatalogTypes.PU:
      return { filter: getPUBrowse, count: getPUBrowseCount };
  }
};

export async function getMeterBrowse(params: {
  page: number;
  filters?: any;
  pageSize: number;
}): Promise<AxiosResponse<MeterBrowseResponse[], any>> {
  const { page, pageSize, filters = {} } = params;
  const url = HOST + `/rest/meter/find?offset=${page * pageSize}&limit=${pageSize}`;
  return axiosPost(url, filters);
}

export async function getMeterBrowseCount(params: {
  filters?: any;
}): Promise<AxiosResponse<number, any>> {
  const { filters = {} } = params;
  const url = HOST + `/rest/meter/find/count`;
  return axiosPost(url, filters);
}

export async function getStampBrowse(params: {
  page: number;
  filters?: any;
  pageSize: number;
}): Promise<AxiosResponse<StampBrowseResponse[], any>> {
  const { page, pageSize, filters = {} } = params;
  const url = HOST + `/rest/stamp/find?offset=${page * pageSize}&limit=${pageSize}`;
  return axiosPost(url, filters);
}

export async function getStampBrowseCount(params: {
  filters?: any;
}): Promise<AxiosResponse<number, any>> {
  const { filters = {} } = params;
  const url = HOST + `/rest/stamp/find/count`;
  return axiosPost(url, filters);
}

export async function getKooBrowse(params: {
  page: number;
  filters?: any;
  pageSize: number;
}): Promise<AxiosResponse<KooBrowseResponse[], any>> {
  const { page, pageSize, filters = {} } = params;
  const url = HOST + `/rest/network-equipment/find?offset=${page * pageSize}&limit=${pageSize}`;
  return axiosPost(url, filters);
}

export async function getKooBrowseCount(params: {
  filters?: any;
}): Promise<AxiosResponse<number, any>> {
  const { filters = {} } = params;
  const url = HOST + `/rest/network-equipment/count`;
  return axiosPost(url, filters);
}
export async function getSimBrowse(params: {
  page: number;
  filters?: any;
  pageSize: number;
}): Promise<AxiosResponse<SimBrowseResponse[], any>> {
  const { page, pageSize, filters = {} } = params;
  const url = HOST + `/rest/sim/find?offset=${page * pageSize}&limit=${pageSize}`;
  return axiosPost(url, filters);
}

export async function getSimBrowseCount(params: {
  filters?: any;
}): Promise<AxiosResponse<number, any>> {
  const { filters = {} } = params;
  const url = HOST + `/rest/sim/find/count`;
  return axiosPost(url, filters);
}

export async function getPUBrowse(params: {
  page: number;
  filters?: any;
  pageSize: number;
}): Promise<AxiosResponse<PuBrowseResponse[], any>> {
  const { page, pageSize, filters = {} } = params;
  const url = HOST + `/rest/meter/find?offset=${page * pageSize}&limit=${pageSize}`;
  return axiosPost(url, filters);
}

export async function getPUBrowseCount(params: {
  filters?: any;
}): Promise<AxiosResponse<number, any>> {
  const { filters = {} } = params;
  const url = HOST + `/rest/meter/find/count`;
  return axiosPost(url, filters);
}

export async function getPUItem(meterId: string): Promise<AxiosResponse<PuEditorResponse, any>> {
  const url = HOST + `/rest/meter/${meterId}`;
  return axiosGet(url);
}

export async function editPUItem(
  meterId: string,
  data: PuEditorResponse
): Promise<AxiosResponse<PuEditorResponse, any>> {
  const url = HOST + `/rest/meter/${meterId}`;
  return axiosPut(url, data);
}

export async function deletePUItem(meterId: string): Promise<AxiosResponse<PuEditorResponse, any>> {
  const url = HOST + `/rest/meter/${meterId}`;
  return axiosDelete(url);
}

export async function getTaskNubmerForPU(
  meterId: string
): ApiResponse<TaskNumberForEquipmentResponse> {
  const url = HOST + `/rest/meter/${meterId}/usage`;
  return axiosGet(url);
}

export async function getEquipmentItem(equipmentId: string): ApiResponse<EquipmentEditorResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}`;
  return axiosGet(url);
}

export async function getTaskNubmerForEquipment(
  equipmentId: string
): ApiResponse<TaskNumberForEquipmentResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}/usage`;
  return axiosGet(url);
}

export async function editEquipmentItem(
  equipmentId: string,
  data: EquipmentEditorResponse
): ApiResponse<EquipmentEditorResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}`;
  return axiosPut(url, data);
}

export async function deleteEquipmentItem(
  equipmentId: string
): ApiResponse<EquipmentEditorResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}`;
  return axiosDelete(url);
}
