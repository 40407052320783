import { Button, Stack } from '@mui/material';
import { ImageGroupView } from '../ImageGroupView/ImageGroupView';
import { useGalleryData } from '@/components/Gallery/store';
import { ImagePanelText } from './ImagePanel.styled';
import { GetFileUrlByFileId } from '@/services/YodaRestService';
import React from 'react';
import DownloadAllButton from '@/components/button/DownloadAllButton/DownloadAllButton';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import { useGallery } from '@/components/features/NewGallery/utils';

export const ImagePanel = () => {
  const { openGallery } = useGallery();
  const imageGroups = useGalleryData(({ imageGroups }) => {
    return imageGroups;
  });

  const allFilesPromise = imageGroups
    ?.map((group) =>
      group?.imageList?.map((image, index) => ({
        url: image.url ?? GetFileUrlByFileId(image.id),
        folder: group.title,
        fileName: `${index + 1}_${group.title}_${image.id}`,
      }))
    )
    .flat();

  return (
    <>
      {imageGroups?.length ? (
        <>
          <div
            style={{
              display: 'flex',
              gap: 10,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <DownloadAllButton
              disabled={!allFilesPromise.length}
              buttonText='Скачать все фото'
              text='Скачать все фото'
              zipName='Архив_фотографий'
              downloadLinksPromises={allFilesPromise}
            />

            <Button
              endIcon={<ImageSearchRoundedIcon />}
              onClick={() => {
                openGallery({ images: imageGroups });
              }}
            >
              Галерея
            </Button>
          </div>

          {imageGroups
            .sort((c, p) => (c.title > p.title ? 1 : -1))
            .map((t, i) => (
              <Stack
                key={`image-group-${i + t.title}`}
                spacing={1}
              >
                <ImageGroupView imageBox={t} />
              </Stack>
            ))}
        </>
      ) : (
        <ImagePanelText variant={'h4'}>
          Для отображения фото воспользуйтесь двойным кликом мышки, нажав на ту часть результата
          работ, фото которой вы хотите получить
        </ImagePanelText>
      )}
    </>
  );
};
