import { useEffect } from 'react';

/**
 * Хук для автоматического скролла к первому полю с ошибкой в форме.
 *
 * @param {string[]} arrErrorNames - Массив идентификаторов (`id`) полей с ошибками.
 *
 * @example
 * useFormErrorToScrollUp(['email', 'password']);
 * // Прокрутит страницу к элементу с id="email" при изменении массива ошибок.
 */
export const useFormErrorToScrollUp = (arrErrorNames: string[] = []) => {
  useEffect(() => {
    if (arrErrorNames.length) {
      const firstError = arrErrorNames[0]; // Берем id первого элемента с ошибкой
      const errorElement = document.getElementById(firstError); // Ищем его в DOM

      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: 'smooth', // Плавный скролл
          block: 'center', // Выравнивание по центру экрана
        });
      }
    }
  }, [arrErrorNames]);
};
