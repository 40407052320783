import React, { useMemo } from 'react';
import { filterShowInfo, formatCount } from '../utils';
import {
  ShowInfoBody,
  ShowInfoTable,
  ShowInfoTableBody,
  ShowInfoTableTd,
} from './BudgetStatisticDoughnut.styled';
import { StatistiSummaryResponse } from '../../api/dto/response';
import { useInvestStatisticStore } from '../../store';
interface DoughnutShowInfoProps {
  item: StatistiSummaryResponse;
  withNartis?: boolean;
}

export const DoughnutShowInfo = ({ item, withNartis }: DoughnutShowInfoProps) => {
  const filteredShowInfo = useMemo(
    () => filterShowInfo(item.showInfo, withNartis),
    [item.showInfo, withNartis]
  );

  const countAsMoney = useInvestStatisticStore((state) => state.countAsMoney);

  return (
    <ShowInfoBody>
      <ShowInfoTable>
        <ShowInfoTableBody>
          {filteredShowInfo &&
            typeof filteredShowInfo === 'object' &&
            Object.entries(filteredShowInfo).map(([key, items], index) => {
              return (
                <React.Fragment key={key}>
                  {index !== 0 && (
                    <tr>
                      <td style={{ height: '20px' }} />
                    </tr>
                  )}
                  {items?.map((item) => (
                    <React.Fragment key={item.title}>
                      <tr style={{ marginBottom: '10px' }}>
                        <ShowInfoTableTd>{item.title}</ShowInfoTableTd>
                        <ShowInfoTableTd>{item.percentage}%</ShowInfoTableTd>
                        <ShowInfoTableTd>
                          {countAsMoney ? formatCount(item?.amount) : item?.amount}
                        </ShowInfoTableTd>
                        <ShowInfoTableTd>{countAsMoney ? '₽' : 'ШТ'}</ShowInfoTableTd>
                      </tr>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            })}
        </ShowInfoTableBody>
      </ShowInfoTable>
    </ShowInfoBody>
  );
};
