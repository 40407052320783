export const TASK_STATUS = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  DISPUT: 'DISPUT',
  RETURNED: 'RETURNED',
  ASSIGNED: 'ASSIGNED',
  CHECK: 'CHECK',
  NOT_RELEVANT: 'NOT_RELEVANT',
  IN_WORK: 'IN_WORK',
};

export const TASK_TYPE = {
  NETWORK_ELEMENT_ODPU: 'NETWORK_ELEMENT_ODPU', //'ОС для ОДПУ',
  WARRANTY_WORK_TECH_CONNECTION: 'WARRANTY_WORK_TECH_CONNECTION', //'Гарантийные работы Тех.прис',
  SBSD: 'SBSD', //'В рамках СБСД',
  ASUMB_SMR_MKD: 'ASUMB_SMR_MKD', // 'СМР АСУМБ',
  NETWORKELEMENTS: 'NETWORKELEMENTS', // 'Сетевое оборудование',
  SMR_ODPU: 'SMR_ODPU', //'СМР ОДПУ',
  REPROGRAMMING: 'REPROGRAMMING', //'Перепрограммирование',
  WARRANTY_WORK_SMR_STOLBI: 'WARRANTY_WORK_SMR_STOLBI', //'Гарантийные работы СМР Столбы',
  COMPLAINT_MKD: 'COMPLAINT_MKD', // 'Жалоба',
  FZ_522: 'FZ_522', // 'ФЗ-522 Общий список',
  FZ_522_ISUR: 'FZ_522_ISUR', // 'ФЗ-522 ИСУР',
  SIGNE_ADPU: 'SIGNE_ADPU', // 'Подписать АДПУ',
  ASUMB_NETWORK_ELEMENTS: 'ASUMB_NETWORK_ELEMENTS', //'СМР сетевого оборудования АСУМБ',
  REPEAT_REQUEST_PSK: 'REPEAT_REQUEST_PSK', //'Повторная заявка ПСК',
  PU_ORDER: 'PU_ORDER', //'Наряд на ПУ',
  SMR_UNDER_RENOVATION: 'SMR_UNDER_RENOVATION', //'Реновация Москвы',
  SMR_MKD: 'SMR_MKD', //'СМР в МКД',
  WARRANTY_WORK_FZ_522: 'WARRANTY_WORK_FZ_522', //'Гарантийные работы ФЗ-522',
  ZIGBEE_MODULE: 'ZIGBEE_MODULE', //'Zigbee модуль',
  ASUMB_GOVERNMENT: 'ASUMB_GOVERNMENT', // 'Госорганы АСУМБ',
  OS_ORDER: 'OS_ORDER', // 'Наряд на ОС',
  SMR_STOLBI: 'SMR_STOLBI', // 'СМР Столбы',
  ASUMB_UGP: 'ASUMB_UGP', // 'УГП АСУМБ',
  WARRANTY_WORK_NETWORK_ELEMENT_STOLBI: 'WARRANTY_WORK_NETWORK_ELEMENT_STOLBI', // 'Гарантийные работы с сетевым оборудованием Столбы',
  SMR_ODPU_MOEK: 'SMR_ODPU_MOEK', // 'СМР ОДПУ МОЭК',
  TECH_CONNECTION: 'TECH_CONNECTION', // 'Тех. Прис.',
  ASUMB_COMPLAINT_MKD: 'ASUMB_COMPLAINT_MKD', // 'Жалоба АСУМБ',
  COMPLAINT_FZ_522: 'COMPLAINT_FZ_522', // 'ФЗ-522 Жалоба',
  CLAIM_ENERGY_SERVICE: 'CLAIM_ENERGY_SERVICE', // 'Жалоба Энерго Сервис',
  NETWORK_ELEMENTS_STOLBY: 'NETWORK_ELEMENTS_STOLBY', // 'Сетевое оборудование Столбы',
  REQUEST: 'REQUEST', // 'Заявка',
  MASS_INSTALLATION_FZ_522: 'MASS_INSTALLATION_FZ_522', // 'ФЗ-522 Массовый завес',
  SMR_ODPU_ENTITY: 'SMR_ODPU_ENTITY', // 'СМР ОДПУ Юр. лицо',
  PPO_TP: 'PPO_TP', // 'ППО ТП',
  CLAIM_ODPU: 'CLAIM_ODPU', // 'Жалоба ОДПУ',
  ASUMB_GUARANTEE: 'ASUMB_GUARANTEE', // 'Гарантия АСУМБ',
};
