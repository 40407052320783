import { styled } from '@mui/material/styles';

interface DownloadAllLoadingWrapperProps {
  forIcon?: boolean;
}

export const DownloadAllLoadingWrapper = styled('div')<DownloadAllLoadingWrapperProps>(
  ({ forIcon }) => ({
    width: '48px',
    height: forIcon ? 48 : 36.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

export const DownloadAllButtonWrapper = styled('label')({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
});
